/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-cycle */
/* eslint-disable react/display-name */
/* eslint-disable prettier/prettier */
import React, { useState, useEffect, useContext, useRef } from 'react'
import Popover from '@mui/material/Popover'
import { makeStyles } from '@mui/styles'
import { createNodeId, getNodeIcon, setsAreEqual, structureTagMatcher } from '../../utils/utils'
import { useApolloClient } from '@apollo/client'
import {
	InputContainer,
	Heading,
	DriverContainer,
	Information,
	DriverInnerWrapper,
	Line,
	ConnectionIconContainer,
	CountIcon,
	BeatCountSection,
	UpdateDriverButton,
	ElementContainer,
	IconContainer,
} from './styles'
import InfoIcon from '../../images/infoIcon.svg'
import DriverArrowBox from '../../images/DriverArrowBox.svg'
import supportingIcon from '../../images/supporting-structure-tag.svg'
import opposingIcon from '../../images/opposing-structure-tag.svg'
import BeatIcon from '../../images/beatDark.svg'
import LensIcon from '@lynit/shared/src/images/emptyLens.svg'
import { Checkbox, FormControlLabel, FormGroup } from '@mui/material'
import { useAddDriversToBeatsMutation, useGetRelationshipsQuery } from '../../hooks'
import { graphDataStateContext } from '../../state/graphDataProvider'
// import { createCacheConnection, deleteCacheConnections, updateCacheBeatsDriven, updateCacheField, updateCacheFieldAllNodes } from '@lynit/shared/src/utils/apollo'
// import { systemStateContext } from '../../state/systemProvider'

const useStyles = makeStyles(() => ({
	root: {
		width: '350px',
		height: 'auto',
		maxHeight: '324px',
		borderRadius: '5px',
		border: '1px solid #B5B5B5',
		background: '#FFF',
		position: 'fixed',
		overflow: 'hidden',
	},
	formRoot: {
		position: 'relative',
		right: '258px',
		margin: 0,
	},
	CheckboxRoot: {
		padding: 0,
	},
	lable: {
		marginLeft: '10px',
	},
}))

const DriverModal = React.memo(
	({
		isActive,
		setConnectionModal,
		isArc,
		isNote,
		driverIds,
		sourceId,
		createLog,
		referenceElement,
		mouseX,
		mouseY,
		beat,
		setCurrentElement,
	}) => {
		const classes = useStyles()
		const [performAddDriversToBeatsMutation] = useAddDriversToBeatsMutation()
		const { data: relationships } = useGetRelationshipsQuery()
		const graphData = useContext(graphDataStateContext)

		const [selectedDriverList, setSelectedDriverList] = useState(driverIds)
		const [isDriverListChange, setIsDriverListChange] = useState(false)
		//const [connectionsArr, setConnectionsArr] = useState([])
		//const [refId, setRefId] = useState(null)
		const [refName, setRefName] = useState(null)
		//const [driverList, setDriverList] =useState(graphData.allNodes.nodes)

		const saveInterval = useRef()
		const client = useApolloClient()

		const objectArray = Object.values(graphData.allNodes.nodes).filter(node =>
			['Character', 'Arc', 'Event', 'Theme'].includes(node.__typename),
		)
		objectArray.sort((a, b) => {
			if (a.driverConnections.length < b.driverConnections.length) {
				return 1
			}
			if (a.driverConnections.length > b.driverConnections.length) {
				return -1
			}
			return 0
		})

		const [driverList, setDriverList] = useState(objectArray)
		const [filterInput, setFilterInput] = useState('')

		useEffect(() => {
			setDriverList(
				objectArray.filter(
					node =>
						node?.name?.toLowerCase()?.includes(filterInput) ||
						selectedDriverList.find(driver => driver?.id === node?.id),
				),
			)
		}, [filterInput])

		const handleChangeDrivers = (event, e) => {
			const element = { id: e.id, __typename: e.__typename }
			const { checked } = event.target
			let selectedDriverListSet
			if (checked) {
				setSelectedDriverList(prevSelectedItems => [...prevSelectedItems, element])
				selectedDriverListSet = new Set([...selectedDriverList, element].map(driver => driver.id))
			} else {
				setSelectedDriverList(prevSelectedItems =>
					prevSelectedItems.filter(item => item.id !== element.id),
				)
				selectedDriverListSet = new Set(
					selectedDriverList.filter(item => item.id !== element.id).map(driver => driver.id),
				)
			}

			const driverIdSet = new Set(driverIds.map(driver => driver.id))
			setIsDriverListChange(!setsAreEqual(driverIdSet, selectedDriverListSet))
		}

		const handleAddDriverToBeats = async e => {
			setIsDriverListChange(false)
			setConnectionModal(false)
			createLog(
				`Connection Creation Element Selected`,
				`{"workflowStep":${2},"connectionType":"Driver"}`,
				'Add Driver Modal',
				'Connection Creation',
			)
			const removedDriverConnections = []
			const newStaticConnections = []
			const existingStaticConnections = []

			const totalDriverConnections = selectedDriverList.map((driver, index) => {
				return {
					id: createNodeId('Relationship'),
					description: '',
					arcStage: null,
					relName: 'RELATED',
					order: String((graphData.nodes[driver.id]?.driverConnections?.length || 0)),
					connectionType: 'Driver',
					structureTag: null,
					arc: null,
					sourceNode: {
						id: sourceId,
						__typename: 'Beat',
					},
					destNode: driver,
					__typename: 'Relationship',
					createdAt: new Date().toISOString(),
					updatedAt: new Date().toISOString(),
					name: null,
					beatsDriven: '0',
					sharedBeats: null,
					firstBeat: '',
				}
			})
			const addedDriverConnections = totalDriverConnections.filter(
				sd => !driverIds.find(driver => sd.destNode.id === driver.id),
			)

			const setUniqueStaticConnections = new Set()
			addedDriverConnections.map((driver, index) => {
				const driver_A = driver.destNode
				selectedDriverList.map(driver_B => {
					const driverPair = [driver_A.id, driver_B.id].sort().toString()
					if (setUniqueStaticConnections.has(driverPair) || driver_A.id === driver_B.id) {
						return
					}

					setUniqueStaticConnections.add(driverPair)

					const existStaticConnection = graphData.nodes[driver_A.id]?.staticConnections?.find(
						conn => {
							return [conn.sourceNode.id, conn.destNode.id].includes(driver_B.id)
						},
					)
					if (!existStaticConnection) {
						const sharedBeats = graphData.nodes[driver_A.id].driverConnections
							.map(conn => {
								const beatId =
									conn.sourceNode.__typename === 'Beat' ? conn.sourceNode.id : conn.destNode.id
								const beat = graphData.nodes[beatId]
								return beat
							})
							.filter(beat => {
								const sharedBeat = beat.driverConnections.find(driver => {
									return [driver.sourceNode.id, driver.destNode.id].includes(driver_B.id)
								})
								return sharedBeat
							})
							.map(beat => beat)
						sharedBeats?.push(beat)

						newStaticConnections.push({
							id: createNodeId('Relationship'),
							description: null,
							arcStage: null,
							relName: 'RELATED',
							order: String(index),
							connectionType: 'Static',
							structureTag: null,
							arc: null,
							sourceNode: driver_A,
							destNode: driver_B,
							__typename: 'Relationship',
							// createdAt: new Date().toISOString(),
							// updatedAt: new Date().toISOString(),
							//name: null,
							beatsDriven: String(sharedBeats?.length),
							sharedBeats,
							firstBeat: '',
							createdAt: new Date().toISOString(),
							updatedAt: new Date().toISOString()
						})
					} else {
						const newSharedBeats = (existStaticConnection.sharedBeats || []).concat([
							{ id: sourceId, __typename: 'Beat' },
						])
						const newBeatsDriven = String(newSharedBeats.length)
						existingStaticConnections.push({
							...existStaticConnection,
							sharedBeats: newSharedBeats,
							beatsDriven: newBeatsDriven,
						})
					}
				})
			})
			const driversToMove = driverIds.filter(
				driver => !selectedDriverList.find(sd => sd.id === driver.id),
			)

			//remove

			driversToMove.forEach(driverToDelete => {
				driverIds.map(driver => {
					const connToUpdate = relationships.relations.find(conn => {
						return (
							conn.connectionType === 'Static' &&
							[conn.destNode.id, conn.sourceNode.id].includes(driver.id) &&
							[conn.destNode.id, conn.sourceNode.id].includes(driverToDelete.id)
						)
					})
					if (connToUpdate) {
						const newSharedBeats =
							connToUpdate.sharedBeats?.filter(sharedBeat => sharedBeat.id !== sourceId) || []
						const newBeatsDriven = String(newSharedBeats.length)
						existingStaticConnections.push({
							...connToUpdate,
							sharedBeats: newSharedBeats,
							beatsDriven: newBeatsDriven,
						})
					}
					
				})
			})

			await performAddDriversToBeatsMutation({
				removedDriverConnections,
				addedDriverConnections,
				newStaticConnections,
				existingStaticConnections,
				client,
				sourceId,
				beat,
				driversToMove,
			})
		}

		const trimName = name => {
			if (name?.length > 25) {
				return `${name.slice(0, 25)}...`
			} else {
				return name
			}
		}

		return (
			<div className="addDriver">
				<Popover
					id="connection-modal"
					anchorReference="anchorPosition"
					anchorPosition={{ top: mouseY, left: mouseX }}
					anchorEl={isActive}
					transformOrigin={{ vertical: 'top', horizontal: 'right' }}
					open={Boolean(isActive)}
					onClose={() => setConnectionModal(false)}
					classes={{ paper: classes.root }}
				>
					<Heading>
						<span
							style={{
								color: '#000',
								fontFamily: 'Source Sans Pro',
								fontSize: '15px',
								fontStyle: 'normal',
								fontWeight: 700,
								lineHeight: 'normal',
								textAlign: 'center',
							}}
						>
							Select Elements that drive this Beat
						</span>
					</Heading>
					{/* {selectedDriverList.length === 0 && (
						<Information>
							<img src={InfoIcon} alt="information icon" />
							<span>Removing all drivers will delete this beat.</span>
						</Information>
					)} */}
					{isDriverListChange && (
						<UpdateDriverButton>
							<button
								onClick={() => {
										handleAddDriverToBeats()

								}}
							>
								Done
							</button>
						</UpdateDriverButton>
					)}
					<InputContainer className="container">
						<img src={LensIcon} alt="LensIcon" />
						<input
							autoFocus
							id={'driverSearchInput'}
							defaultValue={filterInput}
							type="text"
							placeholder="Search"
							onChange={e => setFilterInput(e.target.value.toLowerCase())}
						/>
					</InputContainer>
					{/* <InputContainer className="container">
						<span>{refName}'s Connections</span>
					</InputContainer> */}
					<div
						style={{
							// width: '350px',
							height: 'auto',
							maxHeight: '210px',
							borderRadius: '5px',
							border: '1px solid #B5B5B5',
							background: '#FFF',
							//position: 'relative',
							overflow: 'auto',
						}}
					>
						<DriverContainer>
							<Line length={driverList.length - 1} />
							{driverList.map((e, index) => {
								const elementId = e.id
								return (
									<DriverInnerWrapper
										key={index}
										index={index}
										length={graphData.allNodes.nodes?.length - 1}
									>
										<img className="driver-arrow" src={DriverArrowBox} alt="driver arrow box" />
										<FormGroup>
											<FormControlLabel
												control={
													<Checkbox
														classes={{ root: classes.CheckboxRoot }}
														onChange={event => handleChangeDrivers(event, e)}
														checked={!!selectedDriverList?.find(driver => driver.id === elementId)}
													/>
												}
												label={
													<>
														<ElementContainer>
															<IconContainer isArc={isArc} type={e.__typename}>
																<img src={getNodeIcon(e.__typename)} />
															</IconContainer>
															{isArc || isNote ? (
																<p style={{ fontSize: '0.7rem', width: '100px' }}>
																	{trimName(e.name)}
																</p>
															) : (
																<p>{trimName(e.name)}</p>
															)}
														</ElementContainer>
														{/* <ConnectionIconContainer>
															<img
																src={
																	e.structureTag === 'Ally' ||
																	e.structureTag === 'Supporting' ||
																	e.structureTag === 'Protagonist'
																		? supportingIcon
																		: e.structureTag === 'Opponent' ||
																		e.structureTag === 'Opposing' ||
																		e.structureTag === 'Antagonist'
																		? opposingIcon
																		: supportingIcon
																}
															/>
														</ConnectionIconContainer> */}
													</>
												}
												sx={{
													'& .MuiSvgIcon-root': { fontSize: 20 },
													'& .MuiTypography-root': { marginLeft: '10px' },
												}}
												classes={{ root: classes.formRoot }}
											/>
										</FormGroup>
										<BeatCountSection>
											<CountIcon>
												<p>{e?.driverConnections.length || 0}</p>
											</CountIcon>
											<img src={BeatIcon} alt="Beat icon dark" />
										</BeatCountSection>
									</DriverInnerWrapper>
								)
							})}
						</DriverContainer>
						{/* {isDriverListChange && (
							<UpdateDriverButton>
								<button
									onClick={() => {
										if (selectedDriverList.length === 0 ){
											setCurrentElement({ name:beat.title, id:beat.id, type:"Beat" })
										} else {
											handleAddDriverToBeats()
										}

									}}
								>
									Done
								</button>
							</UpdateDriverButton>
						)} */}
					</div>
				</Popover>
			</div>
		)
	},
)

export default DriverModal
