/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
/* eslint-disable no-nested-ternary */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-expressions */

import React, { useState, useEffect, useRef, useContext } from 'react'
import { useApolloClient } from '@apollo/client'
import Quill from '../Quill/quill-lynit'

import debounce from 'lodash/debounce'

import { sharedContext } from '../../state/sharedProvider'
import {
	setCacheConnDesc,
	setCacheConnSelection,
	setFieldError,
	setNewConnId,
	setNoElementError,
	setServerError,
} from '../../state/actions'
import { toastHandler } from '../../utils/backendHandler'
import Toast from '../CustomToast'
import { deleteCacheElement, deleteCacheConnections, updateCacheField } from '../../utils/apollo'
import { QuillContainer } from './styles'
import { useUpdateNodeMutation, useUpdateRelationshipMutation } from '../../hooks'
import useQuillAutoSave from '../AutoSave/useQuillAutoSave'
import { userStateContext } from '../../state/userProvider'
import { beatsDataDispatchContext, beatsDataStateContext } from '../../state/beatsProvider'
import { systemStateContext } from '../../state/systemProvider'
import { nodeTypeForId } from '../../utils/utils'

const Delta = Quill.import('delta')

const DescriptionField = ({
	id,
	fieldName,
	type,
	connectionType,
	placeholder,
	elementDescription,
	isRightPanel,
	isChapterTab,
	updatedAt = () => new Date().toISOString(),
}) => {

	if (!isRightPanel) {
		// eslint-disable-next-line no-param-reassign
		isRightPanel = false
	}
	const {
		state: { searchResult },
		dispatch: dispatchAction,
	} = useContext(sharedContext)
	const client = useApolloClient()
	let change = new Delta()
	const serverErrorInterval = useRef()

	const [updateElement] = useUpdateNodeMutation(type, { ignoreResults: true })
	const [updateNote] = useUpdateNodeMutation('Note', { ignoreResults: true })
	const [performUpdateRelationshipMutation] = useUpdateRelationshipMutation({ ignoreResults: true })

	const [isFocused, setFocused] = useState(false)

	const beatsRelatedData = useContext(beatsDataStateContext)
	const systemRelatedData = useContext(systemStateContext)
	const beatsDataDispatch = useContext(beatsDataDispatchContext)
	const user = useContext(userStateContext)

	const update = (id, description, text) => {
		if (id) {
			if (type === 'Note') {
				updateNote({
					variables: {
						id,
						input: {
							contents: description,
							updatedAt: updatedAt(),
						},
					},
				})
					.then(res => {
						change = new Delta()
						if (res && beatsRelatedData?.serverError) {
							beatsDataDispatch(setServerError(false))
							clearInterval(serverErrorInterval.current)
						}
						beatsDataDispatch(setFieldError(false))
					})
					.catch(async err => {
						quill?.blur()
						if (err.message.includes('Cannot updated')) {
							beatsDataDispatch(setNoElementError(true))
							setTimeout(
								() =>
									deleteCacheElement(client, { id, type }).then(() => {
										beatsDataDispatch(setNoElementError(false))
									}),
								3000,
							)
						} else if (
							err.message.includes('Failed to fetch') ||
							err.message.includes('NetworkError')
						) {
							beatsDataDispatch(setServerError(true))
						} else if (err.message.includes('Response not successful:')) {
							beatsDataDispatch(setFieldError(true))
						} else {
							toastHandler('error', 'There was an error updating a note, try again.')
							systemRelatedData?.createLog(
								`ToastHandler Error Message`,
								`{"errorMessage":"${err.message}"}`,
								'DescriptionField',
								'Note Updation',
							)
						}
					})
			}
			if (type !== 'Note' && id?.includes('rel')) {
				performUpdateRelationshipMutation({
					id,
					input: {
						description,
					},
					client,
					serverErrorInterval,
					newMapValue: { description, updatedAt: new Date().toISOString() },
				})
					.then(res => {
						change = new Delta()
					})
					.catch(async err => {
						quill?.blur()
					})
			}
			let input = { updatedAt: updatedAt() }
			input[fieldName] = description
			if (type !== 'Note' && !id?.includes('rel')) {
				updateElement({
					variables: {
						id,
						input,
					},
				})
					.then(res => {
						change = new Delta()
						if (res && beatsRelatedData?.serverError) {
							beatsDataDispatch(setServerError(false))
							clearInterval(serverErrorInterval.current)
						}
						beatsDataDispatch(setFieldError(false))
					})
					.catch(async err => {
						quill?.blur()
						if (err.message.includes('Cannot update')) {
							beatsDataDispatch(setNoElementError(true))
							setTimeout(
								() =>
									deleteCacheElement(client, { id, type }).then(() => {
										beatsDataDispatch(setNoElementError(false))
									}),
								3000,
							)
						} else if (
							err.message.includes('Failed to fetch') ||
							err.message.includes('NetworkError')
						) {
							beatsDataDispatch(setServerError(true))
						} else if (err.message.includes('Response not successful:')) {
							beatsDataDispatch(setFieldError(true))
						} else {
							toastHandler('error', 'There was an error updating an element, try again.')
							systemRelatedData?.createLog(
								`ToastHandler Error Message`,
								`{"errorMessage":"${err.message}"}`,
								'DescriptionField',
								'Element Updation',
							)
						}
					})
			}
		} else {
		}
	}

	const { quill, quillRef } = useQuillAutoSave(
		{
			modules: {
				toolbar: false,
			},
			placeholder,
		},
		update,
		id,
		fieldName,
		elementDescription,
	)

	useEffect(() => {
		if (quill?.root) {
			quill.root.dataset.placeholder = placeholder || ''
		}
	}, [quillRef, placeholder])

	useEffect(() => {
		window.onbeforeunload = () => {
			if (change?.length() > 0) {
				return 'There are unsaved changes. Are you sure you want to leave?'
			}
		}
	}, [isFocused])

	useEffect(() => {
		if (quill) {
			if (beatsRelatedData?.serverError || beatsRelatedData?.noElementError) {
				quill.disable()
			} else {
				quill.enable()
			}
			if (!user?.user?.subscriptionStatus?.editDescriptionfield) {
				quill.disable()
			}
		}
	}, [
		beatsRelatedData?.serverError,
		beatsRelatedData?.noElementError,
		quill,
		user?.user?.subscriptionStatus?.editDescriptionfield,
	])

	useEffect(() => {
		if (beatsRelatedData?.serverError) {
			serverErrorInterval.current = setInterval(() => {
				if (change?.length() > 0) {
					update(id, JSON.stringify(quill.getContents()))
				}
			}, 500)
		}
		if (!beatsRelatedData?.serverError) {
			clearInterval(serverErrorInterval.current)
		}
	}, [beatsRelatedData?.serverError])

	useEffect(() => {
		if (beatsRelatedData?.fieldError) {
			setTimeout(() => {
				toastHandler(
					'dark',
					'',
					<Toast
						onRetry={() => {
							//update(id,JSON.stringify(quill.getContents()))
							setError(false)
						}}
						isRetry
						content={'Content not update. Try again later.'}
					/>,
				)
			}, 1000)
		}
	}, [beatsRelatedData?.fieldError])

	return (
		<>
			<QuillContainer
				data-testid="quill"
				data-private="lipsum"
				data-dd-privacy="mask"
				isEnabled={user?.user?.subscriptionStatus?.editDescriptionfield}
				connectionType={connectionType}
				type={type}
				isChapterTab={isChapterTab}
				isRightPanel={isRightPanel}
				ref={quillRef}
				id={id}
				data-fieldname={fieldName}
				translate="no"
				onFocus={() => {
					setFocused(true)
					const highlights = quillRef.current.querySelectorAll(
						'span[style*="background-color: yellow"]',
					)
					highlights.forEach(highlightedElement => {
						const parentElement = highlightedElement.parentNode
						// Replace the highlighted span with its text content
						parentElement.replaceChild(
							document.createTextNode(highlightedElement.textContent),
							highlightedElement,
						)
						// Merge text nodes to prevent fragmenting
						parentElement.normalize()
					})
				}}
				onBlur={() => {
					if (id && JSON.stringify(quill?.getContents()) !== elementDescription && quill) {
						const updateData = {}
						updateData[fieldName] = JSON.stringify(quill?.getContents())
						updateData.updatedAt = new Date().toISOString()
						updateCacheField(client, { id: id, __typename: nodeTypeForId(id) }, updateData, true)
					}
				}}
			/>
		</>
	)
}
export default React.memo(DescriptionField)
