/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable arrow-parens */
/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable prettier/prettier */
import React, { useState, useEffect, useContext, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import orderBy from 'lodash/orderBy'
import { useApolloClient } from '@apollo/client'
import { SortableContext, horizontalListSortingStrategy, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable'

import {
	elementReordered,
	setBeatsInChapters,
	setDeepModeElement,
	setLeftToggleTabs,
	setNoElementError,
	setServerError,
} from '@lynit/shared/src/state/actions'
import { sharedContext } from '@lynit/shared/src/state/sharedProvider'
import { graphDataStateContext } from '@lynit/shared/src/state/graphDataProvider'
import { useCreateNodeMutation, useReorderNodesMutation } from '@lynit/shared/src/hooks'

import SuggestionTooltip from '@lynit/shared/src/ui/ToolTip/SuggestionTooltip'
import SubscriptionFooter from '@lynit/shared/src/ui/SubscriptionFooter'
import { createCacheElement, deleteCacheElement } from '@lynit/shared/src/utils/apollo'
import { toastHandler } from '@lynit/shared/src/utils/backendHandler'

import elementBox from '@lynit/shared/src/images/elementBox.svg'
import addIcon from '@lynit/shared/src/images/add-driver-icon.svg'
import helpIcon from '@lynit/shared/src/images/helpIcon.svg'
import chapIcon from '../../images/chapter-icon.svg'

import ChapterCard from './ChapterCard'
import {
	ElementContainer,
	ElementListContainer,
	CreateButton,
	Header,
	ChaptersContainer,
	BeatConnectionLimit,
	ScrollingContainer,
} from './styles'
import { userStateContext } from '@lynit/shared/src/state/userProvider'
import { systemStateContext } from '@lynit/shared/src/state/systemProvider'
import { beatsDataDispatchContext, beatsDataStateContext } from '@lynit/shared/src/state/beatsProvider'
import { createNodeId } from '@lynit/shared/src/utils/utils'

const ChapterView = ({ createLog, isVisualizationExpanded, isExpandingView, response, storyOutlineLoaded }) => {
	const client = useApolloClient()
	const history = useHistory()
	const serverErrorInterval = useRef()
	const newNodeData = useRef()

	const {
		state: {
			beatsInChapters,
			leftToggleTabs,
			rightToggleTabs,
			isActive,
			activeElement,
		},
		dispatch,
	} = useContext(sharedContext)

	const {
		currentStoryId: storyId,
		deepModeElement,
		serverError,
		noElementError,
		reorderedPosition,
	} = useContext(beatsDataStateContext)
	const beatsDataDispatch = useContext(beatsDataDispatchContext)

	const user = useContext(userStateContext)
	const graphData = useContext(graphDataStateContext)
	const {isUserSubscribed} = useContext(systemStateContext)

	const [filteringData, setFilteringData] = useState([])
	const [isNodeCreated, setNodeCreated] = useState(false)

	const [reorderNodes] = useReorderNodesMutation()
	const [performCreateNodeMutation] = useCreateNodeMutation('Chapter')

	useEffect(() => {
		if (filteringData?.length) {
			let totalBeats = 0
			filteringData.forEach(chapter => {
				totalBeats += chapter?.beatConnections?.length ?? 0
			})
			dispatch(setBeatsInChapters(totalBeats))
		} else {
			dispatch(setBeatsInChapters(0))
		}
	}, [filteringData])

	

	useEffect(() => {
		const currentElements = graphData?.nodesByType?.['Chapter'] || []
		const elements = currentElements.map(element => {
			return { ...element, order: +element.order }
		})
		setFilteringData(orderBy(elements, ['order'], ['asc']))
	}, [graphData?.nodesByType])

	useEffect(() => {
		if (!!activeElement.id && activeElement.type === 'Chapter') {
			const chapterCard = document.getElementById(`${activeElement.id}-chapter-card`)
			chapterCard?.scrollIntoView({ behavior: 'smooth', inline: 'nearest' })
		}
	}, [activeElement])

	// display updated position before getting response from backend while reorder chapters
	useEffect(() => {
		if (
			reorderedPosition?.type === 'chapter' &&
			reorderedPosition.source.index !== reorderedPosition.destination.index
		) {
			setFilteringData(
				arrayMove(
					filteringData,
					reorderedPosition.source.index,
					reorderedPosition.destination.index,
				),
			)
			beatsDataDispatch(elementReordered())
		}
	}, [reorderedPosition])

	const addChapter = async () => {
 
		const tempObj = {
			id: createNodeId("Chapter"),
			createdAt: new Date().toISOString(),
			updatedAt: new Date().toISOString(),
			order: '0',
			__typename: "Chapter",
			name: "New Chapter",
			description: '',
			beatConnections: [],
			driverConnections: [],
			childConnections: [],
			staticConnections: [],
			noteConnections: [],
			xCoordinate: null,
			yCoordinate: null,
			vizLocSaved: null,
			firstBeat: '',
			coreElement: 'false',
			storyText: '',
			number:"0",
			wordCount:"0",
			lastDocView:"open",
			lastOpenedDate:"",
		}
		const optimisticResponse = {}
		
		optimisticResponse[`create${tempObj.__typename}`] = tempObj

		// await createCacheElement(client, optimisticResponse, true,true)
		
		// createLog(
		// 	'Dive Deep Entered',
		// 	`{"elementType":"Chapter", "action":"Element Creation"}`,
		// 	'Chapter View',
		// 	null,
		// )
		
		const elem = document.getElementById(`chapters-container`)

		elem?.scrollTo({
			left: 0,
			behavior: 'smooth',
		})

		await performCreateNodeMutation({
			node:{
					id: tempObj.id,
					name: tempObj.name,
					description: tempObj.description,
					number: tempObj.number,
					order: tempObj.order,
			},
			optimisticResponse,
			tempObj

		}).then(res => {
			setNodeCreated(true);
			newNodeData.current = {
				id: res?.data?.createChapter?.id,
				nodeType: res?.data?.createChapter?.__typename,
			}
			
			
			
		})
			.catch(e => {
				console.error(e)
			})
	}

	return (
		<ElementListContainer
			isVisible={!(leftToggleTabs || rightToggleTabs)}
			isVisualizationExpanded={isVisualizationExpanded}
			isExpandingView={isExpandingView}
			id="chapter-view"
		>
			<ElementContainer isData={filteringData?.length !== 0}>
				<Header>
					<span className="chapter-view-title">
						<div className="chapter-icon-container">
							<img src={chapIcon} alt="chapter icon" />
						</div>
						<span className="chapters-title">Chapter Planner</span>
						<SuggestionTooltip
							title="Add Beats to the Chapter where they take place."
							placement="right"
							arrow
						>
							<img src={helpIcon} alt="Help Icon" style={{ width: '10px', height: '10px' }} />
						</SuggestionTooltip>
						<span
							onMouseEnter={() => {
								createLog(
									'Beats In Chapters Count Hovered',
									`{"location":"ChapterView"}`,
									'ChapterView',
									null,
								)
							}}
						>
							Beats in Chapters: {beatsInChapters} {isActive && <>/20</>}{' '}
						</span>
					</span>
					<BeatConnectionLimit>
						<CreateButton
							data-testid="create-button"
							disabled={serverError || noElementError}
							onClick={async () => {
								await addChapter()
								createLog(
									`Element Creation Attempted`,
									`{"workflowStep":${1},"elementType":"Chapter"}`,
									'ElementList',
									'Element Creation',
								)
							}}
						>
							<img src={addIcon} alt="add icon" />
							New Chapter
						</CreateButton>
					</BeatConnectionLimit>
				</Header>
				{filteringData?.length === 0 || (isVisualizationExpanded && (!storyOutlineLoaded ) )? (
					<div
						style={{
							display: 'flex',
							flexDirection: 'column',
							alignItems: 'center',
							justifyContent: 'center',
							width: '100%',
							height: '100%',
						}}
					>
						<img style={{ width: '70%', maxWidth: '320px' }} src={elementBox} alt="BoxIcon" />
						<p>There are no Chapters to show</p>
					</div>
				) : (
					<div style={{ position: 'relative', height: 'calc(100% - 33px)' }}>
						<ScrollingContainer id="chapters-container">
							{isUserSubscribed?.isActiveUser && (
								<SubscriptionFooter
									isActive={isUserSubscribed?.isActiveUser}
									text={'Upgrade to Pro to add more Beats to Chapters'}
									target="chapters-container"
								/>
							)}
							<ChaptersContainer>
								<SortableContext
									items={filteringData?.map(chapter => chapter?.id)}
									strategy={horizontalListSortingStrategy}
								>
									{filteringData?.length &&
										filteringData?.map((element, i) => (
											<ChapterCard
												key={element?.id ?? i}
												id={element?.id}
												name={element?.name}
												description={element?.description}
												type={element?.__typename}
												number={element?.number}
												createLog={createLog}
												response={response}
											/>
										))}
								</SortableContext>
							</ChaptersContainer>
						</ScrollingContainer>
					</div>
				)}
			</ElementContainer>
		</ElementListContainer>
	)
}

export default React.memo(ChapterView)
