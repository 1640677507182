/* eslint-disable prettier/prettier */
import React, { useContext, memo } from 'react'
import { withStyles } from '@mui/styles'
import { useApolloClient } from '@apollo/client'

import { useCreateConnectionMutation, useCreateNodeMutation } from '@lynit/shared/src/hooks'
import { sharedContext } from '@lynit/shared/src/state/sharedProvider'
import { setNewConnId } from '@lynit/shared/src/state/actions'
import { createNodeId, sortNodesByCreatedAt } from '@lynit/shared/src/utils/utils'
import { toastHandler } from '@lynit/shared/src/utils/backendHandler'
import { createCacheConnection, createCacheElement, deleteCacheElement } from '@lynit/shared/src/utils/apollo'

// eslint-disable-next-line import/no-cycle
import plusIcon from '../../images/addNoteIcon.svg'
import NoteCard, { createNoteConnection } from '../NoteCard'
import { muiStyle, NotesContainer, NotesContent, styles } from './styles'
import { LayoutContextProvider } from '../../state/layoutProvider'
import { graphDataStateContext } from '@lynit/shared/src/state/graphDataProvider'
import { userStateContext } from '@lynit/shared/src/state/userProvider'
import { beatsDataStateContext } from '@lynit/shared/src/state/beatsProvider'
import { systemStateContext } from '@lynit/shared/src/state/systemProvider'
import { useCreateConnectionAndElementMutation } from '@lynit/shared/src/hooks/createConnectionAndElement'

const CountPill = ({ count, additionStyles }) => (
	<div style={{ ...styles.pill, ...additionStyles }}>{count}</div>
)

export const NotesList = ({ noteNodes, deepModeElementName = '', isCatelog }) => {
	const client = useApolloClient()
	const noteCounts = {}

	//const createNoteMutation = createNoteConnection()
	const [performCreateNodeMutation] = useCreateNodeMutation('Note', { ignoreResults: true });
	const [performCreateConnectionAndElement] = useCreateConnectionAndElementMutation({ ignoreResults: true })

	const beatsRelatedData = useContext(beatsDataStateContext)
	const systemRelatedData = useContext(systemStateContext)

	const user = useContext(userStateContext)

	noteNodes.forEach(note => {
		if (note && note.staticConnections && note.staticConnections.length !== 0) {
			note.staticConnections.forEach(conn => {
				const nodeType = conn.__typename
				if (nodeType in noteCounts) {
					noteCounts[nodeType] += 1
				} else {
					noteCounts[nodeType] = 1
				}
			})
		}
	})

	const createNode = async () => {
		

		const tempObj = {
			id: createNodeId("Note"),
			createdAt: new Date().toISOString(),
			updatedAt: new Date().toISOString(),
			order: '0',
			__typename: 'Note',
			title: '',
			contents: '',
			beatConnections: [],
			driverConnections: [],
			childConnections: [],
			staticConnections: [],
			noteConnections: [],
			xCoordinate: null,
			yCoordinate: null,
			vizLocSaved: null,
			firstBeat: '',
			description:""
			
		}
		const newConnection = {
			id: createNodeId("Relationship"),
			description: '',
			arcStage: null,
			relName: 'RELATED',
			order: null,
			connectionType: "Note",
			structureTag:null,
			arc: null,
			sourceNode: {
				id: beatsRelatedData?.deepModeElement?.id,
				__typename: beatsRelatedData?.deepModeElement?.nodeType,
			},
			destNode: {
				id:tempObj.id,
				__typename: "Note",
			},
			__typename: 'Relationship',
			createdAt: new Date().toISOString(),
			updatedAt: new Date().toISOString(),
			name: null,
			beatsDriven:null,
			sharedBeats:[],
			firstBeat: ''
		}

		if(beatsRelatedData?.deepModeElement && deepModeElementName){

			await performCreateConnectionAndElement({
				client,
				newConnection,
				user,
				optimisticResponse:{ createNote: tempObj },
				newElement:tempObj,
				
			}
			)

		} else {
			await performCreateNodeMutation({
				node: {
					id:tempObj.id,
					title: '',
					contents: '' 
				},
				optimisticResponse:{ createNote: tempObj },
				hasOrder:false
			})
		}
	}

	return (
		<LayoutContextProvider>
			<NotesContainer translate="no" data-public data-dd-privacy="allow">
				{!isCatelog && (
					<div style={styles.headerContainer}>
						<div style={styles.title}>
							<h1 style={{ margin: '0', fontSize: '25px' }}>Notes</h1>
							<span
								style={{
									fontSize: '18px',
									overflow: 'hidden',
									textOverflow: 'ellipsis',
									maxWidth: '150px',
									whiteSpace: 'pre',
								}}
							>
								{deepModeElementName && <>/ {deepModeElementName}</>}
							</span>
							<CountPill count={noteNodes?.length} />
						</div>
					</div>
				)}

				{!isCatelog && (
					<div
						style={{
							marginBottom: '10px',
							border: '1px solid #97979773',
							borderRadius: '5px',
							padding: '6px 15px',
							color: '#666666',
							fontSize: '14px',
							cursor: 'pointer',
						}}
						onClick={() => {
							createNode()
						}}
					>
						<img src={plusIcon} alt="Add Note" style={{ marginRight: '5px' }} /> Add Note
					</div>
				)}

				<NotesContent noteNodesLength={noteNodes?.length}>
					{!noteNodes?.length && (
						<div
							style={{
								border: '0.5px dashed #3C4043',
								borderRadius: '3px',
								textAlign: 'center',
								padding: '15px 0',
								fontSize: '12px',
								marginTop: '12px',
								color: '#222222',
							}}
						>
							There are no notes to show yet
						</div>
					)}
					{noteNodes
						.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt))
						.map(note => (
							<NoteCard key={note?.id} note={note} />
						))}
				</NotesContent>
			</NotesContainer>
		</LayoutContextProvider>
	)
}

export default withStyles(muiStyle)(memo(NotesList))
