import React, { memo, useContext, useEffect, useRef, useState } from 'react'
import Collapse from '@mui/material/Collapse'
import { CircularProgress, Backdrop } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { sharedContext } from '../../state/sharedProvider'
import {
	openRecommendation,
	setCurrentStory,
	setCurrentStoryId,
	setStory,
	tutorialOpened,
	tutorialSetExpanded,
	setClickedValueProp,
	setSummaryCardVisible,
} from '../../state/actions'

import {
	CollapsedTutorialSet,
	CreateCompanionStoryBtn,
	TutorialSetContainer,
	TutorialTitle,
	IconContainer,
	TutorialCount,
	CollapsedTutorialCount,
	NoCompanionStoryTag,
} from './styles'

import collapseIcon from '../../images/collapse-icon.svg'
import expandIcon from '../../images/expand-icon.svg'
import TutorialCard from './TutorialCard'
import Tutorial from './Tutorial'
import {
	useChangeIsFirstLoginMutation,
	useCreateStoryMutation,
	useLazyGetAllNodesQuery,
	useLazyGetUserQuery,
} from '../../hooks'
import { userStateContext } from '../../state/userProvider'
import { beatsDataDispatchContext } from '../../state/beatsProvider'
import { systemDispatchContext, systemStateContext } from '@lynit/shared/src/state/systemProvider'
import { throttle } from 'lodash'


const TutorialSet = ({ tutorialSet, createLog, previousRecommendation, isExpanded }) => {
	const [isCollapsed, setIsCollapsed] = useState(!isExpanded)
	const [openedTutorialId, setOpenedTutorialId] = useState('')
	const [creatingStory, setCreatingStory] = useState(false)

	const {
		state: { isActive, clickedValueProp, tutorialId, tutorialSetId },
		dispatch,
	} = useContext(sharedContext)
	const user = useContext(userStateContext)
	const { isMobile, toastHandler } = useContext(systemStateContext) 

	const beatsDataDispatch = useContext(beatsDataDispatchContext)
	const systemDataDispatch = useContext(systemDispatchContext)
	const history = useHistory()

	const [, { refetch: refetchUser }] = useLazyGetUserQuery({ fetchPolicy: 'cache-first' })
	const [changeIsFirstLogin] = useChangeIsFirstLoginMutation()
	const [createStory] = useCreateStoryMutation()

	// const [getNodes] = useLazyGetAllNodesQuery()

	// const refetch = args => {
	// 	return getNodes({ variables: args })
	// }

	// to collapse tutorial set if user completes all the tutorials
	useEffect(() => {
		const isCompleted = tutorialSet.tutorials.every(tutorial => tutorial.status === 'complete')
		if (isCompleted && !isCollapsed) {
			setIsCollapsed(true)
		}
	}, [tutorialSet])

	useEffect(() => {
		if (tutorialId) {
			const isTargetTutorialSet = tutorialSet?.tutorials?.find(
				tutorial => tutorial.id === tutorialId,
			)
			if (isTargetTutorialSet) {
				setIsCollapsed(false)
				setOpenedTutorialId(tutorialId)
				dispatch(tutorialOpened())
			} else {
				setIsCollapsed(true)
			}
		}
	}, [tutorialId, tutorialSet])

	useEffect(() => {
		if (tutorialSetId === tutorialSet.id) {
			setIsCollapsed(false)
			dispatch(tutorialSetExpanded())
		}
	}, [tutorialSetId, tutorialSet])

	useEffect(() => {
		if (clickedValueProp) {
			const isTargetTutorialSet = tutorialSet?.id === 'onboarding'
			if (isTargetTutorialSet) {
				const clickedProp = tutorialSet?.tutorials?.find(
					tutorial => tutorial.id === clickedValueProp,
				)
				if (clickedProp) {
					setIsCollapsed(false)
					setOpenedTutorialId(clickedProp.id)
					dispatch(setClickedValueProp(null))
				}
			} else {
				setIsCollapsed(true)
			}
		}
	}, [clickedValueProp, tutorialSet])

	const createNewStory = template => {
		return new Promise((resolve, reject) => {
			setCreatingStory(true)
			createStory({
				variables: {
					template: template,
				},
			})
				.then(r => {
					sessionStorage.setItem('story', r?.data?.createStory?.id)
					beatsDataDispatch(setCurrentStoryId(r?.data?.createStory?.id))
					systemDataDispatch(setSummaryCardVisible(isMobile? false : "Premise"))
					dispatch(setStory(r?.data?.createStory?.id))
					dispatch(setCurrentStory(r?.data?.createStory))
					// refetch({
					// 	storyId: r?.data?.createStory?.id,
					// }).then(() => {
					// 	setCreatingStory(false)
					// 	const needsRefresh = window.location.href.includes('story')
					// 	history.push(`/story/${r?.data?.createStory?.id}`, {
					// 		// eslint-disable-next-line
					// 		isTutorialStory: false, //'The Tortoise and the Hare' === r?.data?.createStory?.template,
					// 	})
					// 	if(needsRefresh){
					// 		window.location.reload()
					// 	}
						
					// 	resolve()
					// })

					setCreatingStory(false)
					const needsRefresh = window.location.href.includes('story')
					history.push(`/story/${r?.data?.createStory?.id}`, {
						// eslint-disable-next-line
						isTutorialStory: false, //'The Tortoise and the Hare' === r?.data?.createStory?.template,
					})
					if(needsRefresh){
						window.location.reload()
					}
					
					resolve()
				})
				.catch(e => {
					if (e.message.includes('subscription')) {
						toastHandler('error', e.message)
						createLog(
							`ToastHandler Error Message`,
							`{"errorMessage":"${e.message}"}`,
							'TutorialSet',
							'New Story Creation',
						)
						if (
							user?.user?.subscriptionStatus?.status === 'trialing' ||
							user?.user?.subscriptionStatus?.status === 'active'
						) {
							window.location.reload()
						}
					} else {
						toastHandler('error', e.message)
						createLog(
							`ToastHandler Error Message`,
							`{"errorMessage":"${e.message}"}`,
							'TutorialSet',
							'New Story Creation',
						)
					}
					reject(e.message)
					setCreatingStory(false)
				})
		})
	}

	const handleCreateStory = useRef(
		
		throttle((template, tutorialSetId) => {
			if (user?.user?.isFirstLogin) {
				changeIsFirstLogin()
			}
			if (!(user?.user?.userSummary?.storyCount >= 2 && isActive)) {
				createLog('Story Creation Attempted', '{}', 'Learn With Lynit', 'Story Creation')
				createNewStory(template, tutorialSetId)
				refetchUser()
			} else {
				toastHandler('error', 'Upgrade to Lynit Pro to create more stories')
				createLog(
					`ToastHandler Error Message`,
					`{"errorMessage":"Upgrade to Lynit Pro to create more stories"}`,
					'TutorialSet',
					'New Story Creation',
				)
			
			}
		},9999, {'trailing': false})
	).current

	if (
		openedTutorialId &&
		tutorialSet?.tutorials?.find(tutorial => tutorial.id === openedTutorialId)
	) {
		return (
			<Tutorial
				tutorialSet={tutorialSet}
				tutorial={tutorialSet.tutorials.find(tutorial => tutorial.id === openedTutorialId)}
				backToTutorialSet={() => {
					if (previousRecommendation) {
						dispatch(openRecommendation(previousRecommendation.id))
					}
					setOpenedTutorialId('')
				}}
			/>
		)
	}

	return (
		<TutorialSetContainer
			data-public
			data-dd-privacy="allow"
			isCollapsed={isCollapsed}
			onClick={e => {
				
				setIsCollapsed(!isCollapsed)
			}}
		>
			{creatingStory && (
				<Backdrop sx={{ color: '#fff', zIndex: 1 }} open={creatingStory}>
					<CircularProgress sx={{ color: '#FFC300' }} />
				</Backdrop>
			)}
			<Collapse in={!isCollapsed} collapsedSize={24}>
				<CollapsedTutorialSet onClick={() => {
						setIsCollapsed(!isCollapsed)}}
					>
					<TutorialTitle >
						<IconContainer>
							{isCollapsed ? (
								<img src={expandIcon} alt="expand icon" />
							) : (
								<img src={collapseIcon} alt="collapse icon" />
							)}
						</IconContainer>
						<span className="title">{tutorialSet?.name}</span>
						{!isCollapsed && (
							<>
								<div className="bullet" />
								<TutorialCount>{tutorialSet?.tutorials?.length}</TutorialCount>
							</>
						)}
					</TutorialTitle>
					{isCollapsed && (
						<CollapsedTutorialCount>
							{tutorialSet?.tutorials?.length} Lessons
						</CollapsedTutorialCount>
					)}
					{!isCollapsed &&
						(tutorialSet?.templateName ? (
							<CreateCompanionStoryBtn
								onClick={(e) => {
									e.stopPropagation()
									handleCreateStory(tutorialSet?.templateName, tutorialSet?.id)
								}}
							>
								Create Companion Template
							</CreateCompanionStoryBtn>
						) : (
							<NoCompanionStoryTag>No Companion Template</NoCompanionStoryTag>
						))}
				</CollapsedTutorialSet>

				{tutorialSet?.description && (
					<p className="tutorial-set-description">{tutorialSet?.description}</p>
				)}

				<div>
					{tutorialSet?.tutorials?.map(tutorial => (
						<TutorialCard 
							key={tutorial.id} 
							tutorial={tutorial} 
							setOpenedTutorialId={setOpenedTutorialId} 
							onClick={()=>{ 
								console.log("---spot 5----")
						 		e.stopPropagation()
								}
							}  
						/>
					))}
				</div>
			</Collapse>
		</TutorialSetContainer>
	)
}

export default memo(TutorialSet)
