import styled from 'styled-components'
import { getNodeIcon, vizTitleLengthFormat } from '@lynit/shared/src/utils/utils'
import { useContext, useEffect, useRef, useState } from 'react'
import {
	Connection,
	ConnectionLine,
	ElementCardContainer,
	ElementName,
	IconContainer,
	TooltipDescription,
	TooltipLabel,
	TooltipWrapper,
} from './styles'
import suggestionIcon from '@lynit/shared/src/images/suggestion-icon.svg'
import connectionSupportingIcon from '@lynit/shared/src/images/connection-supporting-icon.svg'
import artistIcon from '@lynit/shared/src/images/artist-icon.svg'
import { makeStyles } from '@mui/styles'
import HtmlTooltip from '@lynit/shared/src/ui/ToolTip/HtmlTooltip'
import { systemStateContext } from '@lynit/shared/src/state/systemProvider'

const MissingPremiseDriver = ({
	text,
	handleClick,
	connectionModalType,
	referenceElement,
	type,
}) => {
	const [isMouseOverRelationship, setIsMouseOverRelationship] = useState(false)
	const { createLog, toastHandler } = useContext(systemStateContext)
  const [isClicked, setIsClicked] = useState(false)
	const modalRef = useRef()

	const useStyles = makeStyles(() => ({
		tooltip: {
			marginLeft: 0,
			background: 'transparent',
		},
		tooltipSm: {
			margin: '0 auto',
			background: 'transparent',
			width: '100%',
			padding: 0,
			marginTop: '6px !important',
		},
	}))

  const classes = useStyles()

	const getShortName = name => {
		return vizTitleLengthFormat(name, 11)
	}

	useEffect(() => {
		if (type && modalRef) {
			createLog(
				'Relationship Clicked',
				`{"structureTag": "Missing Premise", "driverType":"${referenceElement?.__typename}", "relationshipGroupDriverType": "${connectionModalType?.type}" }`,
				'StoryViz',
				null,
			)
			handleClick(modalRef.current, connectionModalType)
		}
	}, [modalRef,type])

	return (
		<>
			<ConnectionContainer
				id={`connectionCard`}
				onMouseEnter={() => {
					createLog(
						'Relationship Hover',
						`{"driverType":"${referenceElement?.__typename}", "relationshipGroupDriverType": "${connectionModalType?.type}", "structureTag": "Missing Premise"}`,
						'Missing Premise Driver',
						null,
					)
					setIsMouseOverRelationship(true)
				}}
				onMouseLeave={() => {
          setIsMouseOverRelationship(false)
        
        }}
				ref={modalRef}
				onClick={async e => {
          setIsClicked(true)
					createLog(
						'Relationship Clicked',
						`{"structureTag": "Missing Premise", "driverType":"${referenceElement?.__typename}", "relationshipGroupDriverType": "${connectionModalType?.type}" }`,
						'StoryViz',
						null,
					)
					handleClick(e.currentTarget, connectionModalType)

          setTimeout(() => setIsClicked(false), 4000)

         
				}}
				
			>
				<ElementCardContainer>
					<HtmlTooltip
						title={
							<TooltipWrapper>
								<TooltipLabel>This Premise relationship is missing</TooltipLabel>
								<TooltipDescription>
									Click <img src={suggestionIcon} width={'14px'} height={'14px'} /> to create the
									relationship{' '}
								</TooltipDescription>
							</TooltipWrapper>
						}
						placement={'bottom'}
						classes={{
							tooltip: classes.tooltipSm,
						}}
					>
						<ConnectionCardContainer>
							<ElementName>
								<IconContainer
									isSelected={isMouseOverRelationship || type || isClicked}
									color={referenceElement?.__typename}
									missingDriver={!isMouseOverRelationship}
								>
									<img src={getNodeIcon(referenceElement?.__typename)} />
								</IconContainer>{' '}
								<ElementNameLable isActive={isMouseOverRelationship || type|| isClicked}>
									{getShortName(referenceElement?.name)}
								</ElementNameLable>
							</ElementName>
							<Connection>
								<ConnectionLine>
									<img src={connectionSupportingIcon} height={'36px'} />
								</ConnectionLine>
							</Connection>
							<ElementName>
								<IconContainer
									isSelected={isMouseOverRelationship || type || isClicked}
									color={connectionModalType?.type}
									missingDriver={!isMouseOverRelationship}
								>
									<img src={getNodeIcon(connectionModalType?.type)} />
								</IconContainer>{' '}
								<ElementNameLable isActive={isMouseOverRelationship || type || isClicked}>
									{getShortName(text)}
								</ElementNameLable>
							</ElementName>
						</ConnectionCardContainer>
					</HtmlTooltip>
				</ElementCardContainer>
			</ConnectionContainer>
		</>
	)
}

export default MissingPremiseDriver

const ConnectionContainer = styled.div`
	border-radius: 4px;
	cursor: pointer;
`

const Div = styled.div`
	border-radius: 4px;
	border: 1px dashed #ffc300;
	background-color: #fff;
	position: relative;
	//display: flex;
	width: 82%;
	//align-items: start;
	//justify-content: space-between;
	//gap: 20px;
	cursor: pointer;
	padding: 3px 5px;
	left: 37px;
	:hover {
		background-color: #ffc300;
		border-color: transparent;
	}
`

const Div2 = styled.div`
	display: flex;
	gap: 7px;
`

const Img = styled.img`
	aspect-ratio: 1;
	object-fit: contain;
	object-position: center;
	width: 20px;
	overflow: hidden;
	max-width: 100%;
`

const Div3 = styled.div`
	color: #000;
	leading-trim: both;
	text-edge: cap;
	align-self: center;
	flex-grow: 1;
	white-space: wrap;
	margin: auto 0;
	font: 600 12px Source Sans Pro, sans-serif;
`

const Img2 = styled.img`
	aspect-ratio: 1;
	object-fit: contain;
	object-position: center;
	width: 14px;
	overflow: hidden;
	margin-top: 15px;
	max-width: 100%;
`
const ConnectionCardContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	/* min-width: 379px; */
`

const ElementNameLable = styled.div`
	font-family: Source Sans Pro;
	font-size: 12px;
	font-weight: 600;
	line-height: 15.08px;
	text-align: left;
	color: ${props => (props.isActive ? `rgba(76, 76, 76, 1)` : `rgba(189, 189, 189, 1)`)};
`
