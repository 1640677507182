/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable arrow-parens */
/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable prettier/prettier */
import React, { useState, useEffect, useContext, useRef } from 'react'
import fullIcon from '@lynit/shared/src/images/full.svg'
import regIcon from '@lynit/shared/src/images/reg.svg'
import openIcon from '@lynit/shared/src/images/open.svg'
import activeFullIcon from '@lynit/shared/src/images/active-full-icon.svg'
import activeRegIcon from '@lynit/shared/src/images/active-reg-icon.svg'
import activeOpenIcon from '@lynit/shared/src/images/active-open-icon.svg'
import notesIcon from '@lynit/shared/src/images/notes-icon.svg'
import closeIcon from '@lynit/shared/src/images/close-icon.svg'
import useMediaQuery from '@mui/material/useMediaQuery'
import {
	BeatDescription,
	BeatHeader,
	BeatLable,
	BeatSectionWrapper,
	BottomSectionContainer,
	CharacterImageWrapper,
	Connection,
	DescriptionArea,
	DescriptionFieldWrapper,
	DescriptionLable,
	DesignationLabel,
	Devider,
	DividerButton,
	DividerContainer,
	DividerLine,
	DriverDocBottomSection,
	DriverDocWrapper,
	DriverLabelArea,
	DriverName,
	DriverTypeLable,
	IconContainer,
	InnerView,
	LableContainer,
	LayoutStyleContainer,
	LayoutView,
	LeftHeaderData,
	MiddleSection,
	MiddleSectionDescription,
	NotesContainer,
	NotesCount,
	NotesIcon,
	NotesLabel,
	ScapBookLable,
	ScrapBookWrapper,
	SectionDeviderLine,
	Tooltip,
} from './styles'
import { DeleteIcon, PremiseLine } from '../ListElement/styles'
import trashIcon from '@lynit/shared/src/images/IconDelete.svg'
import { useApolloClient } from '@apollo/client'

import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'

import orderBy from 'lodash/orderBy'

import { useHistory } from 'react-router-dom'
import { sharedContext } from '@lynit/shared/src/state/sharedProvider'
import { userStateContext } from '@lynit/shared/src/state/userProvider'
import { systemStateContext } from '@lynit/shared/src/state/systemProvider'
import {
	beatsDataDispatchContext,
	beatsDataStateContext,
} from '@lynit/shared/src/state/beatsProvider'
import { graphDataStateContext } from '@lynit/shared/src/state/graphDataProvider'
import {
	elementReordered,
	onSaveModal,
	setActiveConnections,
	setDeepModeElement,
	setDivingDeep,
	setLeftToggleTabs,
	setNoElementError,
	setRightToggleTabs,
	setServerError,
	setScrollPosition,
	setChapterViewExpanded,
	setVisualizationExpanded,
	setCurrentStory,
	setIsCatelogOpen,
} from '@lynit/shared/src/state/actions'
import CollapsiblePanel from '@lynit/shared/src/ui/CollapsiblePanel'
import ToolTip from '@lynit/shared/src/ui/ToolTip'
import { autoSaveStateContext } from '@lynit/shared/src/state/autoSaveProvider'
import { toastHandler } from '@lynit/shared/src/utils/backendHandler'
import {
	deleteCacheConnections,
	deleteCacheElement,
	updateCacheField,
	updateCacheNameElement,
} from '@lynit/shared/src/utils/apollo'
import { getNodeIcon, createNodeId, nodeTypeForId } from '@lynit/shared/src/utils/utils'
import {
	useReorderNodesMutation,
	useCreateNodeMutation,
	useEditStoryMutation,
	useGetRelationshipsQuery,
	useUpdateNodeMutation,
} from '@lynit/shared/src/hooks'
import TextField from '@lynit/shared/src/ui/Textfield'

import DeleteModal from '@lynit/shared/src/shared-ui/DeleteModal'

import { clickD3Node, selectD3Node, selectVizSVG } from '@lynit/story-outline/src/ui/viz/vizHelpers'
import openViewIcon from '@lynit/shared/src/images/openView.svg'
import openRegView from '@lynit/shared/src/images/openRegView.svg'
import DescriptionField from '@lynit/shared/src/ui/DescriptionField'
import { useMemo } from 'react'
import { useCallback } from 'react'

const ThreePartDoc = ({
	setActive,
	id,
	BottomSectionInputComponent,
	MiddleSectionInputComponent,
	middleSectionDecriptionFields,
	docType,
}) => {
	const graphData = useContext(graphDataStateContext)
	const { user, refetchUser } = useContext(userStateContext)
	const [reorderNodes] = useReorderNodesMutation()

	const {
		state: {
			leftToggleTabs,
			rightToggleTabs,
			activeElement,
			activeConnections,
			deleteNode,
			scrollPosition,
			isChapterViewExpanded,
			currentStory,
		},
		dispatch: sharedDispatch,
	} = useContext(sharedContext)

	const { currentElement: currentDeepModeElement } = useContext(beatsDataStateContext)

	const beatsDataDispatch = useContext(beatsDataDispatchContext)
	const { createLog, isMobile, toastHandler } = useContext(systemStateContext)

	const client = useApolloClient()
	const history = useHistory()
	const serverErrorInterval = useRef()
	const scrollRef = useRef()
	const [editStory] = useEditStoryMutation()
	const [elementName, setElementName] = useState()
	const [currentElement, setCurrentElement] = useState()
	const [onMouseOverView, setMouseOverView] = useState('reg')
	const [designation, setDesignation] = useState('')
	const [view, setView] = useState('open')
	const [onMouseOverToggleIcon, setOnMouseOverToggleIcon] = useState(false)
	const isTablet = useMediaQuery('(min-width: 601px) and (max-width: 768px)')
	const isSmallScreen = useMediaQuery('(max-width: 768px)')

	const removeElement = async (id, type) => {
		const deletedConnections = []
		const deletedElements = [graphData.nodes[id]]
		const updatedData = []

		const staticConnections = graphData?.nodes?.[id]?.staticConnections
		const driverConnections = graphData?.nodes?.[id]?.driverConnections
		setCurrentElement()
		await deleteNode({
			variables: {
				id,
			},
		})
			.then(async () => {
				refetchUser()
				if (staticConnections?.length) {
					staticConnections.forEach(async connection => {
						deletedConnections.push(connection)
						deleteCacheConnections(client, connection, false)
					})
				}
				if (driverConnections?.length) {
					driverConnections.forEach(async connection => {
						deletedConnections.push(connection)
						deleteCacheConnections(client, connection, false)
					})
				}

				reorderNodesAfterDelete(id)
				if (type === 'Chapter') {
					const deletedNode = graphData.nodes[id]
					deletedNode?.beatConnections.forEach(beatConnection => {
						deletedConnections.push(beatConnection)
						deleteCacheConnections(client, beatConnection)
					})
				}
				await deleteCacheElement(client, currentElement, true)

				if (currentElement?.id === currentDeepModeElement) {
					beatsDataDispatch(setDeepModeElement(null))
				}
				
			})
			.catch(e => {
				console.error(e)
				setCurrentElement()

				if (e.message.includes('subscription')) {
					toastHandler('error', e.message, null, 'RelationshipList', 'Element Deletion')

					if (
						user?.subscriptionStatus?.status === 'trialing' ||
						user?.subscriptionStatus?.status === 'active'
					) {
						window.location.reload()
					}
				} else {
					toastHandler(
						'error',
						`${currentElement?.name} could not be deleted.`,
						null,
						'RelationshipList',
						'Element Deletion',
					)
				}
			})
	}

	const reorderNodesAfterDelete = async deletedNodeId => {
		const mutationData = []

		const deletedNode = graphData.nodes[deletedNodeId]

		graphData.nodesByType[deletedNode.__typename].forEach(element => {
			if (element?.order > deletedNode?.order) {
				mutationData.push({
					id: element?.id,
					order: String(element?.order - 1),
				})
			}
		})

		await reorderNodes({
			variables: {
				nodeType: deletedNode.__typename,
				newNodeOrder: mutationData,
			},
		})
			.then(res => {
				if (res && serverError) {
					beatsDataDispatch(setServerError(false))
					clearInterval(serverErrorInterval.current)
				}
			})
			.catch(async err => {
				if (err.message.includes('Cannot update')) {
					beatsDataDispatch(setNoElementError(true))
				}
				if (err.message.includes('Failed to fetch') || err.message.includes('NetworkError')) {
					beatsDataDispatch(setServerError(true))
				}
			})
			.finally(() => {
				setTimeout(() => refetchUser(), 0)
			})
	}


	useEffect(() => {
		if ((isMobile || isTablet) && graphData?.nodes[id]?.lastDocView === 'full') {
			setView('open')
			setMouseOverView('open')
		} else {
			setView(graphData?.nodes[id]?.lastDocView || 'open')
			setMouseOverView(graphData?.nodes[id]?.lastDocView || 'open')
		}
	}, [graphData?.nodes[id]?.lastDocView, isTablet, isMobile])

	let type = nodeTypeForId(id)
	const [updateElement] = useUpdateNodeMutation(type)

	useEffect(() => {
		const baseContainer = document.getElementById('baseContainer')
		const bottomDriverDoc = document.getElementById('bottom-driver-doc')

		if (view === 'full') {
			baseContainer?.setAttribute('style', 'position: relative;')
			bottomDriverDoc?.classList.add('full-view')
		} else {
			baseContainer?.setAttribute('style', 'position: unset;')
			bottomDriverDoc?.classList.remove('full-view')
		}
	}, [view])

	const handleDriverView = driverView => {
		setView(driverView)
		setMouseOverView(driverView)
		const updateData = {
			lastDocView: driverView,
			updatedAt: new Date().toISOString(),
		}
		updateCacheField(client, { id: id, __typename: nodeTypeForId(id) }, updateData, true)

		updateElement({
			variables: {
				id,
				input: {
					lastDocView: driverView,
					updatedAt: new Date().toISOString(),
				},
			},
		})
	}

	useEffect(() => {
		let id = graphData.nodes[id]?.id
		if (id) beatsDataDispatch(setDeepModeElement({ id: id, nodeType: type }))
	}, [type])

	useEffect(() => {
		if (!!activeElement.id && activeElement.type !== 'Chapter') {
			setActive(activeElement.type)
		}
	}, [activeElement])

	useEffect(() => {
		if (!id) {
			if (scrollRef.current) {
				scrollRef.current.scrollTop = scrollPosition
			}
		}
	}, [id])

	useEffect(() => {
		if (id && docType === 'Driver') {
			const doesPremiseConnectionExist = graphData?.allNodes?.nodes?.filter(
				node => node.coreElement === 'true' && node.id === id,
			)
			let doesSecondary = graphData?.nodes[id]?.staticConnections?.filter(conn => {
				return (
					graphData?.nodes[conn.destNode.id]?.coreElement === 'true' ||
					graphData?.nodes[conn.sourceNode.id]?.coreElement === 'true'
				)
			})

			setDesignation(
				doesPremiseConnectionExist?.length
					? 'Premise'
					: doesSecondary?.length
					? 'Secondary'
					: 'Minor',
			)
		}
	}, [graphData?.allNodes?.nodes, id])

	const RenderDescriptionFields = useCallback(
		({ descriptionFields }) => {
			return descriptionFields?.map((fields, index) => {
				return (
					<DescriptionArea key={`${fields.slug}${index}`}>
						<DescriptionLable>{fields.label}</DescriptionLable>
						<DescriptionFieldWrapper>
							<DescriptionField
								id={id}
								key={`${id}-${fields.slug}`}
								fieldName={fields.slug}
								type={nodeTypeForId(id)}
								createLog={createLog}
								elementDescription={
									graphData.nodes[id]?.[fields.slug] || currentStory?.[fields.slug]
								}
								isArcConnection={false}
								isElement={true}
								placeholder={fields.placeholder}
							/>
						</DescriptionFieldWrapper>
					</DescriptionArea>
				)
			})
		},
		[graphData.nodes[id], currentStory?.id, view],
	)

	return (
		<div
			style={
				{
					// padding: `0 15px 15px 10px`,
					// background: `rgb(250, 250, 250)`,
				}
			}
		>
			<DriverDocWrapper view={view}>
				<LeftHeaderData>
					{["Driver","Chapter"].includes(docType) && (
						<DeleteIcon
							src={trashIcon}
							alt="Delete Button"
							onClick={e => {
								e.stopPropagation()
								let name = graphData?.nodes[id]?.name
								let type = graphData?.nodes[id]?.__typename
								setCurrentElement({ name, id, type })

								createLog(
									'Element Deletion Attempted',
									`{"workflowStep":${1},"elementType":"${type}"}`,
									'ListElement',
									'Element Deletion',
								)
							}}
						/>
					)}
					<CharacterImageWrapper>
						<IconContainer isSelected={true} color={type}>
							<img src={getNodeIcon(type)} />
						</IconContainer>
					</CharacterImageWrapper>
					<DriverLabelArea>
						<DriverName>
							{docType === 'Chapter' && <>Ch:</>}
							{docType === 'Chapter' && (
								<TextField
									id={id}
									size={'20px'}
									type={'Chapter'}
									content={graphData?.nodes[id]?.number}
									placeholder={`0`}
									isNumber={true}
									// isRightPanel={isRightPanel}
								/>
							)}
							{['Driver', 'Chapter'].includes(docType) ? (
								<TextField
									id={graphData?.nodes[id]?.id}
									size={'90%'}
									type={graphData?.nodes[id]?.__typename || 'Character'}
									content={graphData?.nodes[id]?.name}
									placeholder={`New ${graphData.nodes[id]?.__typename}`}
									isNumber={false}
									// isRightPanel={isRightPanel}
								/>
							) : (
								docType
							)}
						</DriverName>

						<LableContainer>
								<>
									{docType === 'Driver' && (<DesignationLabel type={designation}>{designation}</DesignationLabel>)}
									{["Chapter","Driver"].includes(docType) && (<DriverTypeLable color={graphData.nodes[id]?.__typename}>
										{graphData.nodes[id]?.__typename}
									</DriverTypeLable>)}
								</>
							
							{['Driver', 'Chapter'].includes(docType) && (
								<NotesContainer>
									<NotesCount>{graphData.nodes[id]?.noteConnections?.length || 0}</NotesCount>
									<NotesIcon src={notesIcon} alt="notes-icon" />
									<NotesLabel>Related Notes</NotesLabel>
								</NotesContainer>
							)}
						</LableContainer>
					</DriverLabelArea>
				</LeftHeaderData>
				<LayoutView>
					{!['Premise', 'Outlining', 'Writing'].includes(docType) && (
						<LayoutStyleContainer
							onClick={() => {
								// beatsDataDispatch(setDeepModeElement())
								// sharedDispatch(setCurrentStory({ ...currentStory, lastOpenedDriver: '' }))
								// editStory({
								// 	variables: {
								// 		id: currentStory?.id,
								// 		input: {
								// 			lastOpenedDriver: '',
								// 		},
								// 	},
								// })

								sharedDispatch(
									setActiveConnections({
										isActive: activeConnections.docType === 'Chapter',
										elementId: null,
										elementType: null,
										docType: null,
										appView: activeConnections.appView,
									}),
								)
							}}
						>
							<img src={closeIcon} alt="close-icon" />
							<p>Close</p>
						</LayoutStyleContainer>
					)}
					<LayoutStyleContainer
						isActive={view === 'reg'}
						onClick={() => {
							handleDriverView('reg')
						}}
						onMouseEnter={() => {
							setMouseOverView('reg')
						}}
						onMouseLeave={() => {
							setMouseOverView(null)
						}}
					>
						<img
							src={view === 'reg' || onMouseOverView === 'reg' ? activeRegIcon : regIcon}
							alt="reg-icon"
						/>
						<p>Reg</p>
					</LayoutStyleContainer>
					<LayoutStyleContainer
						isActive={view === 'open'}
						onClick={() => {
							handleDriverView('open')
						}}
						onMouseEnter={() => {
							setMouseOverView('open')
						}}
						onMouseLeave={() => {
							setMouseOverView(null)
						}}
					>
						<img
							src={view === 'open' || onMouseOverView === 'open' ? activeOpenIcon : openIcon}
							alt="open-icon"
						/>
						<p>Open</p>
					</LayoutStyleContainer>
					<LayoutStyleContainer
						isActive={view === 'full'}
						hide={isSmallScreen}
						onClick={() => {
							sharedDispatch(setIsCatelogOpen(false))
							handleDriverView('full')
						}}
						onMouseEnter={() => {
							setMouseOverView('full')
						}}
						onMouseLeave={() => {
							setMouseOverView(null)
						}}
					>
						<img
							src={view === 'full' || onMouseOverView === 'full' ? activeFullIcon : fullIcon}
							alt="full-icon"
						/>
						<p>Full</p>
					</LayoutStyleContainer>
				</LayoutView>
			</DriverDocWrapper>
			{view !== 'reg' && (
				<MiddleSection isVisible={view !== 'reg'} view={view}>
					<MiddleSectionDescription>
						{<RenderDescriptionFields descriptionFields={middleSectionDecriptionFields} />}
					</MiddleSectionDescription>
					<MiddleSectionInputComponent />
				</MiddleSection>
			)}
			<BottomSectionContainer id="bottom-driver-doc" view={view} isReg={view === 'reg'}>
				{/* <DividerContainer isVisible={view !== 'full'}>
					<DividerLine />
					<DividerButton
						onClick={() => {
							view === 'open' ? handleDriverView('reg') : handleDriverView('open')
						}}
						onMouseEnter={() => {
							setOnMouseOverToggleIcon(true)
						}}
						onMouseLeave={() => {
							setOnMouseOverToggleIcon(false)
						}}
					>
						<InnerView>
							<img src={view === 'open' ? openRegView : openViewIcon} alt="open-svg-view" />
							<Tooltip isVisible={onMouseOverToggleIcon}>
								{view === 'reg' ? `Open View` : `Close View`}
							</Tooltip>
						</InnerView>
					</DividerButton>
					<DividerLine />
				</DividerContainer> */}
				<Connection isVisible={view !== 'full'}>
					<SectionDeviderLine isReg={view === 'reg'}>
						<InnerView>
							<img
								src={view === 'open' ? openRegView : openViewIcon}
								alt="open-svg-view"
								onClick={() => {
									view === 'open' ? handleDriverView('reg') : handleDriverView('open')
								}}
								onMouseEnter={() => {
									setOnMouseOverToggleIcon(true)
								}}
								onMouseLeave={() => {
									setOnMouseOverToggleIcon(false)
								}}
							/>{' '}
							<Tooltip isVisible={onMouseOverToggleIcon}>
								{view === 'reg' ? `Open View` : `Close View`}
							</Tooltip>
						</InnerView>
					</SectionDeviderLine>
				</Connection>
				<DriverDocBottomSection>
					<BeatSectionWrapper view={view} ref={scrollRef}>
						{id && <BottomSectionInputComponent />}
					</BeatSectionWrapper>
				</DriverDocBottomSection>
			</BottomSectionContainer>

			{currentElement?.id && (
				<DeleteModal
					elementType={currentElement?.type?.toLowerCase()}
					nodeName={`${currentElement?.name}`}
					isShowed={currentElement}
					details=" and its beats that don't have another driver will be removed too"
					isConnection={currentElement?.type === 'Beat' || currentElement?.type === 'Connection'}
					acceptHandler={async () => {
						if (currentElement.type === 'Connection') {
							removeConnection(currentElement)
							createLog(
								'Connection Deletion Confirmed',
								`{"workflowStep":${2},"connectionType":"Static"}`,
								'ElementList',
								'Connection Deletion',
							)
						} else {
							createLog(
								`Element Deletion Confirmed`,
								`{"workflowStep":${2},"elementType":"${currentElement?.type}"}`,
								'ElementList',
								'Element Deletion',
							)

							if (currentElement?.type !== 'Beat') {
								sharedDispatch(
									setActiveConnections({
										isActive: false,
										elementId: null,
										elementType: null,
										appView: currentStory.lastDocView,
									}),
								)
								// sharedDispatch(setCurrentStory({ ...currentStory, lastOpenedDriver: '' }))
								// editStory({
								// 	variables: {
								// 		id: currentStory?.id,
								// 		input: {
								// 			lastOpenedDriver: '',
								// 		},
								// 	},
								// })
								// setExpandedElementId("")
							}
							removeElement(currentElement?.id, currentElement?.type)
						}
						// const connectionCount = getConnectionCount(client)
						// sharedDispatch(
						// 	setConnectionCount({
						// 		supportingCount: connectionCount.Supporting,
						// 		opposingCount: connectionCount.Opposing,
						// 		undefinedCount: connectionCount.Undefined,
						// 		total: connectionCount.Total,
						// 	}),
						// )
						setCurrentElement()
					}}
					closeHandler={() => {
						if (currentElement.type === 'Connection') {
							createLog(
								'Connection Deletion Cancelled',
								`{"workflowStep":${2},"connectionType":"Static"}`,
								'ElementList',
								'Connection Deletion',
							)
						} else {
							createLog(
								'Element Deletion Cancelled',
								`{"workflowStep":${2},"elementType":"${currentElement?.type}"}`,
								'ElementList',
								'Element Deletion',
							)
						}
						setCurrentElement()
					}}
				/>
			)}
		</div>
	)
}

export default React.memo(ThreePartDoc)
