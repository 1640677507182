/* eslint-disable array-callback-return */
/* eslint-disable no-unused-vars */
/* eslint-disable import/no-cycle */
/* eslint-disable react/display-name */
/* eslint-disable prettier/prettier */
import React, { useState, useEffect, useContext } from 'react'
import Popover from '@mui/material/Popover'
import { makeStyles } from '@mui/styles'
import Element from './Element'
import { IconContainer, InputContainer } from './styles'
import LensIcon from '@lynit/shared/src/images/emptyLens.svg'
import { graphDataStateContext } from '@lynit/shared/src/state/graphDataProvider'
import { AddElementContainer } from '@lynit/shared/src/ui/CreateConnectionModal/styles'
import {
	createNodeId,
	nodeTypeForId,
} from '@lynit/shared/src/utils/utils'
import { setNewConnId } from '@lynit/shared/src/state/actions'
import addIcon from '@lynit/shared/src/images/add-driver-icon.svg'
import { sharedContext } from '@lynit/shared/src/state'
import { userStateContext } from '@lynit/shared/src/state/userProvider'
import { useApolloClient } from '@apollo/client'
import { useCreateConnectionAndElementMutation } from '@lynit/shared/src/hooks/createConnectionAndElement'

const useStyles = makeStyles(() => ({
	root: {
		width: '180px',
		height: '200px',
		padding: '0 10px',
	},
}))

const LinkNoteModal = React.memo(
	({
		isRightSidePanel,
		objectList,
		createConnection,
		isActive,
		currentId,
		setConnectionModal,
		connectionArc,
		sourceId,
		sourceNode,
		createLog,
		position,
		elementName,
		isDriver,
		type,
		mainElementId,
		suggetionHandleClick,
		isNote,
		
	}) => {
		
		const classes = useStyles()
		const [isEdit, setIsEdit] = useState(false)
		const [currentElementName, setCurrentElementName] = useState('')
		const user = useContext(userStateContext)
		const client = useApolloClient()
		const {
			dispatch,
			//state: { refetchUser }  ,
		} = useContext(sharedContext)

		const nodeList = objectList?.filter(
			e => {
				return ['Arc', 'Event','Theme', 'Character', 'Chapter'].includes(e.__typename) && e.id !== currentId
				}
		).sort((a,b) => {
			if(isNote){
				return a.__typename.localeCompare(b.__typename) || a.name.localeCompare(b.name) 
			}else{
				return a.staticConnections.length - b.staticConnections.length
			}
		})

		const [performCreateConnectionAndElement] = useCreateConnectionAndElementMutation()

		const createStaticConnectionAndElement = async (
			newConnection,
			newElement,
			optimisticResponse,
		) => {
			await performCreateConnectionAndElement({
				client,
				newConnection,
				newElement,
				user,
				optimisticResponse,
				hasOrder: true,
				broadcast: false,
			})
		}

		const [objectArray, setObjectArray] = useState(nodeList)
		const [filterInput, setFilterInput] = useState('')

		const handleCreateElement = async e => {
			const newNodeId = createNodeId(type)
			const tempObj = {
				id: newNodeId,
				createdAt: new Date().toISOString(),
				updatedAt: new Date().toISOString(),
				order: '0',
				__typename: type,
				name: currentElementName,
				description: '',
				beatConnections: [],
				driverConnections: [],
				childConnections: [],
				staticConnections: [],
				noteConnections: [],
				xCoordinate: null,
				yCoordinate: null,
				vizLocSaved: null,
				firstBeat: '',
				coreElement: 'false',
				storyText: '',
				wordCount: '0',
				innerConflict: '',
				externalConflict: '',
				type: '',
				goalsAndNeeds: '',
				centralConflict: '',
				lastDocView: '',
				lastOpenedDate: '',
			}
			const optimisticResponse = {}
			optimisticResponse[`create${type}`] = tempObj

			const structureTag = null

			const tempId = createNodeId('Relationship')

			const newConnection = {
				id: tempId,
				description: '',
				arcStage: null,
				relName: 'RELATED',
				order: null,
				connectionType: 'Static',
				structureTag,
				arc: null,
				sourceNode: {
					id: mainElementId,
					__typename: nodeTypeForId(mainElementId),
				},
				destNode: {
					id: newNodeId,
					__typename: nodeTypeForId(newNodeId),
				},
				__typename: 'Relationship',
				beatsDriven: null,
				sharedBeats: [],
				firstBeat: '',
				createdAt: new Date().toISOString(),
				updatedAt: new Date().toISOString(),
			}
			dispatch(setNewConnId(tempId))
			setConnectionModal(false)

			setIsEdit(false)

			createStaticConnectionAndElement(newConnection, tempObj, optimisticResponse)
			suggetionHandleClick(newConnection)
		}
		//link node
		
		// useEffect(() => {
		// 		if(isActive){
		// 			document.getElementById('linkSearchInput')?.focus();
		// 		}
				
			
		// 	console.log("linkNoteModal loaded")
		// 	console.log("document.getElementById('linkSearchInput')",document.getElementById('linkSearchInput'))
		// }, [isActive])

		useEffect(() => {
			setObjectArray(
				nodeList.filter(
					node => node?.name?.toLowerCase()?.includes(filterInput),
				),
			)
			
			
		}, [objectList, filterInput])

		

		return (
			<div className="addDriver">
				<Popover
					id="connection-modal"
					anchorEl={isActive}
					transformOrigin={{ vertical: position === 'top' ? 'bottom' : 'top', horizontal: 'left' }}
					open={Boolean(isActive)}
					onClose={() => setConnectionModal(false)}
					classes={{ paper: classes.root }}
					style={{
						zIndex:'999999999999'
					}}
				>
					<span
						style={{
							alignItems: 'center',
							fontFamily: 'Source Sans Pro',
							fontWeight: '700',
							fontSize: '14px',
							letterSpacing: '0.05em',
							marginBottom: '5px',
						}}
					>
						Connect to:
					</span>
					<InputContainer className="container">
						<img src={LensIcon} alt="LensIcon" />
						<input
							autoFocus
							id={"linkSearchInput"}
							defaultValue={filterInput}
							type="text"
							placeholder="Search"
							onChange={e => setFilterInput(e.target.value.toLowerCase())}
						/>
					</InputContainer>

					{objectArray?.length > 0 ? objectArray?.filter(item => {
					if(item.name !== elementName) {
						return item
					}})
						.map(e => (
							<Element
									isRightSidePanel={isRightSidePanel}
									isArc={false}
									connectionArc={connectionArc}
									sourceId={sourceId}
									sourceNode={sourceNode}
									key={e?.id}
									name={e.__typename === 'Chapter' ? `Ch ${e.number}: ${e.name}` : e.name}
									type={e.__typename}
									id={e?.id}
									e={e}
									createConnection={createConnection}
									setFilterInput={setFilterInput}
									setConnectionModal={setConnectionModal}
									createLog={createLog}
									isNote={isNote}
									isDriver={isDriver}
									staticConnections={e?.staticConnections?.length}
									/>
								))
						: 'No results'}

					{isDriver && (
						<AddElementContainer type={type} isEdit={isEdit}>
							<IconContainer type={type} isElement={true} onClick={() => setIsEdit(true)}>
								<img src={addIcon} alt="new-Character-icon" width={'10px'} height={'10px'}/>
							</IconContainer>
							{!isEdit ? (
								<span onClick={() => setIsEdit(true)}>Fast Create New {type}</span>
							) : (
								<div className="input-container" type={type}>
									<input
										autoFocus
										type="text"
										placeholder="Name"
										onChange={e => {
											setCurrentElementName(e.target.value)
										}}
									/>

									{currentElementName?.length ? (
										<button onClick={e => handleCreateElement(e)}>Create</button>
									) : null}
								</div>
							)}
						</AddElementContainer>
					)}
				</Popover>
			</div>
		)
	},
)

export default LinkNoteModal