import styled from 'styled-components'

const SearchCatelogStyled = styled.div`
	background: rgba(255, 255, 255, 0.5);
	border: 1px solid rgba(199, 199, 199, 0.2);
	//margin: 10px;
	padding: 0;
	position: relative;
	width: 100%;
`
const Filters = styled.div`
	display: flex;
	gap: 20px;
	justify-content: center;
	align-items: center;
`
const MobileExtraFilter = styled.div`
	display: none;
	justify-content: space-between;
	padding: 0 20px;
	@media (max-width: 769px) {
		display: flex;
	}
`
const FilterContainer = styled.div`
	padding: 11px 0;
	border-bottom: 1px solid rgba(232, 232, 232, 1);
	display: flex;
	justify-content: space-around;
	position: sticky;
	top: -2px;
	z-index: 999;
	background: white;
	@media (max-width: 769px) {
		flex-direction: column;
		gap: 10px;
	}
`
const ViewByContainer = styled.div`
	padding: 10px 20px 10px 20px;
	gap: 27px;
	display: flex;
	font-family: Source Sans Pro;
	font-size: 14px;
	line-height: 12.57px;
	text-align: left;
	background: rgba(243, 243, 243, 1);
`
const ViewByLabel = styled.div`
	font-weight: 700;
	color: rgba(199, 199, 199, 1);
`
const ViewBySelect = styled.div`
	display: flex;
	gap: 27px;
`
const OptionLabel = styled.div`
	cursor: pointer;
	color: ${props => (props.isActive ? 'rgba(76, 76, 76, 1)' : 'rgba(199, 199, 199, 1)')};
	font-weight: ${props => (props.isActive ? '700' : '400')};
`
const FilterLabel = styled.div`
	font-weight: ${props => (props.isActive ? '600' : 'normal')};
	font-size: 14px;
	cursor: pointer;
	@media (max-width: 600px) {
		font-size: 12px;
	}
`
const SearchResultLabel = styled.div`
	font-weight: 600;
	font-size: 14px;
	cursor: pointer;
	display: ${props => (!props.hide ? 'none' : 'unset')};
	padding: ${props => (!props.hide ? 'unset' : '0 20px')};
	@media (max-width: 600px) {
		font-size: 11px;
	}
`
const SearchContainer = styled.div`
	width: 100%;
	box-sizing: border-box;
	width: 100%;
	padding: 0 5px;
	height: 100%;
	display: grid;
	grid-column-gap: 20px;
	grid-row-gap: 20px;

	grid-template-columns: ${props => (props.single ? `1fr` : `1fr 1fr`)};

	@media (max-width: 1024px) {
		grid-template-columns: 1fr 1fr;
	}

	@media (max-width: 600px) {
		grid-template-columns: 1fr;
	}
	@media (max-width: 769px) {
		grid-template-columns: 1fr;
		max-width: unset;
	}
`

const SearchResult = styled.div`
	display: flex;
	gap: 20px;
	padding: 20px;
`

const Card = styled.div``

const InputContainer = styled.div`
	position: relative;
	width: 100%;
	max-width: 400px;
	right: 10px;
`

const SearchInput = styled.input`
	padding: 8px 16px 8px 40px;
	border-radius: 4px;
	width: auto;
	font-size: 12px;
	border: 0.5px solid rgba(199, 199, 199, 1);
	background: rgba(243, 243, 246, 1);
	outline: none;
	cursor: pointer;
	justify-content: center;
	text-align: left;
	@media (min-width: 1024px) {
		width: 200px;
	}
	@media (max-width: 600px) {
		font-size: 0.5625rem;
		padding: 8px 16px 8px 28px;
	}

	@media (max-width: 426px) {
		padding: 8px 0px 8px 24px;
	}
`

const IconWrapper = styled.div`
	position: absolute;
	top: 50%;
	left: 10px;
	transform: translateY(-50%);
	pointer-events: none;
	display: flex;
	align-items: center;
	justify-content: center;
	img {
		width: 20px;
		height: 20px;
	}
	@media (max-width: 600px) {
		img {
			width: 13px;
			height: 13px;
		}
	}
`
const ExitSearchIcon = styled.div`
	position: absolute;
	top: 50%;
	right: 0px;
	transform: translateY(-50%);
	cursor: pointer;
	display: flex;
	align-items: center;
	justify-content: center;
	background: rgba(199, 199, 199, 1);
	height: 70%;
	z-index: 99;
	padding: 5px 10px 5px 10px;
	border-radius: 0px 5px 5px 0px;
	font-family: Source Sans Pro;
	font-size: 12px;
	font-weight: 400;
	line-height: 15.08px;
	text-align: left;
	@media (max-width: 600px) {
		font-size: 0.5625rem;
		padding: 4px 10px 4px 10px;
	}
`

export {
	SearchCatelogStyled,
	FilterLabel,
	Filters,
	SearchResult,
	Card,
	InputContainer,
	SearchInput,
	IconWrapper,
	FilterContainer,
	SearchResultLabel,
	SearchContainer,
	ViewByContainer,
	ViewByLabel,
	ViewBySelect,
	OptionLabel,
	ExitSearchIcon,
	MobileExtraFilter,
}
