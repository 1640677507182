/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable arrow-parens */
/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable prettier/prettier */
import React, { useState, useEffect, useContext, useRef } from 'react'

import { useHistory } from 'react-router-dom'
import { sharedContext } from '@lynit/shared/src/state/sharedProvider'
import { userStateContext } from '@lynit/shared/src/state/userProvider'
import { systemStateContext } from '@lynit/shared/src/state/systemProvider'
import {
	beatsDataDispatchContext,
	beatsDataStateContext,
} from '@lynit/shared/src/state/beatsProvider'
import { graphDataStateContext } from '@lynit/shared/src/state/graphDataProvider'
import {

	setActiveConnections,
	setCurrentStory,
	setIsCatelogOpen,
} from '@lynit/shared/src/state/actions'
import ToolTip from '@lynit/shared/src/ui/ToolTip'
import { autoSaveStateContext } from '@lynit/shared/src/state/autoSaveProvider'
import { toastHandler } from '@lynit/shared/src/utils/backendHandler'
import {
	createCacheElement,
	deleteCacheElement,
	updateCacheField,
	updateCacheNameElement,
} from '@lynit/shared/src/utils/apollo'
import { getNodeIcon, createNodeId, nodeTypeForId } from '@lynit/shared/src/utils/utils'
import {
	useReorderNodesMutation,
	useCreateNodeMutation,
	useEditStoryMutation,
	useGetRelationshipsQuery,
	useUpdateNodeMutation,
	useDraggable,
} from '@lynit/shared/src/hooks'

import { useMemo } from 'react'
import { useCallback } from 'react'
import ThreePartDoc from '../ThreePartDoc'
import ChapterView from '@lynit/story-outline/src/ui/ChapterView'
import DeepModeElement from '@lynit/deep-mode-element/src/ui/DeepModeElement'
import { IdleTimerConsumer } from 'react-idle-timer'
import ChapterBeatsContainer from '@lynit/shared/src/ui/ChapterBeatsContainer'
import { orderBy } from 'lodash'
import { useDndContext } from '@dnd-kit/core'

const ChapterDoc = ({
	id,
}) => {
	
	const { active, over } = useDndContext()
	
	
	const graphData = useContext(graphDataStateContext)

	const newBeatList = graphData.nodes[id]?.beatConnections.map(connection => {
		const beatId = connection?.sourceNode?.id?.startsWith('bea')
							? connection?.sourceNode?.id
							: connection?.destNode?.id
		return { id:beatId,connectionId:connection.id, order: +connection.order }
	})
	
	const orderedBeatList= orderBy(newBeatList, ['order'], ['asc'])
	const beatsList = useDraggable(id, active, over, orderedBeatList,"beatConnections")
	

	
	const BottomSectionInputComponent = useCallback(() => {
		return(<DeepModeElement
			id={id}
		/>)
	},[id])
	
	const middleSectionDecriptionFields = {
		Chapter: [
			{ label: 'Summary', slug: 'description', placeholder: `Summarize what happens in this Chapter.`, },
			//{ label: 'Chapter Highlight', slug: 'narrativeVoice', placeholder: `Narrative Voice Placeholder Text`, },
		],
		
	}

	const MiddleSectionInputComponent = useCallback(() => {

		return(
			<div
			style={{
				maxWidth:"400px",
				display: "flex",
				flexDirection: "column",
				padding: " 10px 0px 0px 0px"
			}
			}>
				<ChapterBeatsContainer
						id={id}
						type={"Chapter"}
						beatsList={beatsList}
						
				/>
			</div>
		)



	},[id,beatsList])


	// return useMemo(() => {
	return id && (
		<ThreePartDoc
		id={id}
		docType={"Chapter"}
		middleSectionDecriptionFields={middleSectionDecriptionFields["Chapter"]}
		MiddleSectionInputComponent={MiddleSectionInputComponent}
		BottomSectionInputComponent={BottomSectionInputComponent}
		/>
	) 
	
}

export default React.memo(ChapterDoc)
