import gql from "graphql-tag"

export const TASK_FRAGMENT = gql`
	fragment taskFields on Task {
		id
		description
		completed
		createdAt
		updatedAt
		recommendationId
		order
	}
`

export const RECOMMENDATION_FRAGMENT = gql`
	fragment recommendationFields on Recommendation {
		title
		id
		image
		message
		completeConditions {
			summaryLevel
			userFriendlyMetricName
			metric
			metricCurrentValue
			operation
			value
		}
		type
		timeToComplete
		difficulty
		valueProps
		status
		level
		order
		visibility
		updatedAt
	}
`

export const relationshipFieldsFragment = gql`
	fragment relationshipFields on Relationship {
		description
		arc {
			id
		}
		arcStage
		beatsDriven
		sharedBeats {
			id
		}
		connectionType
		structureTag
		order
		relName
		firstBeat
		createdAt
		updatedAt
	}
`

export const nodeFieldsFragment = gql`
	fragment nodeFields on Node {
		... on Arc {
			name
			description
			createdAt
			updatedAt
			order
			storyText
			xCoordinate
			yCoordinate
			coreElement
			vizLocSaved
			firstBeat
			lastOpenedDate
			lastDocView
			centralConflict
		}
		... on Node {
			createdAt
			updatedAt
			order
			firstBeat
		}
		... on Chapter {
			number
			order
			name
			description
			createdAt
			updatedAt
			storyText
			wordCount
			xCoordinate
			yCoordinate
			vizLocSaved
			firstBeat
			lastDocView
			lastOpenedDate
		}
		... on Character {
			name
			order
			description
			createdAt
			updatedAt
			storyText
			xCoordinate
			yCoordinate
			coreElement
			vizLocSaved
			firstBeat
			lastOpenedDate
			lastDocView
			innerConflict
			externalConflict
			goalsAndNeeds
		}
		... on Note {
			title
			contents
			createdAt
			updatedAt
			xCoordinate
			yCoordinate
			vizLocSaved
			firstBeat
		}
		... on Event {
			name
			order
			description
			createdAt
			updatedAt
			storyText
			xCoordinate
			yCoordinate
			coreElement
			vizLocSaved
			firstBeat
			lastOpenedDate
			lastDocView
		}
		... on Theme {
			name
			order
			description
			createdAt
			updatedAt
			storyText
			xCoordinate
			yCoordinate
			coreElement
			vizLocSaved
			firstBeat
			lastOpenedDate
			lastDocView
		}
		... on Beat {
			title
			description
			createdAt
			updatedAt
			order
			xCoordinate
			yCoordinate
			vizLocSaved
			firstBeat
		}
		... on Premise {
			description
			narrativeDrive
			createdAt
			updatedAt
			lastDocView
			lastOpenedDate
		}
		... on Outlining {
			description
			createdAt
			updatedAt
			lastDocView
			lastOpenedDate

			
			
		}
		... on Writing {
			description
			toneAndStyle
			narrativeVoice
			createdAt
			updatedAt
			lastDocView
			lastOpenedDate
			
		}
	}
`

export const connectionsFragment = gql`
	fragment beatConnections on Node {
		beatConnections {
			id
			...relationshipFields
			destNode {
				id
			}
			sourceNode {
				id
			}
		}
	}
	${relationshipFieldsFragment}
`

export const driverConnectionsFragment = gql`
	fragment driverConnections on Node {
		driverConnections {
			id
			...relationshipFields
			destNode {
				id
			}
			sourceNode {
				id
			}
		}
	}
	${relationshipFieldsFragment}
`

export const childConnectionsFragment = gql`
	fragment childConnections on Node {
		childConnections {
			id
			...relationshipFields
			destNode {
				id
			}
			sourceNode {
				id
			}
		}
	}
	${relationshipFieldsFragment}
`

export const staticConnectionsFragment = gql`
	fragment staticConnections on Node {
		staticConnections {
			id
			...relationshipFields
			destNode {
				id
			}
			sourceNode {
				id
			}
		}
	}
	${relationshipFieldsFragment}
`

export const noteConnectionsFragment = gql`
	fragment noteConnections on Node {
		noteConnections {
			id
			...relationshipFields
			destNode {
				id
			}
			sourceNode {
				id
			}
		}
	}
	${relationshipFieldsFragment}
`
