/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
/* eslint-disable no-nested-ternary */
import React, { useState, useContext, useEffect, useRef } from 'react'
import { useApolloClient } from '@apollo/client'
import {
	SortableContext,
	useSortable,
	verticalListSortingStrategy,
	arrayMove,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import Quill from 'quill'
import orderBy from 'lodash/orderBy'
import Tooltip from '@mui/material/Tooltip'
import { makeStyles } from '@mui/styles'
import Timeline from '@mui/lab/Timeline'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import { useHistory } from 'react-router-dom'
import { useDndContext } from '@dnd-kit/core'
import { sharedContext } from '@lynit/shared/src/state/sharedProvider'
import { graphDataStateContext } from '@lynit/shared/src/state/graphDataProvider'
import { systemStateContext } from '@lynit/shared/src/state/systemProvider'
import {
	beatsDataDispatchContext,
	beatsDataStateContext,
} from '@lynit/shared/src/state/beatsProvider'
import {
	elementReordered,
	setActiveConnections,
	setConnectionCount,
	setCurrentStory,
	setDeepModeElement,
	setIsCatelogOpen,
	setOpenToolTip,
	setScrollPosition,
} from '@lynit/shared/src/state/actions'
import DeleteModal from '@lynit/shared/src/shared-ui/DeleteModal'
import DescriptionField from '@lynit/shared/src/ui/DescriptionField'
import useQuillAutoSave from '@lynit/shared/src/ui/AutoSave/useQuillAutoSave'

import ChapterBeatsContainer from '@lynit/shared/src/ui/ChapterBeatsContainer'
import SubscriptionFooter from '@lynit/shared/src/ui/SubscriptionFooter'
import TextField from '@lynit/shared/src/ui/Textfield'
import { userStateContext } from '@lynit/shared/src/state/userProvider'
import hash from 'object-hash'

import { getConnectionCount, updateCacheField } from '@lynit/shared/src/utils/apollo'
import Tabs from '@mui/material/Tabs'
import Tab from '@mui/material/Tab'
import Box from '@mui/material/Box'
import CreateConnectionModal from '@lynit/shared/src/ui/CreateConnectionModal'
import ChapterCard from '@lynit/story-outline/src/ui/ChapterView/ChapterCard'
// import StyledTooltip from '../../StoryOutline/StyledToolTip'
import getConnections from '@lynit/shared/src/utils/getConnections'

import documentIconYellow from '../../images/documentYellow.svg'
import documentIcon from '../../images/documentIcon.svg'
import connectionIconDark from '../../images/connectionDark.svg'
import dragger from '@lynit/shared/src/images/dragger.svg'
import beatIconDark from '@lynit/shared/src/images/beatDark.svg'
import arrow from '@lynit/shared/src/images/downCheveron.svg'
import trashIcon from '@lynit/shared/src/images/IconDelete.svg'
import {
	createNodeId,
	getNodeIcon,
	nodeTypeForId,
	sortNodesByCreatedAt,
} from '@lynit/shared/src/utils/utils'
import relationshipIcon from '@lynit/shared/src/images/relationship-icon.svg'

import {
	createCacheConnection,
	createCacheElement,
	deleteCacheConnections,
	deleteCacheElement,
	updateCacheBeatsDriven,
	updateCacheDescriptionElement,
	updateCacheOrderBeat,
} from '@lynit/shared/src/utils/apollo'

import {
	ArrowIcon,
	CollapsedElement,
	ElementContainer,
	UncollapsedElement,
	BottomSection,
	CollapsedContainer,
	DeleteIcon,
	IconsContainer,
	DraggerContainer,
	Element,
	CreateBeatQuill,
	AddBeat,
	ElementWrapper,
	BeatsTitle,
	CreateBeat,
	TabsContainer,
	CountIcon,
	TabContainer,
	BeatsExpanded,
	ConnectionWrapper,
	IndividualConnectionWrapper,
	Line,
	CreateConnectionBtn,
	PlusIcon,
	CreateConnection,
	LeftDashLine,
	DriverSection,
	NewIndividualConnectionWrapper,
	RelationshipHeaderContainer,
	RelationshipLabelContainer,
	RelationshipLabel,
	BeatsCountContainer,
	TotalBeatCount,
	ExtraBeatsContainer,
	ElementCardContainer,
	ElementLable,
	ElementLableText,
	ElementCard,
	CreateRelation,
	CreateRelationLable,
	Card,
	EmptyDriverWrapper,
	EmptyDriverLabel,
	EmptyDriverDescription,
	ExistingBeatLabel,
	ExistingBeat,
	CreateBeatWrapper,
} from './styles'

import {
	useGetRelationshipsQuery,
	useReorderBeatsMutation,
	useReorderNodesMutation,
	useUpdateNodeMutation,
	useDeleteConnectionMutation,
	useCreateBeatMutation,
	useDraggable,
	useEditStoryMutation,
} from '@lynit/shared/src/hooks'

import { nodeColors } from '@lynit/shared/src/utils/commonStyles'
import MissingPremiseDriver from './MissingPremiseDriver'
import NewConnectionCard from './NewConnectionCard'
import whitePlusIconYellowBackground from '@lynit/shared/src/images/whitePlusIconYellowBackground.svg'
import MissingStructureTagDriver from './MissingStructureTagDriver'
import BeatsContainer from '@lynit/shared/src/ui/BeatsContainer'

const Delta = Quill.import('delta')

const useStyles = makeStyles(() => ({
	tooltip: {
		margin: 0,
		background: '#55534F',
		display: props => props.isTooltip && 'none',
	},
	tooltip1: {
		display: props => !props.isCollapsed && 'none',
		background: '#55534F',
	},
	initialsTooltip: {
		background: '#55534F',
	},
	arrow: {
		color: '#55534F',
	},
	timeline: {
		margin: '0',
		padding: '7px 5px',
	},
	timelineItem: {
		'&:before': {
			display: 'none',
		},
		minHeight: 'unset',
		boxSizing: 'border-box',
		gap:"0px",
		alignItems:"center",
		justifyContent: 'center',
	},
	timelineContent: {
		padding: '0px 3px',
		width: 'calc(100% - 20px)',
	},
	timelineDot: {
		margin: 0,
		backgroundColor: '#78C091',
	},
	timelineChapter: {
		margin: 0,
		backgroundColor: nodeColors.Chapter,
		width: '8px',
		height: '8px',
		padding: '0',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: '10px',
		color: '#000000',
	},
	hiddenTimelineConnector: {
		visibility: 'hidden',
	},
	timelineConnector: {
		background: '#78C091',
	},
	dashedTimelineConnector: {
		background: 'transparent',
		border: '1px dashed #78C091',
		width: 0,
	},
	tab: {
		color: '#888585',
		fontFamily: 'Source Sans Pro',
		fontSize: '14px',
		fontStyle: 'normal',
		fontWeight: 700,
		lineHeight: 'normal',
		textTransform: 'none',
		minWidth: '70px',
		padding: '2px 5px',
	},
	tabSelected: {
		color: '#000000 !important',
	},
	indicator: {
		height: '6px',
		borderRadius: '10px',
		backgroundColor: '#FFC300',
	},
	tabs: {
		borderBottom: '6px solid #fff5d4',
		borderRadius: '4px',
	},
}))

const a11yProps = index => {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`,
	}
}

const ListElement = React.memo(
	({
		name,
		description,
		type,
		number,
		id,
		newNode,
		setNewNode,
		deleteNode,
		active,
		filteringData,
		setServerError,
		serverErrorInterval,
		setNoElementError,
		attributes,
		listeners,
		driverConnections,
		isOverlay,
		setExpandedElementId,
		expandedElementId,
		isRightPanel,
		scrollRef,
		connectionModal,
		setConnectionModal,
		setIsSetModifiers,
		setStructureTagConnnectionModal,
		structureTagConnnectionModal,
	}) => {
		const client = useApolloClient()
		const {
			state: { activeConnections, currentStory },
			dispatch: sharedDispatch,
		} = useContext(sharedContext)

		const {
			currentElement: currentDeepModeElement,
			currentStoryId: storyId,
			deepModeElement,
			serverError,
			noElementError,
		} = useContext(beatsDataStateContext)
		
		const beatsDataDispatch = useContext(beatsDataDispatchContext)
		const { createLog, toastHandler } = useContext(systemStateContext)
		const graphData = useContext(graphDataStateContext)
		const { user, refetchUser } = useContext(userStateContext)
		const driverTypeList = ['Character', 'Arc', 'Theme', 'Event']
		const [elementName, setElementName] = useState(name)
		const [elementNumber, setElementNumber] = useState(number)
		const [isEdit, setEdit] = useState(false)
		const [isCollapsed, setCollapsed] = useState(newNode?.id !== id)
		const [beatContentChanged, setBeatContentChanged] = useState(false)
		const [newBeat, setNewBeat] = useState(null)
		const [tempBeatContent, setTempBeatContent] = useState('')
		const [tabValue, setTabValue] = useState(0)
		const [isDocumentHovered, setIsDocumentHovered] = useState(false)
		const [beatsDrivenByConn, setBeatsDrivenByConn] = useState(0)
		const [connUsedAsBeatDrivers, setConnUsedAsBeatDrivers] = useState(0)
		const [connectionsArr, setConnectionsArr] = useState([])
		const [connectionModalType, setConnectionModalType] = useState(false)
		const [isTooltipOpen, setTooltipOpen] = useState(false)
		const [currentElement, setCurrentElement] = useState()
		const [missingPremiseConnections, setMissingPremiseConnections] = useState([])
		const [missingStructureTagConnection, setMissingStructureTagConnection] = useState([])
		const [openedConnectionCard, setOpenedConnectionCard] = useState(false)
		const [currentDriverType, setCurrentDriverType] = useState()
		const [isNewStructureTag, setIsNewStructureTag] = useState()
		const [isAutoSelect,setIsAutoSelect] = useState(false)

		const [createBeat] = useCreateBeatMutation({ ignoreResults: true })
		const [reorderNodes] = useReorderNodesMutation()
		const [updateBeat] = useUpdateNodeMutation('Beat')
		const [performDeleteConnectionMutation] = useDeleteConnectionMutation()
		const { data: relationships } = useGetRelationshipsQuery()

		const countRef = React.useRef()
		const saveInterval = useRef()
		const history = useHistory()
		let change = new Delta()
		const [editStory] = useEditStoryMutation()


		const [beatsList,setBeatslist] = useState([])

		useEffect(()=>{
			const newBeatList = graphData?.nodes[id]?.driverConnections.map(connection => {
				const beatId = connection?.sourceNode?.id?.startsWith('bea')
									? connection?.sourceNode?.id
									: connection?.destNode?.id
				return { id:beatId,connectionId:connection.id, order: +connection.order }
			})
		
			const orderedBeatList= orderBy(newBeatList, ['order'], ['asc'])

			setBeatslist(orderedBeatList)

		},[graphData?.nodes[id]?.driverConnections])

		useEffect(async () => {
			if (newBeat && tempBeatContent) {
				client.cache.modify({
					id: client.cache.identify({
						id: newBeat.id,
						__typename: newBeat.__typename,
					}),
					fields: {
						description() {
							return tempBeatContent.description
						},
					},
				})
				try {
					await updateBeat({
						variables: {
							id: newBeat.id,
							input: {
								description: tempBeatContent.description,
								updatedAt:new Date().toISOString()
							},
						},
					})
				} catch (error) {
					console.error(error)
					client.cache.modify({
						id: client.cache.identify({
							id: newBeat.id,
							__typename: newBeat.__typename,
						}),
						fields: {
							description() {
								return ''
							},
						},
					})
				}
				setNewBeat(null)
				setTempBeatContent('')
			}
		}, [tempBeatContent, newBeat])

		const classes = useStyles({
			isTooltip: deepModeElement?.id === id,
			isCollapsed: expandedElementId !== id,
		})

		const checkBeatContentChanged = (updateId, content, text) => {

			if (text.length >0) {
				setBeatContentChanged(true)
			} else {
				setBeatContentChanged(false)
			}

		}

		const { quill, quillRef } = useQuillAutoSave(
			{
				modules: {
					toolbar: false,
					
				},
				formats:[],
				placeholder: 'Type your first beat here',
			},
			checkBeatContentChanged,
			id,
			"firstBeat",
			"",

		)



		const createBeatHandler = async (beatContent = JSON.stringify({ ops: [{ insert: '\n' }] })) => {
			const beatId = createNodeId('Beat')
			const relId = createNodeId('Relationship')
			const tempData = {
				createBeat: {
					beat: {
						id: beatId,
						beatConnections: [],
						driverConnections: [],
						description: beatContent,
						driverConnections: [],
						order: null,
						parentId: id,
						staticConnections: [],
						noteConnections: [],
						title: '',
						createdAt: new Date().toISOString(),
						updatedAt: new Date().toISOString(),
						xCoordinate: null,
						yCoordinate: null,
						vizLocSaved: false,
						firstBeat: '',
						__typename: 'Beat',
					},
					relationship: {
						arc: {
							id,
							__typename: type,
						},
						connectionType: 'Driver',
						sourceNode: {
							id: beatId,
							__typename: 'Beat',
						},
						id: relId,
						order: String(driverConnections.length || 0),
						destNode: {
							id,
							__typename: type,
						},
						name: null,
						description: null,
						arcStage: null,
						relName: null,
						__typename: 'Relationship',
						structureTag: null,
						beatsDriven: null,
						sharedBeats:[],
						firstBeat: '',
						createdAt: new Date().toISOString(),
						updatedAt: new Date().toISOString(),
						
					},
				},
			}
			await createCacheElement(client, tempData, false, false)
			await createCacheConnection(client, tempData.createBeat.relationship)
			setTimeout(() => {
				const elem = document.getElementById(`${id}-beatContainer`)
				elem.scrollTo({
					top: elem.scrollHeight,
					behavior: 'smooth',
				})
			})

			createLog(
				`Beat Creation Attempted`,
				`{"workflowStep":${1},"parentNodeType":"${type}"}`,
				'Beats Tab',
				'Beat Creation',
			  )

			setNewBeat(tempData.createBeat.beat)
			createBeat({
				variables: {
					beat: {
							id: tempData.createBeat.beat.id,
							description: tempData.createBeat.beat.description,
					}
					,
					relationship: {
						id: tempData.createBeat.relationship.id,
						destNodeId: tempData.createBeat.relationship.destNode.id,
						sourceNodeId: tempData.createBeat.relationship.sourceNode.id,
						description: tempData.createBeat.relationship.description,
						connectionType: tempData.createBeat.relationship.connectionType,
						structureTag: tempData.createBeat.relationship.structureTag,
						relName: 'RELATED',
						beatsDriven: tempData.createBeat.relationship.beatsDriven,
						sharedBeats: tempData.createBeat.relationship.sharedBeats?.map(beat => { return { id: beat.id } }) || [],
						firstBeat: ''
					},
					parentId: id,
				},
				ignoreResults: true,
				
			}).catch(async error => {
				await deleteCacheElement(client, tempData.createBeat.beat)
				await deleteCacheConnections(client, tempData.createBeat.relationship)
				toastHandler('error', `There was an error creating the Beat, try again.`,null, "ListElement","Beat Creation")
				console.error(error)
			})
			quill?.setText('')
			setBeatContentChanged(false)
		}
		
		const displayNewElement = type => {
			switch (type) {
				case 'Theme':
					return `TS&M`
				default:
					return type
			}
		}

		const deleteBeatConnectionFromCache = async beatConnection => {
			await deleteCacheConnections(client, beatConnection)

			const connectedChapterId = beatConnection.destNode?.id
			const connectedChapter = graphData.nodesByType['Chapter'].find(
				chapter => chapter?.id === connectedChapterId,
			)

			const mutationData = []

			connectedChapter?.beatConnections.forEach(connection => {
				if (+connection.order > +beatConnection.order) {
					mutationData.push({
						id: connection.id,
						order: String(+connection.order - 1),
					})
				}
			})

			if (mutationData.length) {
				await updateCacheOrderBeat(client, mutationData)
			}
		}

		const reorderNodesAfterDelete = async deletedNodeId => {
			const mutationData = []

			const deletedNode = filteringData.find(element => element?.id === deletedNodeId)

			filteringData.forEach(element => {
				if (element?.order > deletedNode?.order) {
					mutationData.push({
						id: element?.id,
						order: String(element?.order - 1),
					})
				}
			})

			await reorderNodes({
				variables: {
					nodeType: active,
					newNodeOrder: mutationData,
				},
			})
				.then(res => {
					if (res && serverError) {
						beatsDataDispatch(setServerError(false))
						clearInterval(serverErrorInterval.current)
					}
				})
				.catch(async err => {
					if (err.message.includes('Cannot update')) {
						beatsDataDispatch(setNoElementError(true))
					}
					if (err.message.includes('Failed to fetch') || err.message.includes('NetworkError')) {
						beatsDataDispatch(setServerError(true))
					}
				})
				.finally(() => {
					setTimeout(() => refetchUser(), 0)
				})
		}

		const MissingKeyRelationShip = ({driverType,driverName}) => {
			const description = {
				Character: `Increase ${driverName}’s emotional depth`,
				Theme: `Add thematic significance to ${driverName}`,
				Arc: `Strengthen ${driverName}’s influence on the plot `,
				Event: `Connect ${driverName} to a key moment`,
			}
			return (
				<EmptyDriverWrapper>
					<EmptyDriverLabel>{`${driverName} is missing a${ ["Arc", "Event"].includes(driverType) ? "n " + displayNewElement(driverType) :" " + displayNewElement(driverType)} relationship`}</EmptyDriverLabel>
					<EmptyDriverDescription>{description[driverType]}</EmptyDriverDescription>
				</EmptyDriverWrapper>
			)
		}
		
		const removeElement = async (id, type) => {
			const deletedConnections = []
			const deletedElements = [graphData.nodes[id]]
			const updatedData = []

			const staticConnections = graphData?.nodes?.[id]?.staticConnections
			const driverConnections = graphData?.nodes?.[id]?.driverConnections
			
			

			await deleteNode({
				variables: {
					id,
				},
			})
				.then(async () => {
					refetchUser()
					if (staticConnections?.length) {
						staticConnections.forEach(async connection => {
							deletedConnections.push(connection)
							deleteCacheConnections(client, connection, false)
						})
					}
					if (driverConnections?.length) {
						driverConnections.forEach(async connection => {

							deletedConnections.push(connection)
							deleteCacheConnections(client, connection, false)

						})
					}
					reorderNodesAfterDelete(id)
					if (type === 'Chapter') {
						const deletedNode = graphData.nodes[id]
						deletedNode?.beatConnections.forEach(beatConnection => {
							deletedConnections.push(beatConnection)
							deleteCacheConnections(client, beatConnection)
						})
					}
					await deleteCacheElement(client, currentElement, true)

					if (currentElement?.id === currentDeepModeElement) {
						beatsDataDispatch(setDeepModeElement(null))
					}
					setCurrentElement()
				})
				.catch(e => {
					console.error(e)
					setCurrentElement()

					if (e.message.includes('subscription')) {
						toastHandler('error', e.message,null, "ListElement","Element Deletion")
						
						if (
							user?.subscriptionStatus?.status === 'trialing' ||
							user?.subscriptionStatus?.status === 'active'
						) {
							window.location.reload()
						}
					} else {

						toastHandler('error',  `${currentElement?.name} could not be deleted.`,null, "ListElement","Element Deletion")
						
					}
				})
		}

		const removeConnection = async connToDelete => {
			// Because the element that comes from deleting a connection with a Note is mutated in
			// order to handle the case to edit the Note instead of the conenction, it is necessary
			let elementToDelete
			// let driverConn

			elementToDelete = relationships?.relations?.find(e => e?.id === connToDelete?.id)
			// if (connToDelete?.__typename !== 'Note') {
			// elementToDelete = relationships?.relations?.find(e => e?.id === connToDelete?.id)
				// const drivers = graphData.nodes[id].driverConnections?.filter(e =>
				// 	e.sourceNode.__typename === 'Beat'
				// 		? e.destNode.id === elementToDelete.destNode.id ||
				// 		  e.destNode.id === elementToDelete.sourceNode.id
				// 		: e.sourceNode.id === elementToDelete.sourceNode.id ||
				// 		  e.sourceNode.id === elementToDelete.destNode.id,
				// )

				//driverConn = drivers?.filter(driver => relationships.relations.find(e => e.id === driver.id))
			// } else {
				
			// }
			// if (driverConn) {
			// 	driverConn.forEach(conn => {
			// 		deleteCacheConnections(client, conn)
			// 			.then(() => {
			// 				setTimeout(() => refetchUser(), 1000)
			// 			})
			// 			.catch(e => {
			// 				console.log(e)
			// 			})
			// 	})
			// }
			performDeleteConnectionMutation({
				id: connToDelete?.__typename !== 'Note' ? connToDelete?.id : connToDelete?.id,
				client,
				elementToDelete,
				isCreateCacheConnection:true
			})
			if (
				graphData?.nodes?.[elementToDelete.sourceNode.id].coreElement === 'true' ||
				graphData?.nodes?.[elementToDelete.destNode.id].coreElement === 'true'
			) {
				//refetchPremiseConnections()
			}
		}


		useEffect(() => {
			const connectionCount = {
				Characters: 0,
				Arcs: 0,
				Events: 0,
				Themes: 0,
				Chapters: 0,
				Total: 0,
			}
			const childCount = {
				'In Chapter': 0,
				Unused: 0,
				Total: 0,
			}
			if (graphData?.nodesByType?.[type]?.length) {
				const currentElement = graphData?.nodesByType?.[type]?.find(data => data.id === id)
				if (currentElement?.staticConnections?.length) {
					currentElement?.staticConnections?.forEach(item => {
						if (item?.destNode?.__typename !== 'Note' && item?.sourceNode?.__typename !== 'Note') {
							if (item?.destNode?.id !== id) {
								connectionCount[`${item?.destNode?.__typename}s`] += 1
							} else if (item?.sourceNode?.id !== id) {
								connectionCount[`${item?.sourceNode?.__typename}s`] += 1
							}
							connectionCount['Total'] += 1
						}
					})
				}
				childCount['Total'] = currentElement?.driverConnections?.length||0
				
			}
			countRef.current = { connectionCount, childCount }
		}, [type, graphData?.allNodes.nodes])

		useEffect(() => {
			let beatsDrivenByConn = 0
			driverConnections?.forEach(driverConnection => {
				if (driverConnection?.destNode?.__typename === 'Beat') {
					beatsDrivenByConn = graphData.nodes[driverConnection?.destNode?.id]?.driverConnections
						?.length
						? beatsDrivenByConn + 1
						: beatsDrivenByConn
				} else {
					beatsDrivenByConn = graphData.nodes[driverConnection?.sourceNode?.id]?.driverConnections
						?.length
						? beatsDrivenByConn + 1
						: beatsDrivenByConn
				}
			})
			setBeatsDrivenByConn(beatsDrivenByConn)
		}, [driverConnections])

		useEffect(() => {
			let connUsedAsBeatDrivers = 0
			connectionsArr?.all?.forEach(connections => {
				connUsedAsBeatDrivers =
					connections.beatsDriven && connections.beatsDriven > 0
						? connUsedAsBeatDrivers + 1
						: connUsedAsBeatDrivers
			})
			setConnUsedAsBeatDrivers(connUsedAsBeatDrivers)
			let missingPremiseNodes = []

			if(graphData.nodes[id]?.coreElement === 'true'){
				missingPremiseNodes = graphData.allNodes.nodes.filter(node => {
				
					if ( node.coreElement==='true' && node.id != id && connectionsArr.premise ){

						const doesPremiseConnectionExist = connectionsArr?.premise?.find(conn => node.id ===conn.destNode.id)
	
						return !doesPremiseConnectionExist
	
					}

					return false
					})

			} else {
				
				
				
			}

			setMissingPremiseConnections(missingPremiseNodes)
			let missingStructureTagNodes = []
			missingStructureTagNodes = connectionsArr?.all?.filter(rel => {
				return !rel.structureTag

			})

			setMissingStructureTagConnection(missingStructureTagNodes)
		}, [connectionsArr])

		useEffect(() => {
			if (id) {
				if (saveInterval.current) {
					clearTimeout(saveInterval.current)
				}
				saveInterval.current = setTimeout(() => {
					setConnectionsArr(
						getConnections(id, relationships?.relations, graphData?.nodes),
					)
				}, 100)

				return () => {
					clearTimeout(saveInterval.current)
				}
			}
			if (activeConnections.isActive) {
				setConnectionsArr(
					getConnections(
						activeConnections.elementId,
						relationships?.relations,
						graphData.nodes,
					),
				)
			}
		}, [relationships?.relations, graphData.nodes, activeConnections])

		const getTrimmedNodeName = node => {
			if (node?.name) {
				return node?.name?.length > 26 ? `${node?.name?.substring(0, 26)} ...` : node?.name
			}
			return ''
		}
		const tabValueLabel = { 0: 'Connections', 1: 'Beats' }

		const handleChangeTab = (event, newValue) => {
			createLog(
				'List Element Tab Selected',
				`{"workflowStep":${1},"Tab":"${tabValueLabel[newValue]}"}`,
				'List Element',
				'Connection Creation',
			)
			setTabValue(newValue)
		}

		const handleClick = (event, connectionModalType = { type: 'Opposing' }) => {
			setConnectionModalType(connectionModalType)
			setConnectionModal(event.currentTarget)
			createLog(
				'Connection Creation Attempted',
				`{"workflowStep":${1},"connectionType":"Static", "connectionModalType":"${
					connectionModalType.type
				}"}`,
				'ListElement',
				'Connection Creation',
			)
		}
		const handleMissingDriverClick = (event, connectionModalType = { type: 'Opposing' }) => {
			setStructureTagConnnectionModal(event)
			setConnectionModalType(connectionModalType)
			createLog(
				'Connection Creation Attempted',
				`{"workflowStep":${1},"connectionType":"Static", "connectionModalType":"${
					connectionModalType.type
				}"}`,
				'ListElement',
				'Connection Creation',
			)
		}

		const onTooltipOpen = menuItem => {
			createLog(
				`Suggestion Tooltip hovered`,
				`{"Menu Item": "${menuItem}"}`,
				`Connection Modal`,
				'Connection Creation',
			)
		}

		return (
			<>
				<ElementContainer
					className="element-container"
					isSelected={deepModeElement?.id === id}
					isCollapsed={expandedElementId !== id}
					style={{ cursor: isOverlay ? 'grabbing' : 'unset' }}
				>
					<CollapsedElement
						isEdit={isEdit}
						type={type}
						isSelected={deepModeElement?.id === id}
						isCollapsed={expandedElementId !== id}
					>
						<Tooltip title="Expand" classes={{ tooltip: classes.tooltip1 }}>
							<ArrowIcon
								isEdit={isEdit}
								src={arrow}
								alt="Arrow"
								isCollapsed={expandedElementId !== id}
								onClick={async () => {
									if (expandedElementId) {
										setExpandedElementId('')
										if (type === 'Chapter') {
											
										} else {
											
										}
									} else {
										sharedDispatch(
											setScrollPosition(
												type === 'Chapter' ? 'chapterTab' : 'elementList',
												scrollRef.current.scrollTop,
											),
										)
										setExpandedElementId(id)
										if (type === 'Chapter') {
											
										} else {
											
										}
									}
									// if(expandedElementId === id){
									// 	dispatch(setActiveConnections(false))
									// }
									if (isEdit) {
										setEdit(false)
									}
									setCollapsed(!isCollapsed)
									createLog(
										`Element Details ${expandedElementId === id ? 'Collasped' : 'Expanded'}`,
										`{"workflowStep":${null},"elementType":"${type}"}`,
										'ListElement',
										null,
									)
									setNewNode(null)
								}}
							/>
						</Tooltip>
						<ElementWrapper
							onClick={() => {
								if (!expandedElementId) {
									setExpandedElementId(id)
								}
							}}
						>
							<Element>
								<CollapsedContainer
									isCollapsed={expandedElementId !== id}
									hoverStyle={deepModeElement?.id !== id}
									isEnabled={
										user?.subscriptionStatus?.editTextfield ||
										user?.subscriptionStatus?.editNumberfield
									}
									className="test"
								>
									{type === 'Chapter' && (
										<>
											<p>Ch:</p>
											<TextField
												id={id}
												size={'1.5rem'}
												type={'Chapter'}
												content={number}
												elementContent={elementNumber}
												setContent={setElementNumber}
												placeholder={'0'}
												isNumber={true}
												isCollapsed={false}
												createLog={createLog}
												setExpandedElementId={setExpandedElementId}
												expandedElementId={expandedElementId}
												isRightPanel={isRightPanel}
											/>
										</>
									)}
									{type && (
										<TextField
											id={id}
											size={'90%'}
											type={type}
											content={name}
											elementContent={elementName}
											setContent={setElementName}
											placeholder={`New ${type}`}
											isNumber={false}
											setExpandedElementId={setExpandedElementId}
											expandedElementId={expandedElementId}
											isRightPanel={isRightPanel}
										/>
									)}
								</CollapsedContainer>
							</Element>
							<IconsContainer>
								<DraggerContainer>
									{expandedElementId === id && (
										<Tooltip
											title={deepModeElement?.id === id ? 'Close' : 'Open'}
											placement="bottom"
										>
											<BottomSection
												isHover={isDocumentHovered}
												isDeepMode={deepModeElement?.id === id}
											>
												<div
													disabled={serverError || noElementError}
													onClick={e => {
														e.stopPropagation()
														if (deepModeElement?.id !== id) {
															sharedDispatch(setOpenToolTip(false))
															createLog(
																'Dive Deep Entered',
																`{"elementType":"${type}", "action":"Name Clicked"}`,
																'ListElement',
																null,
															)

															createLog(
																`Element Details ${
																	expandedElementId === id ? 'Collasped' : 'Expanded'
																}`,
																`{"workflowStep":${null},"elementType":"${type}"}`,
																'ListElement',
																null,
															)

															beatsDataDispatch(setDeepModeElement({ id, nodeType: type }))
														} else {
															beatsDataDispatch(setDeepModeElement(null))
														}
													}}
													onMouseEnter={() => setIsDocumentHovered(true)}
													onMouseLeave={() => setIsDocumentHovered(false)}
												>
													<img
														src={
															isDocumentHovered || deepModeElement?.id === id
																? documentIconYellow
																: documentIcon
														}
														alt="document icon"
													/>
													<p>Document</p>
												</div>
											</BottomSection>
										</Tooltip>
									)}
									<DeleteIcon
										disabled={serverError || noElementError}
										src={trashIcon}
										alt="Delete Button"
										onClick={e => {
											e.stopPropagation()
											setCurrentElement({ name, id, type })
											createLog(
												'Element Deletion Attempted',
												`{"workflowStep":${1},"elementType":"${type}", "isCollapsed":${
													expandedElementId !== id
												}}`,
												'ListElement',
												'Element Deletion',
											)
										}}
									/>
									{expandedElementId !== id && (
										<img
											src={dragger}
											alt="Dragger Icon"
											{...attributes}
											{...listeners}
											style={{ cursor: isOverlay ? 'grabbing' : 'grab' }}
											onClick={e => e.stopPropagation()}
										/>
									)}
								</DraggerContainer>
							</IconsContainer>
						</ElementWrapper>
					</CollapsedElement>
					{expandedElementId === id && (
						<UncollapsedElement
							isCollapsed={expandedElementId !== id}
							isSelected={deepModeElement?.id === id}
							type={type}
							className="uncollapsed-element"
							style={{ padding: '8px' }}
						>
							{type === 'Chapter' ? (
								<ChapterCard
									id={expandedElementId}
									name={name}
									description={description}
									type={type}
									number={number}
									createLog={createLog}
								/>
							) : (
								<>
									{type && (
										<DescriptionField
											id={id}
											fieldName={'description'}
											type={type}
											createLog={createLog}
											elementDescription={description}
											isArcConnection={false}
											isElement={true}
											placeholder={`Summarize your ${type} here`}
											isRightPanel={isRightPanel}
										/>
									)}
									<Box sx={{ width: '100%' }}>
										<TabsContainer id="listElementTabs">
											<Box sx={{ borderBottom: 1, borderColor: 'divider', width: '100%' }}>
												<Tabs
													value={tabValue}
													onChange={handleChangeTab}
													aria-label="basic tabs example"
													variant="fullWidth"
													classes={{ flexContainer: classes.tabs, indicator: classes.indicator }}
												>
													<Tab
														key={'relationships'}
														label={
															<TabContainer isActive={tabValue === 0}>
																<img src={connectionIconDark} alt="Connection icon dark" />
																<p>Relationships</p>
																<CountIcon>
																	<p>{`${connectionsArr?.all?.length || 0}`}</p>
																</CountIcon>
															</TabContainer>
														}
														{...a11yProps(0)}
														classes={{ root: classes.tab, selected: classes.tabSelected }}
													/>
													<Tab
														key={'beats'}
														label={
															<TabContainer isActive={tabValue === 1}>
																<img src={beatIconDark} alt="Beat icon dark" />
																<p>Beats</p>
																<CountIcon>
																	<p>{`${countRef?.current?.childCount?.Total}`}</p>
																</CountIcon>
															</TabContainer>
														}
														{...a11yProps(1)}
														classes={{ root: classes.tab, selected: classes.tabSelected }}
													/>
												</Tabs>
											</Box>
										</TabsContainer>
										<div
											role="tabpanel"
											hidden={tabValue !== 0}
											id={`simple-tabpanel-${0}`}
											key={'relationships'}
											aria-labelledby={`simple-tab-${0}`}
											style={{ position: 'relative', marginTop: '30px' }}
										>
											{/* <RelationshipHeaderContainer>
												<RelationshipLabelContainer>
													<img src={relationshipIcon} />
													<RelationshipLabel>Relationships</RelationshipLabel>
												</RelationshipLabelContainer>
												<BeatsCountContainer>
													<TotalBeatCount>
														<p>{`${connectionsArr?.all?.length || 0}`}</p>
														<img src={relationshipIcon} />
													</TotalBeatCount>
													<ExtraBeatsContainer>
														<p>{missingPremiseConnections?.length || 0}</p>
														<img src={missingPremiseDriverIcon} />
													</ExtraBeatsContainer>
												</BeatsCountContainer>
											</RelationshipHeaderContainer> */}

											{driverTypeList.map(driver => {
												const filteredMissingDriversList = missingPremiseConnections.filter(
													driverData => driverData.__typename === driver,
												)
												const filteredDriversList = connectionsArr?.all
													?.filter(
														driverData =>
															driverData.__typename === driver && driverData?.structureTag,
													)
													.sort((a, b) => (a?.createdAt ? sortNodesByCreatedAt(a, b) : -1))
												const missingStructureTagDriversList = missingStructureTagConnection
													?.filter(connection => connection.__typename === driver)
													.sort((a, b) => (a?.createdAt ? sortNodesByCreatedAt(a, b) : -1))
												return (
													<ElementCardContainer key={driver}>
														<ElementCard key={driver}>
															{!filteredDriversList?.length &&
																!filteredMissingDriversList?.length &&
																!missingStructureTagDriversList?.length && (
																	<MissingKeyRelationShip
																		driverType={driver}
																		driverName={graphData?.nodes[id]?.name}
																	/>
																)}
															<CreateRelation
																onMouseEnter={() =>
																	createLog(
																		'Create Relationship Button Hover',
																		`{"driverType":"${driver}", "relationshipGroupDriverType": "${driver}"}`,
																		'List Element',
																		null,
																	)
																}
																onClick={e => {
																	handleClick(e, { type: 'Supporting' })
																	setCurrentDriverType(driver)
																}}
																style={{
																	marginBottom:
																		!filteredDriversList?.length &&
																		!filteredMissingDriversList?.length &&
																		!missingStructureTagDriversList?.length
																			? 0
																			: `10px`,
																}}
															>
																<img src={whitePlusIconYellowBackground} />
																<CreateRelationLable>
																	Connect a {displayNewElement(driver)}
																</CreateRelationLable>
																<ElementLable>
																	<img
																		src={getNodeIcon(`Driver-${driver}`)}
																		width={driver === 'Character' ? '12px' : '20px'}
																	/>
																	<ElementLableText>{`${displayNewElement(
																		driver,
																	)}s`}</ElementLableText>
																</ElementLable>
															</CreateRelation>
															<Card>
																{expandedElementId === id &&
																	missingStructureTagDriversList?.length > 0 &&
																	missingStructureTagDriversList.map((node, index) => {
																		return (
																			<NewIndividualConnectionWrapper
																				key={node.id}
																				firstCard={
																					missingStructureTagDriversList?.length === 0 &&
																					index === 0
																				}
																				prevConnCard={missingStructureTagDriversList?.length > 0}
																				type={node.__typename}
																				isMissingDriver={true}
																				beatsDriven={0}
																				isCollapsed={true}
																			>
																				<MissingStructureTagDriver
																					key={node.id}
																					id={node.id}
																					type={node.__typename}
																					text={node.name}
																					handleClick={handleMissingDriverClick}
																					connectionsArr={connectionsArr}
																					connectionModalType={{
																						type: node.__typename,
																						recommendedDriverId: node.id,
																					}}
																					setCurrentDriverType={setCurrentDriverType}
																					referenceElement={graphData?.nodes[id]}
																				/>
																			</NewIndividualConnectionWrapper>
																		)
																	})}
																{expandedElementId === id &&
																	filteredMissingDriversList?.length > 0 &&
																	filteredMissingDriversList.map((node, index) => {
																		return (
																			<NewIndividualConnectionWrapper
																				key={node.id}
																				firstCard={
																					filteredMissingDriversList?.length === 0 && index === 0
																				}
																				prevConnCard={filteredMissingDriversList?.length > 0}
																				type={node.__typename}
																				isMissingDriver={true}
																				beatsDriven={0}
																				isCollapsed={true}
																			>
																				<MissingPremiseDriver
																					key={node.id}
																					id={node.id}
																					text={node.name}
																					handleClick={handleMissingDriverClick}
																					connectionModalType={{
																						type: node.__typename,
																						recommendedDriverId: node.id,
																						isRecommendedDriver: true,
																					}}
																					referenceElement={graphData?.nodes[id]}
																				/>
																			</NewIndividualConnectionWrapper>
																		)
																	})}
																{expandedElementId === id && filteredDriversList?.length > 0 && (
																	<ConnectionWrapper className="connectionList">
																		{/* <Line /> */}
																		{filteredDriversList?.map((element, index) => {
																			return (
																				<NewIndividualConnectionWrapper
																					key={element?.id}
																					firstCard={index === 0}
																					prevConnCard={false}
																					type={element?.__typename}
																					isMissingDriver={false}
																					beatsDriven={element?.beatsDriven}
																				>
																					<NewConnectionCard
																						key={element?.id}
																						isDefaultOpened={connectionsArr?.all?.length < 5}
																						id={element?.id}
																						name={element?.name}
																						type={element?.__typename}
																						structureTag={element?.structureTag}
																						beatsDriven={element?.beatsDriven}
																						sourceNode={element?.sourceNode}
																						description={element?.description}
																						firstBeat={element?.firstBeat}
																						disableHoverListener={true}
																						createLog={createLog}
																						setCurrentElement={() => {}}
																						sharedBeats={element?.sharedBeats}
																						referenceElement={graphData?.nodes[id]}
																						destNode={
																							element?.sourceNode.id === id
																								? graphData?.nodes[element?.destNode.id]
																								: graphData?.nodes[element?.sourceNode.id]
																						}
																						isNewStructureTag={isNewStructureTag}
																						tabValue={tabValue}
																						setOpenedConnectionCard={setOpenedConnectionCard}
																						openedConnectionCard={openedConnectionCard}
																					></NewConnectionCard>
																				</NewIndividualConnectionWrapper>
																			)
																		})}
																	</ConnectionWrapper>
																)}
															</Card>
														</ElementCard>
													</ElementCardContainer>
												)
											})}
										</div>
										{/* <div>
											<CreateConnection
												isLength={connectionsArr?.other?.length}
												noCards={
													connectionsArr?.other?.length === 0 &&
													missingPremiseConnections?.length === 0 &&
													connectionsArr?.premise?.length === 0
												}
											>
												<CreateConnectionBtn
													onClick={e => {
														handleClick(e, { type: 'Supporting' })
													}}
													isSupporting={true}
												>
													<img
														className="icon"
														src={supportingIconWithoutOutline}
														alt="supporting-connection"
													/>
													Add a Supporting Driver
													<SuggestionTooltip
														title="A Supporting Driver captures harmony or complementary ideas."
														placement="bottom"
														arrow
														onOpen={() => onTooltipOpen('Supporting')}
													>
														<img className="tool-tip" src={tooltipIcon} alt="Tool-tip" />
													</SuggestionTooltip>
												</CreateConnectionBtn>

												<CreateConnectionBtn
													onClick={e => {
														handleClick(e, { type: 'Opposing' })
													}}
												>
													<img
														className="icon"
														src={opposingIconWithoutOutline}
														alt="oppsing-connection"
													/>
													Add an Opposing Driver
													<SuggestionTooltip
														title="An Opposing Driver captures opposing ideas that lead to direct conflict, whether mental, verbal, or physical."
														placement="bottom"
														arrow
														onOpen={() => onTooltipOpen('Opposing')}
													>
														<img className="tool-tip" src={tooltipIcon} alt="Tool-tip" />
													</SuggestionTooltip>
												</CreateConnectionBtn>
											</CreateConnection>
										</div> */}
										<div
											role="tabpanel"
											hidden={tabValue !== 1}
											id={`simple-tabpanel-${1}`}
											key={'beats'}
											aria-labelledby={`simple-tab-${1}`}
										>
											{type !== 'Chapter' ? (
												<BeatsContainer
												referenceElement={graphData?.nodes[id]}
												location={"List Element"}
												locationId={id}
												beatsList={beatsList}
												connectionTypeField={"driverConnections"}
											/>
											) : (
												expandedElementId?.id === id &&
												deepModeElement &&
												type === 'Chapter' && (
													<DeepModeChapterContainer id={id} setCurrentElement={setCurrentElement} />
												)
											)}
										</div>
									</Box>

									{/* {['Character', 'Arc', 'Event', 'Theme'].includes(type) && (
													<BeatsTitle>
														<p className="title">{type} Development</p>
														<SuggestionTooltip
															title="A Beat is an interaction that advances a Story Element."
															placement="right"
															arrow
														>
															<img src={helpIcon} alt=" Icon" style={{ width: '10px', height: '10px' }} />
														</SuggestionTooltip>
														<PillComponent
															type="beat"
															beatCount={countRef?.current?.childCount}
															isLeftPanel={true}
															className="beats-heading"
														/>
													</BeatsTitle>
												)}*/}
								</>
							)}
						</UncollapsedElement>
					)}
				</ElementContainer>
				{(connectionModal || structureTagConnnectionModal) && connectionModalType && (
					<CreateConnectionModal
						mainElementId={id || activeConnections.elementId}
						mainElementType={id ? graphData?.nodes[id].__typename : activeConnections?.elementType}
						isOpen={connectionModal}
						isOpenStructureTagModal={structureTagConnnectionModal}
						setStructureTagConnnectionModal={setStructureTagConnnectionModal}
						suggetionHandleClick={() => {}}
						setConnectionModalType={setConnectionModalType}
						setConnectionModal={setConnectionModal}
						connectionsArr={connectionsArr}
						connectionModalType={connectionModalType}
						setIsAutoSelect={setIsAutoSelect}
						isAutoSelect={isAutoSelect}
						handleClose={() => {
							setStructureTagConnnectionModal(false)
							setConnectionModal(false)
							setConnectionModalType({ type: 'Supporting' })
							setIsAutoSelect(false)
						}}
						setIsNewStructureTag={setIsNewStructureTag}
						currentDriverType={currentDriverType}
					/>
				)}
				{currentElement?.id && (
					<DeleteModal
						elementType={currentElement?.type?.toLowerCase()}
						nodeName={`${currentElement?.name}`}
						isShowed={currentElement}
						details=" and its beats that don't have another driver will be removed too"
						isConnection={currentElement?.type === 'Beat' || currentElement?.type === 'Connection'}
						acceptHandler={async () => {
							if (currentElement.type === 'Connection') {
								removeConnection(currentElement)
								createLog(
									'Connection Deletion Confirmed',
									`{"workflowStep":${2},"connectionType":"Static"}`,
									'ElementList',
									'Connection Deletion',
								)
							} else {
								if (currentElement?.type !== 'Beat') {
									setExpandedElementId('')
								}

								await removeElement(currentElement?.id, currentElement?.type)
								createLog(
									`Element Deletion Confirmed`,
									`{"workflowStep":${2},"elementType":"${currentElement?.type}"}`,
									'ElementList',
									'Element Deletion',
								)
							}
							// const connectionCount = getConnectionCount(client)
							// sharedDispatch(
							// 	setConnectionCount({
							// 		supportingCount: connectionCount.Supporting,
							// 		opposingCount: connectionCount.Opposing,
							// 		undefinedCount: connectionCount.Undefined,
							// 		total: connectionCount.Total,
							// 	}),
							// )
							setCurrentElement()
						}}
						closeHandler={() => {
							if (currentElement.type === 'Connection') {
								createLog(
									'Connection Deletion Cancelled',
									`{"workflowStep":${2},"connectionType":"Static"}`,
									'ElementList',
									'Connection Deletion',
								)
							} else {
								createLog(
									'Element Deletion Cancelled',
									`{"workflowStep":${2},"elementType":"${currentElement?.type}"}`,
									'ElementList',
									'Element Deletion',
								)
							}
							setCurrentElement()
						}}
					/>
				)}
			</>
		)
	},
)

const Draggable = props => {
	const { data: relationships } = useGetRelationshipsQuery()
	const graphData = useContext(graphDataStateContext)
	const { currentElement, reorderedPosition } = useContext(beatsDataStateContext)
	const beatsDataDispatch = useContext(beatsDataDispatchContext)
	const { isUserSubscribed } = useContext(systemStateContext)

	const [driverConnections, setDriverConnections] = useState([])

	const {
		setNodeRef,
		attributes,
		listeners,
		isDragging,
		transition,
		transform,
		active,
		over,
		isSorting,
	} = useSortable({
		id: props.id,
		data: {
			type: props.type === 'Chapter' ? 'chapter' : 'element',
			id: props.id,
		},
	})

	const hoveredElement =
		!!currentElement &&
		!currentElement?.startsWith('chp') &&
		over?.id?.startsWith('chp') &&
		active?.id?.startsWith('rel') &&
		over?.id === props.id &&
		props.expandedElementId !== props.id

	const hoveredTimeout = useRef()

	useEffect(() => {
		clearTimeout(hoveredTimeout.current)
		if (hoveredElement) {
			hoveredTimeout.current = setTimeout(() => {
				props.setExpandedElementId(over?.id)
			}, 1000)
		}
	}, [over])

	useEffect(() => {
		const connections = graphData?.nodes[props.id]?.driverConnections?.map(element => {
			return { ...element, order: +element.order }
		})
		const orderedConnections = orderBy(connections, ['order'], ['asc'])

		setDriverConnections(orderedConnections)
	}, [graphData?.nodes[props.id]])


	// update position of beats after reorder until get response from an API
	useEffect(() => {
		if (
			reorderedPosition?.referenceElement?.id === props.id &&
			reorderedPosition?.source?.index !== reorderedPosition?.destination?.index
		) {
			setDriverConnections(
				arrayMove(
					driverConnections,
					reorderedPosition.source.index,
					reorderedPosition.destination.index,
				),
			)
			beatsDataDispatch(elementReordered())
		}
	}, [reorderedPosition])

	return (
		<>
			<div
				ref={setNodeRef}
				style={{
					transition,
					transform: hoveredElement ? 'translateY(-2px)' : CSS.Translate.toString(transform),
					opacity: isDragging ? '0.5' : 'unset',
					height: props.expandedElementId === props.id ? '100%' : 'unset',
					borderRadius: '4px',
					boxShadow: hoveredElement ? '3px 3px 10px grey' : 'unset',
				}}
			>
				<ListElement
					{...props}
					attributes={attributes}
					listeners={listeners}
					driverConnections={driverConnections}
				/>
			</div>
			{isUserSubscribed?.isActiveUser &&
				props.type === 'Chapter' &&
				props.expandedElementId === props.id && (
					<SubscriptionFooter
						isActive={isUserSubscribed?.isActiveUser}
						text={'Upgrade to Pro to add more Beats to Chapters'}
						target="chapter-list-container"
					/>
				)}
		</>
	)
}

export default React.memo(Draggable)

const DeepModeChapterContainer = ({ id, setCurrentElement }) => {
	const { active, over } = useDndContext()

	const graphData = useContext(graphDataStateContext)

	const connections = graphData.nodes[id]?.beatConnections.map(connection => {
		return { ...connection, order: +connection.order }
	})
	const orderedConnections = orderBy(connections, ['order'], ['asc'])

	const beatConnections = useDraggable(id, active, over, orderedConnections,"driverConnections")

	return (
		<ChapterBeatsContainer
			id={id}
			type={'Chapter'}
			beatConnections={beatConnections ?? []}
			setCurrentNode={setCurrentElement}
		/>
	)
}
