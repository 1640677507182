/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable prettier/prettier */
/* eslint-disable array-callback-return */
import React, { memo, useContext, useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import isEqual from 'lodash/isEqual'
import Split from 'react-split'
import { useDroppable } from '@dnd-kit/core'
import { graphDataStateContext } from '@lynit/shared/src/state/graphDataProvider'
import {
	expandTutorialSet,
	openTutorial,
	setCurrentElement,
	setDeepModeElement,
	setLeftToggleTabs,
	setRightToggleTabs,
	setSummaryCardVisible,
	setSummaryView,
	setChapterViewExpanded,
	setVisualizationExpanded,
} from '@lynit/shared/src/state/actions'
import { sharedContext } from '@lynit/shared/src/state/sharedProvider'
import {
	beatsDataDispatchContext,
	beatsDataStateContext,
} from '@lynit/shared/src/state/beatsProvider'
import { systemDispatchContext, systemStateContext } from '@lynit/shared/src/state/systemProvider'
import DragHelperModal from '@lynit/shared/src/ui/DragHelperModal'
import ChapterView from '@lynit/story-outline/src/ui/ChapterView'
import Summary from '@lynit/story-outline/src/ui/Summary'
import EmptyState from '@lynit/story-outline/src/ui/EmptyState'
import Viz from '@lynit/story-outline/src/ui/viz'
import ArcViz from '@lynit/story-outline/src/ui/arcViz'
import VisualizationMenu from './VisualizationMenu'
import chapIcon from '../../images/chapter-icon.svg'
import { VizContainer, ViewContainer, MiddlePanel, Bar } from './styles'
import SearchCatelog from '@lynit/shared/src/ui/Search/SearchCatelog'

const equivalentElements = (prevProps, nextProps) => {
	return (
		isEqual(prevProps.state, nextProps.state) &&
		isEqual(prevProps.deletedNode, nextProps.deletedNode) &&
		prevProps.linkNodes === nextProps.linkNodes &&
		prevProps.deletedNode === nextProps.deletedNode &&
		prevProps.response === nextProps.response
	)
}

const Visualization = memo(
	({
		leftToggleTabs,
		rightToggleTabs,
		state,
		graphData,
		dispatch,
		linkNodes,
		createLog,
		story,
		deletedNode,
		currentElement,
		setCurrentView,
	}) => {
		const location = useLocation()
		const {
			state: { isChapterViewExpanded },
			dispatch: dispatchAction,
		} = useContext(sharedContext)

		const beatsDataDispatch = useContext(beatsDataDispatchContext)
		const systemDataDispatch = useContext(systemDispatchContext)

		const { deepModeElement } = useContext(beatsDataStateContext)

		const [connectionType, setConnectionType] = useState('')
		
		useEffect(() => {
			
			if (location.state?.isTutorialStory) {
				dispatchAction(setSummaryView(true))
			}
			if (location.state?.tutorialId) {
				dispatchAction(openTutorial(location.state.tutorialId))
			}

			// expand tutorial set if creating a template story
			if (location.state?.tutorialSetId) {
				dispatchAction(setSummaryView(true))
				dispatchAction(expandTutorialSet(location.state.tutorialSetId))
			}
		}, [])

		// useEffect(() => {
		// 	systemDataDispatch(setSummaryCardVisible(deepModeElement?.id ? true : false))
		// }, [deepModeElement])

		return (
			<ViewContainer
				isVisible={!(leftToggleTabs || rightToggleTabs)}
				isChapterViewExpanded={isChapterViewExpanded}
				isExpandingView={state?.isExpandingView}
				leftToggleTabs={leftToggleTabs}
				rightToggleTabs={rightToggleTabs}
				id="view-content"
			>
				{/* <VisualizationMenu
					currentView={state?.currentView}
					setCurrentView={setCurrentView}
					setConnectionType={setConnectionType}
				/> */}

				{/* story visualization */}
				{state?.currentView === 'visualization' && (
					<VizContainer isDeepMode={!!currentElement} className="three">
						{/* {!graphData?.loading &&
						Object.values(graphData?.nodes)?.filter(
							e => e.__typename !== 'Chapter' && e.__typename !== 'Note',
						)?.length === 0 ? (
							<EmptyState name={'Story visualization'} />
						) :  */}
						<Viz
							isEmpty={!!graphData}
							graphData={graphData}
							createLog={createLog}
							filteredConnections={[]}
							isDeepMode={!!currentElement}
							linkNodes={linkNodes}
							deletedNode={deletedNode}
							connectionType={connectionType}
							where="main dashboard"
						/>
						{/* } */}
					</VizContainer>
				)}

				{/* timeline */}
				{state?.currentView === 'timeline' && (
					<VizContainer className="timeline-container">
						{graphData?.loading === false &&
						Object.values(graphData?.nodes)?.filter(
							e =>
								e.__typename !== 'Chapter' &&
								e.__typename !== 'Note' &&
								e.__typename !== 'Event' &&
								e.__typename !== 'Theme',
						)?.length === 0 ? (
							<EmptyState name={'Timeline'} isArcViz />
						) : (
							<ArcViz
								story={story}
								graphData={graphData}
								createLog={createLog}
								driverConnToDelete={state?.driverConnToDelete}
								setDriverConnToDelete={val => dispatch({ key: 'driverConnToDelete', value: val })}
								isDriver={state?.isDriver}
								setIsDriver={val => dispatch({ key: 'isDriver', value: val })}
							/>
						)}
					</VizContainer>
				)}

				{state?.currentView === 'summary' && <Summary />}
			</ViewContainer>
		)
	},
)

const VizDroppable = memo(props => {
	const { isOver, setNodeRef, active } = useDroppable({
		id: 'view-container',
		data: {
			id: 'view-container',
		}
	})
	const [activeType, setActiveType] = useState('')
	const [description, setDescription] = useState('')

	useEffect(() => {
		if (active?.data?.current?.type) {
			setActiveType(active.data.current.type)
		} else {
			setActiveType('')
		}
	}, [active])

	useEffect(() => {
		if (activeType === 'beat') {
			setDescription('Drag Beats into Chapter Planner')
		} else if (activeType === 'chapter') {
			setDescription('Reorder Chapter in Chapter Planner')
		} else {
			setDescription('')
		}
	}, [activeType])

	return (
		<div ref={setNodeRef} id="view-container" style={{ height: '100%' }}>
			<DragHelperModal
				description={description}
				target="view-container"
				isOpen={
					isOver &&
					(activeType === 'beat' || activeType === 'chapter') &&
					!props.state.isChapterViewExpanded
				}
			/>
			<Visualization {...props} />
		</div>
	)
})

const StoryOutline = ({ state, dispatch, linkNodes, deletedNode, response }) => {
	const {
		state: {
			isSummaryView,
			recommendationId,
			story,
			tutorialId,
			leftToggleTabs,
			rightToggleTabs,
			activeElement,
			isChapterViewExpanded,
			isVisualizationExpanded,
			currentStory,
			isCatelogOpen,
		},
		dispatch: dispatchAction,
	} = useContext(sharedContext)
	const beatsDataDispatch = useContext(beatsDataDispatchContext)
	const { currentElement } = useContext(beatsDataStateContext)
	const { isMobile, createLog } = useContext(systemStateContext)
	const graphData = useContext(graphDataStateContext)

	const [isNoBeats, setIsNoBeats] = useState(false)
	const [storyOutlineLoaded, setStoryOutlineLoaded] = useState(false)

	useEffect(() => {
		setTimeout(() => {
			setStoryOutlineLoaded(true)
		}, 4000)
	}, [])

	// if user clicks on tutorial link then collapse other elements and go to the summary view
	useEffect(() => {
		if (tutorialId || recommendationId) {
			setCurrentView('summary')
			setTimeout(expandVisualization)
		}
	}, [tutorialId, recommendationId])

	useEffect(() => {
		if (currentStory?.lastDocView === 'Writing') {
			setCurrentView('timeline')
		} else {
			setCurrentView('visualization')
		}
	}, [currentStory?.lastDocView])

	useEffect(() => {
		const totalBeats = graphData?.nodesByType?.Beat?.length
		if (isNoBeats && totalBeats === 1) {
			resetViews('isNoBeats', isNoBeats)
		}

		if (totalBeats === 0 && graphData.loading === false) {
			setIsNoBeats(true)
		} else if (isNoBeats) {
			setIsNoBeats(false)
		}
	}, [graphData?.nodesByType?.Beat?.length])

	useEffect(() => {
		beatsDataDispatch(setCurrentElement(null))
		dispatchAction(setRightToggleTabs(false))

		beatsDataDispatch(setDeepModeElement(null))
		dispatchAction(setLeftToggleTabs(!isMobile))
		if (!(isChapterViewExpanded && isVisualizationExpanded)) {
			expandVisualization()
		}
	}, [isMobile])

	const resetViews = () => {
		const visualization = document.getElementById('visualization')
		const chapterViewContainer = document.getElementById('chapter-view-container')
		chapterViewContainer.style.height = 'calc(60% - 5px)'
		visualization.style.height = 'calc(40% - 5px)'
		dispatchAction(setVisualizationExpanded(false))
		dispatchAction(setChapterViewExpanded(false))
	}

	const expandChapterPlanner = () => {
		const visualization = document.getElementById('visualization')
		const chapterViewContainer = document.getElementById('chapter-view-container')
		visualization.style.height = '30px'
		chapterViewContainer.style.height = 'calc(100% - 40px)'
		dispatchAction(setVisualizationExpanded(false))
		dispatchAction(setChapterViewExpanded(true))
	}

	const expandVisualization = () => {
		const visualization = document.getElementById('visualization')
		const chapterViewContainer = document.getElementById('chapter-view-container')

		if (visualization && chapterViewContainer) {
			chapterViewContainer.style.height = '30px'
			visualization.style.height = 'calc(100% - 40px)'
			dispatchAction(setVisualizationExpanded(true))
			dispatchAction(setChapterViewExpanded(false))
		}
	}

	useEffect(() => {
		if (isSummaryView) {
			dispatchAction(setSummaryView(false))
		}
	}, [isSummaryView])

	useEffect(() => {
		if (!!activeElement.id && activeElement.type === 'Chapter' && isVisualizationExpanded) {
			resetViews()
		}
	}, [activeElement])

	const setCurrentView = view => {
		const possibleViews = ['summary', 'visualization', 'timeline']
		if (possibleViews.includes(view) && view !== state?.currentView) {
			dispatch({ key: 'currentView', value: view })
		}
	}

	const getElementStyle = (dimension, elementSize, gutterSize, index) => {
		if (elementSize !== 50) {
			if (index === 0) {
				document
					.getElementById('viz-bar')
					?.setAttribute(
						'style',
						`opacity: ${
							1 - elementSize / 50
						}; position: absolute; top:0; width: 100%; box-sizing: border-box; z-index: 1;`,
					)
				document
					.getElementById('view-content')
					?.setAttribute('style', `opacity: ${elementSize / 50};`)
			} else {
				document
					.getElementById('chp-bar')
					?.setAttribute(
						'style',
						`opacity: ${
							1 - elementSize / 50
						}; position: absolute; top:0; width: 100%; box-sizing: border-box; z-index: 1;`,
					)
				document
					.getElementById('chapter-view')
					?.setAttribute('style', `opacity: ${elementSize / 50};`)
			}
		}
		return {
			height: `100%`,
			position: 'relative',
		}
	}

	const expandViewHandler = ([visualizationHeight]) => {
		const chapterView = document.getElementById('chapter-view')
		const viewContent = document.getElementById('view-content')

		if (visualizationHeight < 40) {
			expandChapterPlanner()
		} else if (visualizationHeight < 60) {
			resetViews()
		} else {
			expandVisualization()
		}
		document.getElementById('viz-bar').style.opacity = 1
		document.getElementById('chp-bar').style.opacity = 1
		chapterView.style.opacity = '1'
		viewContent.style.opacity = '1'
		dispatch({ key: 'isExpandingView', value: false })
	}


	// return useMemo(() => {
		return (
			<MiddlePanel
				gridColumn="2/3"
				gridRow="1/3"
				gridMobColumn="2/3"
				gridMobRow="1/3"
				isDeepMode={!!currentElement}
				leftToggleTabs={leftToggleTabs}
				rightToggleTabs={rightToggleTabs}
			>
				{isCatelogOpen ? (
					<SearchCatelog />
				) : (

					<div id="visualization" style={{ height: '100%' }}>
								<VizDroppable
									leftToggleTabs={leftToggleTabs}
									rightToggleTabs={rightToggleTabs}
									state={state}
									graphData={graphData}
									dispatch={dispatch}
									linkNodes={linkNodes}
									createLog={createLog}
									story={story}
									deletedNode={deletedNode}
									currentElement={currentElement}
									setCurrentView={setCurrentView}
								/>
								{/* {(state?.isExpandingView || isChapterViewExpanded) && (
								<Bar id="viz-bar" onClick={expandVisualization}>
									<p>
										<span className={state?.currentView === 'summary' ? 'active' : ''}>
											Recommendations
										</span>{' '}
										{' | '}
										<span className={state?.currentView === 'visualization' ? 'active' : ''}>
											Visualization
										</span>{' '}
										{' | '}
										<span className={state?.currentView === 'timeline' ? 'active' : ''}>
											Timeline
										</span>
									</p>
								</Bar>
							)} */}
							</div>

						// <Split
						// 	style={{ height: '100%' }}
						// 	direction="vertical"
						// 	sizes={isChapterViewExpanded ? [4, 96] : isVisualizationExpanded ? [96, 4] : [40, 60]}
						// 	cursor="row-resize"
						// 	minSize={30}
						// 	gutterSize={5}
						// 	elementStyle={getElementStyle}
						// 	onDragEnd={expandViewHandler}
						// 	onDragStart={() => {
						// 		dispatch({ key: 'isExpandingView', value: true })
						// 		createLog('Divider Drag Start', `{}`, 'Middle Panel', null)
						// 	}}
						// >
							
						// 	{/* <div id="chapter-view-container" style={{ position: 'relative' }}>
						// 	<ChapterView
						// 		createLog={createLog}
						// 		isVisualizationExpanded={isVisualizationExpanded}
						// 		isExpandingView={state?.isExpandingView}
						// 		response={response}
						// 		storyOutlineLoaded={storyOutlineLoaded}
						// 	/>
						// 	{(state?.isExpandingView || isVisualizationExpanded) && (
						// 		<Bar id="chp-bar" onClick={expandChapterPlanner}>
						// 			<div className="chapter-icon-container">
						// 				<img src={chapIcon} alt="chapter icon" />
						// 			</div>
						// 			<span className="active">Chapter Planner</span>
						// 		</Bar>
						// 	)}
						// </div> */}
						// </Split>
				)}
			</MiddlePanel>
		)
	// }, [
	// 	leftToggleTabs,
	// 	rightToggleTabs,
	// 	isCatelogOpen,
	// 	isChapterViewExpanded,
	// 	isVisualizationExpanded,
	// ])
}

export default memo(StoryOutline, equivalentElements)
