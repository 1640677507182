import React, { useContext, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useApolloClient, useMutation } from '@apollo/client'
import {
	deleteCacheConnections,
	deleteCacheElement,
	updateCacheField,
	updateCacheOrderBeat,
} from '@lynit/shared/src/utils/apollo'
import whitePlusIconYellowBackground from '@lynit/shared/src/images/whitePlusIconYellowBackground.svg'
import {
	createNodeId,
	getNodeIcon,
	structureTagMatcher,
	vizTitleLengthFormat,
} from '@lynit/shared/src/utils/utils'
import supportingConnectionIcon from '@lynit/shared/src/images/supporting-connection-structure-tag.svg'
import opposingConnectionIcon from '@lynit/shared/src/images/opposing-connection-structure-tag.svg'
import deleteIcon from '@lynit/shared/src/images/deleteIcon.svg'
import {
	BeatsCountLabel,
	BeatsTextWrapper,
	Connection,
	ConnectionCardContainer,
	ConnectionDescription,
	ConnectionImage,
	ElementCardContainer,
	ElementName,
	ElementNameLable,
	IconContainer,
	NewConnectionWrapper,
	StructureTagLable,
	TooltipDescription,
	TooltipWrapper,
} from './styles'
import {
	useCreateBeatMutation,
	useCreateConnectionMutation,
	useUpdateRelationshipMutation,
	useCreateSharedBeatMutation,
	useDeleteConnectionMutation,
	useGetRelationshipsQuery,
	useReorderBeatsMutation,
	useDraggable,
} from '@lynit/shared/src/hooks'
import { SortableContext, verticalListSortingStrategy, arrayMove } from '@dnd-kit/sortable'
import { graphDataStateContext } from '@lynit/shared/src/state/graphDataProvider'
import { makeStyles } from '@mui/styles'
import { nodeColors } from '@lynit/shared/src/utils/commonStyles'
import useQuillAutoSave from '@lynit/shared/src/ui/AutoSave/useQuillAutoSave'

import DescriptionField from '@lynit/shared/src/ui/DescriptionField'
import { beatsDataStateContext } from '@lynit/shared/src/state/beatsProvider'
import { systemStateContext } from '@lynit/shared/src/state/systemProvider'
import SuggestionTooltip from '@lynit/shared/src/ui/ToolTip/SuggestionTooltip'
import tooltipIcon from '@lynit/shared/src/images/tool-tip.svg'
import { sharedContext } from '@lynit/shared/src/state'
import {
	setActiveFilter,
	setIsCatelogOpen,
	setNewConnId,
	setActiveConnections,
} from '@lynit/shared/src/state/actions'

import textIcon from '@lynit/shared/src/images/text-icon.svg'
import activeTextIcon from '@lynit/shared/src/images/active-text-icon.svg'
import beatsIcon from '@lynit/shared/src/images/beats-icon.svg'
import activeBeatsIcon from '@lynit/shared/src/images/active-beats-icon.svg'
import HtmlTooltip from '@lynit/shared/src/ui/ToolTip/HtmlTooltip'
import DeleteModal from '@lynit/shared/src/shared-ui/DeleteModal'
import Arrow from '../../../../shared-package/src/images/arrow.svg'
import { orderBy } from 'lodash'
import BeatsContainer from '@lynit/shared/src/ui/BeatsContainer'
// import arrow from '@lynit/shared/src/images/downCheveron.svg'

const useStyles = makeStyles(() => ({
	tooltip: {
		margin: 0,
		background: '#55534F',
		display: props => props.isTooltip && 'none',
	},
	tooltip1: {
		display: props => !props.isCollapsedBeat && 'none',
		background: '#55534F',
	},
	tooltipSm: {
		margin: '0 auto',
		background: 'transparent',
		width: '100%',
		padding: 0,
		marginTop: '6px !important',
	},
	initialsTooltip: {
		background: '#55534F',
	},
	arrow: {
		color: '#55534F',
	},
	timeline: {
		margin: '0',
		padding: '7px 5px',
	},
	timelineItem: {
		'&:before': {
			display: 'none',
		},
		minHeight: 'unset',
		boxSizing: 'border-box',
		gap: '0px',
		alignItems: 'center',
		justifyContent: 'center',
	},
	timelineContent: {
		padding: '0px 3px',
		width: 'calc(100% - 20px)',
	},
	timelineDot: {
		margin: 0,
		backgroundColor: '#78C091',
	},
	timelineChapter: {
		margin: 0,
		backgroundColor: nodeColors.Chapter,
		width: '8px',
		height: '8px',
		padding: '0',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: '10px',
		color: '#000000',
	},
	hiddenTimelineConnector: {
		visibility: 'hidden',
	},
	timelineConnector: {
		background: '#78C091',
	},
	dashedTimelineConnector: {
		background: 'transparent',
		border: '1px dashed #78C091',
		width: 0,
	},
	tab: {
		color: '#888585',
		fontFamily: 'Source Sans Pro',
		fontSize: '14px',
		fontStyle: 'normal',
		fontWeight: 700,
		lineHeight: 'normal',
		textTransform: 'none',
		minWidth: '70px',
		padding: '12px 5px',
		// alignItems: 'center',
		// justifyContent:"center",
	},
	tabSelected: {
		color: '#000000 !important',
	},
	indicator: {
		height: '6px',
		borderRadius: '10px',
		backgroundColor: '#FFC300',
	},
	tabs: {
		borderBottom: '6px solid #fff5d4',
		borderRadius: '4px',
	},
}))

const NewConnectionCard = ({
	id,
	name,
	text,
	type,
	structureTag,
	handleClick,
	beatsDriven,
	sharedBeats,
	setIsSetModifiers,
	referenceElement,
	destNode,
	description,
	firstBeat,
	tabValue = 0,
	isDefaultOpened,
	render,
	parentLocation="",
	handleClose = () => {},
	isNewStructureTag,
	isAddingExistingBeatVisible = true,
}) => {
	const client = useApolloClient()
	const [performUpdateRelationshipMutation] = useUpdateRelationshipMutation()
	const [openedConnectionCard, setOpenedConnectionCard] = useState(false)

	const graphData = useContext(graphDataStateContext)
	const [newBeat, setNewBeat] = useState(null)
	//const [createBeat] = useCreateBeatMutation({ ignoreResults: true })
	const [performCreateSharedBeat] = useCreateSharedBeatMutation({ ignoreResults: true })
	const [beatContentChanged, setBeatContentChanged] = useState(!!firstBeat)
	const [createConnection] = useCreateConnectionMutation({ ignoreResults: true })
	const beatsRelatedData = useContext(beatsDataStateContext)
	const { createLog, toastHandler } = useContext(systemStateContext)
	const [isEdit, setEdit] = useState(false)
	//const [connectionDescription, setConnectionDescription] = useState(description)
	const [collaspedCardHovered, setCollapsedCardHovered] = useState(false)
	const [performDeleteConnectionMutation] = useDeleteConnectionMutation()
	const [reorderBeats] = useReorderBeatsMutation()
	const { data: relationships } = useGetRelationshipsQuery()
	const {
		state: {
			deleteNode,
			cacheConnData: { newConnId },
		},
		dispatch: sharedDispatch,
	} = useContext(sharedContext)
	const modalRef = useRef()
	const modalRef2 = useRef()

	const [isCollapsedBeat, setIsCollapsedBeat] = useState(true)
	const [isCollapsedDescription, setIsCollapsedDescription] = useState(true)
	const [currentElement, setCurrentElement] = useState()
	const [lastOpenedRelationship, setLastOpenedRelationship] = useState(null)
	const [isMouseOverBeats, setIsMouseOverBeats] = useState(false)
	const [isMouseOverTextTab, setIsMouseOverTextTab] = useState(false)
	const [tabChangeDetected, setTabChangeDetected] = useState(null)

	// useEffect(() => {
	// 	const handleTabChange = () => {
	// 		setTabChangeDetected(true)
	// 	}

	// 	window.addEventListener('visibilitychange', handleTabChange)

	// 	return () => {
	// 		window.removeEventListener('visibilitychange', handleTabChange)
	// 	}
	// }, [])

	// useEffect(() => {
	// 	if ((isDefaultOpened || isNewStructureTag) && !tabChangeDetected) {
	// 		setIsCollapsedDescription(isDefaultOpened || isNewStructureTag)
	// 	} else {
	// 		setOpenedConnectionCard(false)
	// 	}
	// }, [isDefaultOpened, isNewStructureTag])


	const [beatsList,setBeatslist] = useState([])

	useEffect(()=>{

		const newBeatList = relation?.["sharedBeats"].map((beat,index)=> {return {...beat,order:(index)}})  
		const orderedBeatList= orderBy(newBeatList, ['order'], ['asc'])

		setBeatslist(orderedBeatList)

		},[relation])

	
	const relation = relationships?.relations?.find(rel => rel.id === id)

	useEffect(() => {
		if (newConnId === id || isDefaultOpened) {
			
			sharedDispatch(setNewConnId(false))
			setIsCollapsedDescription(false)
			
			createLog(
				'Connection Details Expanded',
				`{"method":"Create New Connection"}`,
				'Connection Card',
				null,
			)
		} 
	}, [])

	useEffect(() => {
		if (
			!isCollapsedBeat 
		) {
			const connectionCard = document.getElementById(`${id}-connectionCard`)

			setTimeout(() => connectionCard?.scrollIntoView({ behavior: 'smooth', block: 'start' }), 200)
		}
	}, [isCollapsedBeat])

	useEffect(() => {

		if (newConnId !== id) {
			setOpenedConnectionCard(!(isCollapsedBeat && isCollapsedDescription))
		}
	}, [isCollapsedBeat,isCollapsedDescription])

	const defaultConnectionDescriptions = {
		Ally: 'Describe how these two characters help each other overcome their weaknesses.',
		Opponent: 'Describe how these two characters exploit each other’s weaknesses.',
		Protagonist: 'Describe how this Protagonist uniquely pursues this Arc?',
		Antagonist: 'Describe how this Antagonist uniquely complicates this Arc?',
		Supporting: 'Describe how these elements help each other overcome their weaknesses?',
		Opposing: 'Describe how these elements attack each other’s weaknesses?',
	}

	const classes = useStyles({
		isTooltip: false,
		isCollapsed: false,
	})

	const shortname = vizTitleLengthFormat(name, 11)

	const handleStructureTagChange = (id, structureTag) => {
		let currentStructureTag
		if (structureTag === 'Ally' || structureTag === 'Opponent') {
			currentStructureTag = structureTag === 'Ally' ? 'Opponent' : 'Ally'
		} else if (structureTag === 'Protagonist' || structureTag === 'Antagonist') {
			currentStructureTag = structureTag === 'Protagonist' ? 'Antagonist' : 'Protagonist'
		} else if (structureTag === 'Opposing' || structureTag === 'Supporting') {
			currentStructureTag = structureTag === 'Opposing' ? 'Supporting' : 'Opposing'
		} else if (!structureTag) {
			currentStructureTag = structureTagMatcher(
				referenceElement?.__typename,
				destNode.__typename,
				true,
			)
		} else {
			currentStructureTag = structureTag
		}

		// updateCacheField(client,{ id, __typename: 'Relationship' },{structureTag:currentStructureTag},true)

		//updateCacheStructureTagElement(client, { id, __typename: 'Relationship' }, currentStructureTag, true)
		performUpdateRelationshipMutation({
			id,
			input: {
				structureTag: currentStructureTag,
			},
			client,
			__typename: 'Relationship',
			newMapValue: { structureTag: currentStructureTag, updatedAt: new Date().toISOString() },
			broadcast: true,
		})
	}

	const createBeatHandler = async (beatContent = JSON.stringify({ ops: [{ insert: '\n' }] })) => {
		const beatId = createNodeId('Beat')
		const relId = createNodeId('Relationship')
		const tempData = {
			createBeat: {
				beat: {
					id: beatId,
					beatConnections: [],
					driverConnections: [],
					description: beatContent,
					driverConnections: [],
					order: null,
					parentId: referenceElement?.id,
					staticConnections: [],
					noteConnections: [],
					title: '',
					createdAt: '',
					updatedAt: '',
					xCoordinate: null,
					yCoordinate: null,
					vizLocSaved: false,
					firstBeat: '',
					__typename: 'Beat',
				},
				relationship: {
					arc: null,
					connectionType: 'Driver',
					destNode: {
						id: beatId,
						__typename: 'Beat',
					},
					id: relId,
					order: String(referenceElement?.driverConnections.length || 0),
					sourceNode: {
						id: referenceElement?.id,
						__typename: referenceElement?.__typename,
					},
					name: null,
					description: null,
					arcStage: null,
					relName: null,
					__typename: 'Relationship',
					structureTag: null,
					beatsDriven: null,
					sharedBeats: [],
					firstBeat: '',
					createdAt: new Date().toISOString(),
					updatedAt: new Date().toISOString(),
				},
			},
		}
		const destNodeDriverConnection = {
			arc: null,
			connectionType: 'Driver',
			destNode: {
				id: beatId,
				__typename: 'Beat',
			},
			id: createNodeId('Relationship'),
			order: String(destNode?.driverConnections?.length || 0),
			sourceNode: {
				id: destNode.id,
				__typename: destNode.__typename,
			},
			name: null,
			description: null,
			arcStage: null,
			relName: null,
			__typename: 'Relationship',
			structureTag: null,
			beatsDriven: null,
			sharedBeats: [],
			firstBeat: '',
			createdAt: new Date().toISOString(),
			updatedAt: new Date().toISOString(),
		}
		const staticConnectionUpdateFields = {
			beatsDriven: String(+beatsDriven + 1),
			sharedBeats: (sharedBeats || []).concat([tempData.createBeat.beat]),
			updatedAt: new Date().toISOString(),
		}

		setTimeout(() => {
			const elem = document.getElementById(`${id}-beatBreakdownContainer`)
			elem.scrollTo({
				top: elem.scrollHeight,
				behavior: 'smooth',
			})
		})

		createLog(
			`Beat Creation Attempted`,
			`{"workflowStep":${1},"parentNodeType":"${type}"}`,
			'Connection Card',
			'Beat Creation',
		)

		setNewBeat(tempData.createBeat.beat)

		await performCreateSharedBeat({
			tempData,
			destNodeDriverConnection,
			staticConnectionUpdateFields,
			id,
			sharedBeats,
			beatsDriven,
			client,
		})
		// createBeat({
		//   variables: {
		//     input: {
		//       id: beatId,
		//       description: beatContent,
		//       relId,
		//     },
		//     parentId: referenceElement?.id,
		//   },
		//   ignoreResults: true,
		//   // async onCompleted(data) {
		//   // 	if (driverConnections.length) {
		//   // 		setNewBeat(data.createBeat.beat)
		//   // 	}
		//   // 	await createCacheElement(client, data, false)
		//   // 	createCacheConnection(client, data.createBeat.relationship)
		//   // 	await deleteCacheElement(client, tempData.createBeat.beat)
		//   // 	deleteCacheConnections(client, tempData.createBeat.relationship)
		//   // },
		// }).then( () => {

		//    //Need to create connection to destnode
		//   createConnection({
		// 		variables: {
		// 			id: destNodeDriverConnection.id,
		// 			destId: destNodeDriverConnection.destNode.id,
		// 			sourceId: destNodeDriverConnection.sourceNode.id,
		// 			description: "",
		// 			connectionType: 'Driver',
		// 			structureTag: null,
		// 			arcStage: null,
		// 			arc: null,
		// 			name: null,
		// 			relName: 'RELATED',
		// 			beatsDriven: null,
		// 			sharedBeats: null,
		//       firstBeat: ''
		// 		},
		// 	})

		//   updateRelation({
		//     variables: {
		//       id,
		//       input: {beatsDriven:staticConnectionUpdateFields.beatsDriven,sharedBeats:staticConnectionUpdateFields.sharedBeats.map(beat => { return {id:beat.id}})},
		//     },
		//   })

		// })
		quill?.setText('')
		setBeatContentChanged(false)
	}

	const checkBeatContentChanged = (updateId, content, text) => {
		if (text.length > 1) {
			// updateCacheField(client,{id:id,__typename:"Relationship"},{firstBeat: content},false)
			performUpdateRelationshipMutation({
				id,
				input: {
					firstBeat: content,
				},
				client,
				__typename: 'Relationship',
				newMapValue: { firstBeat: content },
				broadcast: false,
			})

			setBeatContentChanged(true)
		} else {
			setBeatContentChanged(false)
		}
	}
	const quillMap = {}

	const { quill, quillRef } = useQuillAutoSave(
		{
			modules: {
				toolbar: false,
			},
			formats: [],
			placeholder: 'Plan this relationship with Beats',
		},
		checkBeatContentChanged,
		id + '-' + 'beat',
		'firstBeat',
		firstBeat,
	)

	useEffect(() => {
		if (beatsList?.length > 0 && firstBeat) {
			quill?.setText('')
			performUpdateRelationshipMutation({
				id,
				input: {
					firstBeat: '',
				},
				client,
			})
		}
	}, [beatsList?.length === 0])

	

	const removeConnection = async connToDelete => {
		// Because the element that comes from deleting a connection with a Note is mutated in
		// order to handle the case to edit the Note instead of the conenction, it is necessary
		let elementToDelete
		// let driverConn

		elementToDelete = relationships?.relations?.find(e => e?.id === connToDelete?.id)
		// if (connToDelete?.__typename !== 'Note') {
		// elementToDelete = relationships?.relations?.find(e => e?.id === connToDelete?.id)
		// const drivers = graphData.nodes[id].driverConnections?.filter(e =>
		// 	e.sourceNode.__typename === 'Beat'
		// 		? e.destNode.id === elementToDelete.destNode.id ||
		// 		  e.destNode.id === elementToDelete.sourceNode.id
		// 		: e.sourceNode.id === elementToDelete.sourceNode.id ||
		// 		  e.sourceNode.id === elementToDelete.destNode.id,
		// )

		//driverConn = drivers?.filter(driver => relationships.relations.find(e => e.id === driver.id))
		// } else {

		// }
		// if (driverConn) {
		// 	driverConn.forEach(conn => {
		// 		deleteCacheConnections(client, conn)
		// 			.then(() => {
		// 				setTimeout(() => refetchUser(), 1000)
		// 			})
		// 			.catch(e => {
		// 				console.log(e)
		// 			})
		// 	})
		// }
		await performDeleteConnectionMutation({
			id: connToDelete?.__typename !== 'Note' ? connToDelete?.id : connToDelete?.id,
			client,
			elementToDelete,
			isCreateCacheConnection: true,
		})
		handleClose()
		if (
			graphData?.nodes?.[elementToDelete.sourceNode.id].coreElement === 'true' ||
			graphData?.nodes?.[elementToDelete.destNode.id].coreElement === 'true'
		) {
			//refetchPremiseConnections()
		}
	}
	const deleteBeatConnectionFromCache = async beatConnection => {
		await deleteCacheConnections(client, beatConnection)

		const connectedChapterId = beatConnection.destNode?.id
		const connectedChapter = graphData.nodesByType['Chapter'].find(
			chapter => chapter?.id === connectedChapterId,
		)

		const mutationData = []

		connectedChapter?.beatConnections.forEach(connection => {
			if (+connection.order > +beatConnection.order) {
				mutationData.push({
					id: connection.id,
					order: String(+connection.order - 1),
				})
			}
		})

		if (mutationData.length) {
			await updateCacheOrderBeat(client, mutationData)
		}
	}

	const reorderBeatsAfterDelete = async deletedBeatId => {
		const beat = graphData.nodesByType['Beat'].find(beat => beat?.id === deletedBeatId)

		const mutationData = []

		//remove driverConnection from all drivers
		beat?.driverConnections.map(connToDelete => {
			const driverId =
				connToDelete.sourceNode.id === beat?.id
					? connToDelete.destNode.id
					: connToDelete.sourceNode.id
			const driver = graphData.nodes[driverId]
			driver.driverConnections.forEach(connection => {
				if (+connection?.order > +connToDelete?.order) {
					mutationData.push({
						id: connection.id,
						order: String(+connection.order - 1),
					})
				}
			})
			//deleteCacheConnections(client, connToDelete)
		})

		//remove beatConnection from Chapter and update order of beats in Chapter
		if (beat?.beatConnections?.length) {
			deleteBeatConnectionFromCache(beat?.beatConnections[0])
		}

		if (mutationData.length) {
			await reorderBeats({
				variables: {
					newNodeOrder: mutationData,
				},
			}).catch(async error => {
				console.log(error)
			})
			updateCacheOrderBeat(client, mutationData)
		}
	}

	const removeElement = async (id, type) => {
		const deletedConnections = []
		const updatedData = []

		const staticConnections = graphData?.nodes?.[id]?.staticConnections
		const driverConnections = graphData?.nodes?.[id]?.driverConnections

		await deleteNode({
			variables: {
				id,
			},
		})
			.then(async () => {
				// refetchUser()
				if (staticConnections?.length) {
					staticConnections.forEach(async connection => {
						deletedConnections.push(connection)
						deleteCacheConnections(client, connection, false)
					})
				}
				if (driverConnections?.length) {
					driverConnections.forEach(async connection => {
						deletedConnections.push(connection)
						deleteCacheConnections(client, connection, false)
					})
				}

				if (type === 'Beat') {
					reorderBeatsAfterDelete(id)
					const beatDrivers = driverConnections.map(conn => {
						const id = conn.sourceNode.__typename === 'Beat' ? conn.destNode.id : conn.sourceNode.id

						return { id }
					})

					const setUniqueStaticConnections = new Set()
					const descreaseBeatsDriven = []
					beatDrivers.map(driver_A => {
						beatDrivers.map(driver_B => {
							let driverPair = [driver_A.id, driver_B.id]
							driverPair.sort()
							driverPair = driverPair.toString()
							if (setUniqueStaticConnections.has(driverPair) || driver_A.id === driver_B.id) {
								return
							}
							setUniqueStaticConnections.add(driverPair)
							const existStaticConnection = graphData.nodes[driver_A.id]?.staticConnections.find(
								conn => {
									return [conn.sourceNode.id, conn.destNode.id].includes(driver_B.id)
								},
							)
							if (existStaticConnection) {
								descreaseBeatsDriven.push(existStaticConnection)
							}
						})
					})

					if (descreaseBeatsDriven?.length) {
						descreaseBeatsDriven.map(conn => {
							updatedData.push(conn)
							updateCacheField(client, conn, {
								beatsDriven: String(Math.max(+conn.beatsDriven - 1, 0)),
								sharedBeats: conn.sharedBeats?.filter(sharedBeat => sharedBeat.id !== id) || [],
								updatedAt: new Date().toISOString(),
							})
						})
					}
				}

				await deleteCacheElement(client, currentElement, true)
				setCurrentElement()
			})
			.catch(e => {
				console.error(e)
				setCurrentElement()

				if (e.message.includes('subscription')) {
					toastHandler('error', e.message, null, 'ListElement', 'Element Deletion')

					if (
						user?.subscriptionStatus?.status === 'trialing' ||
						user?.subscriptionStatus?.status === 'active'
					) {
						window.location.reload()
					}
				} else {
					toastHandler(
						'error',
						`${currentElement?.name} could not be deleted.`,
						null,
						'ListElement',
						'Element Deletion',
					)
				}
			})
	}

	return (
		<ConnectionContainer id={`${id}-connectionCard`}>
			<Div beatsDriven={beatsDriven}>
				<HtmlTooltip
					title={
						<img
							src={deleteIcon}
							onClick={() => {
								if (!JSON.stringify(id)?.includes('temp')) {
									setCurrentElement({ id, type: 'Relationship' })
									createLog(
										'Connection Deletion Attempted',
										`{"workflowStep":${1},"connectionType":"Static"}`,
										'StoryViz',
										'Connection Deletion',
									)
								}
							}}
						/>
					}
					placement="left"
					// onOpen={() => setIsMouseOverRelationship(true)}
				>
					<ElementCardContainer
					// onMouseEnter={() => setIsMouseOverRelationship(true)}
					// onMouseLeave={() => setIsMouseOverRelationship(false)}
					>
						<ConnectionCardContainer
							onMouseEnter={() =>
								createLog(
									'Relationship Hover',
									`{"driverType":"${referenceElement?.__typename}", "relationshipGroupDriverType": "${type}", "structureTag": "${structureTag}"}`,
									'Connection Card',
									null,
								)
							}
							onClick={e => {
								e.stopPropagation()
								if (lastOpenedRelationship === 'Beats') {
									setIsCollapsedBeat()
									setIsCollapsedDescription(false)
							
								} else {

									setIsCollapsedDescription(prev => !prev)
									setLastOpenedRelationship('Description')
								}
								setTabChangeDetected(true)
								createLog(
									`Connection Details ${!openedConnectionCard ? 'Expanded' : 'Collapsed'}`,
									`{"method":"Clicked on driver name", "beatsDriven":"${beatsDriven}"}`,
									'Connection Card',
									null,
								)

								createLog(
									'Relationship Clicked',
									`{"driverType":"${referenceElement?.__typename}", "relationshipGroupDriverType": "${type}", "structureTag": "${structureTag}"}`,
									'Connection Card',
									null,
								)
							}}
						>
							<ElementName onClick={e => {}}>
								<IconContainer isSelected={true} color={referenceElement?.__typename}>
									<img src={getNodeIcon(referenceElement?.__typename)} />
								</IconContainer>{' '}
								<HtmlTooltip
									title={
										<TooltipWrapper>
											<TooltipDescription>Open {referenceElement?.name}</TooltipDescription>
										</TooltipWrapper>
									}
									placement={'bottom'}
									classes={{
										tooltip: classes.tooltipSm,
									}}
									ref={modalRef2}
								>
									<ElementNameLable
										onClick={e => {
											e.stopPropagation()
											sharedDispatch(
												setActiveConnections({
													isActive: true,
													elementId: referenceElement.id,
													elementType: referenceElement.__typename,
													docType: 'Driver',
													appView: 'Outlining',
												}),
											)
										}}
									>
										{vizTitleLengthFormat(referenceElement?.name, 11)}
									</ElementNameLable>
								</HtmlTooltip>
							</ElementName>
							<NewConnectionWrapper
								isOpened={openedConnectionCard}
								supporting={
									structureTag === 'Ally' ||
									structureTag === 'Supporting' ||
									structureTag === 'Protagonist'
										? true
										: structureTag === 'Opponent' ||
										  structureTag === 'Opposing' ||
										  structureTag === 'Antagonist'
										? false
										: true
								}
							>
								<ConnectionImage
									supporting={
										structureTag === 'Ally' ||
										structureTag === 'Supporting' ||
										structureTag === 'Protagonist'
											? true
											: structureTag === 'Opponent' ||
											  structureTag === 'Opposing' ||
											  structureTag === 'Antagonist'
											? false
											: true
									}
									dashed={!beatsList?.length}
									isCollapsed={!openedConnectionCard}
								>
									<div>
										{openedConnectionCard ? (
											<StructureTagLable
												supporting={
													structureTag === 'Ally' ||
													structureTag === 'Supporting' ||
													structureTag === 'Protagonist'
														? true
														: structureTag === 'Opponent' ||
														  structureTag === 'Opposing' ||
														  structureTag === 'Antagonist'
														? false
														: true
												}
												onClick={e => {
													e.stopPropagation()
													handleStructureTagChange(id, structureTag)
												}}
											>
												{structureTag}
											</StructureTagLable>
										) : (
											<img
												src={
													structureTag === 'Ally' ||
													structureTag === 'Supporting' ||
													structureTag === 'Protagonist'
														? supportingConnectionIcon
														: structureTag === 'Opponent' ||
														  structureTag === 'Opposing' ||
														  structureTag === 'Antagonist'
														? opposingConnectionIcon
														: supportingConnectionIcon
												}
												onClick={e => {
													e.stopPropagation()
													handleStructureTagChange(id, structureTag)
												}}
												width={'22px'}
												height={'13px'}
											/>
										)}
									</div>
								</ConnectionImage>
								<Connection>
									<BeatsTextWrapper
										bordered
										onClick={e => {
											e.stopPropagation()
										if(!openedConnectionCard){
											createLog(
												`Connection Details ${!openedConnectionCard ? 'Expanded' : 'Collapsed'}`,
												`{"method":"Clicked on Description Icon"}`,
												'Connection Card',
												null,
											)
										}
											
											createLog(
												'Relationship Clicked',
												`{"driverType":"${referenceElement?.__typename}", "relationshipGroupDriverType": "${type}", "structureTag": "${structureTag}"}`,
												'Connection Card',
												null,
											)

											setIsCollapsedDescription(prev => !prev)
											setIsCollapsedBeat(true)
											setLastOpenedRelationship('Description')
											setTabChangeDetected(true)
										}}
										onMouseEnter={() => setIsMouseOverTextTab(true)}
										onMouseLeave={() => setIsMouseOverTextTab(false)}
									>
										<img
											src={isCollapsedDescription || isMouseOverTextTab ? activeTextIcon : textIcon}
											width={'25px'}
											height={'25px'}
										/>
									</BeatsTextWrapper>
									<BeatsTextWrapper
										onClick={e => {
											e.stopPropagation()
											
											if (openedConnectionCard) {
												createLog(
													`Connection Details ${!openedConnectionCard ? 'Expanded' : 'Collapsed'}`,
													`{"method":"Clicked on Beats Icon", "beatsDriven":"${beatsDriven}"}`,
													'Connection Card',
													null,
												)
											}
											setIsCollapsedDescription(true)
											setIsCollapsedBeat(prev => !prev)
											setLastOpenedRelationship('Beats')
											setTabChangeDetected(true)

											createLog(
												'Relationship Clicked',
												`{"driverType":"${referenceElement?.__typename}", "relationshipGroupDriverType": "${type}", "structureTag": "${structureTag}"}`,
												'Connection Card',
												null,
											)
										}}
										onMouseEnter={() => setIsMouseOverBeats(true)}
										onMouseLeave={() => setIsMouseOverBeats(false)}
									>
										<BeatsCountLabel isActive={!isCollapsedBeat || isMouseOverBeats}>
											{beatsList?.length}
										</BeatsCountLabel>{' '}
										<img
											src={
												isCollapsedBeat
													? isMouseOverBeats
														? activeBeatsIcon
														: beatsIcon
													: activeBeatsIcon
											}
											width={'25px'}
											height={'25px'}
										/>
									</BeatsTextWrapper>
								</Connection>
							</NewConnectionWrapper>

							<ElementName onClick={() => {}}>
								<IconContainer isSelected={true} color={type}>
									<img src={getNodeIcon(type)} />
								</IconContainer>{' '}
								<HtmlTooltip
									title={
										<TooltipWrapper>
											<TooltipDescription>Open {name}</TooltipDescription>
										</TooltipWrapper>
									}
									placement={'bottom'}
									classes={{
										tooltip: classes.tooltipSm,
									}}
									ref={modalRef}
								>
									<ElementNameLable
										onClick={e => {
											e.stopPropagation()
											sharedDispatch(
												setActiveConnections({
													isActive: true,
													elementId: destNode.id,
													elementType: destNode.__typename,
													docType: 'Driver',
													appView: 'Outlining',
												}),
											)
										}}
									>
										{shortname}
									</ElementNameLable>
								</HtmlTooltip>
							</ElementName>
						</ConnectionCardContainer>
					</ElementCardContainer>
				</HtmlTooltip>

				<ConnectionDescription isVisible={!isCollapsedDescription}>
					<DescriptionField
						id={id}
						key={id}
						fieldName={'description'}
						type={type}
						isEdit={isEdit}
						setEdit={setEdit}
						elementDescription={description ?? ''}
						connectionType="Static"
						createLog={createLog}
						placeholder={defaultConnectionDescriptions[structureTag]}
						render={render}
					/>
				</ConnectionDescription>
			</Div>
			{!isCollapsedBeat &&
				(<BeatsContainer
				referenceElement={relation}
				location={parentLocation + " Connection Card"}
				locationId={id}
				beatsList={beatsList}
				connectionTypeField={"sharedBeats"}
				/>)
			}
			{currentElement?.id && (
				<DeleteModal
					elementType={currentElement?.type?.toLowerCase()}
					nodeName={`${currentElement?.name}`}
					isShowed={currentElement}
					details=" and its beats that don't have another driver will be removed too"
					isConnection={currentElement?.type === 'Beat' || currentElement?.type === 'Relationship'}
					acceptHandler={async () => {
						if (currentElement.type === 'Relationship') {
							removeConnection(currentElement)
							createLog(
								'Connection Deletion Confirmed',
								`{"workflowStep":${2},"connectionType":"Static"}`,
								'ElementList',
								'Connection Deletion',
							)
						} else {
							await removeElement(currentElement?.id, currentElement?.type)
						}
						setCurrentElement()
					}}
					closeHandler={() => {
						if (currentElement.type === 'Relationship') {
							createLog(
								'Connection Deletion Cancelled',
								`{"workflowStep":${2},"connectionType":"Static"}`,
								'ElementList',
								'Connection Deletion',
							)
						} else {
							createLog(
								'Element Deletion Cancelled',
								`{"workflowStep":${2},"elementType":"${currentElement?.type}"}`,
								'ElementList',
								'Element Deletion',
							)
						}
						setCurrentElement()
					}}
				/>
			)}
		</ConnectionContainer>
	)
}



export default React.memo(NewConnectionCard)


const Div = styled.div`
	margin-bottom: -7px;
	border-radius: 4px;
	position: relative;
	//display: flex;
	//width: 75%;
	//align-items: start;
	//justify-content: space-between;
	//gap: 20px;
	cursor: pointer;
	//padding: 3px 5px;
	//left: 40px;
`

const ConnectionContainer = styled.div`
	border-radius: 4px;
	//border: 1px ${({ beatsDriven }) => (beatsDriven > 0 ? 'solid' : 'dashed')} #000;
	//background-color: #fff;
	position: relative;
	//display: flex;
	// width: 85%;
	//align-items: start;
	//justify-content: space-between;
	//gap: 20px;
	//cursor: pointer;
	// padding: 3px 5px;
	// left: 32px;
	// top: -3px;
`



