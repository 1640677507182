import React, { useState, useEffect, useCallback, useContext, useMemo, useRef } from 'react'
import FlexSearch from 'flexsearch'
import { setActiveConnections, setIsCatelogOpen, setSearchResult } from '../../state/actions'
import { sharedContext } from '../../state'
import { useLazyGetAllNodesQuery, useUpdateNodeMutation } from '../../hooks'
import { ExitSearchIcon, IconWrapper, InputContainer, SearchInput } from './styled'
import searchIcon from '../../images/search-icon.svg'
import { debounce } from 'lodash'
import { updateCacheField } from '../../utils/apollo'
import { useApolloClient } from '@apollo/client'
import { nodeTypeForId } from '../../utils/utils'
import { useMediaQuery } from '@mui/material'

const SearchComponent = () => {
	const client = useApolloClient()
	const {
		state: { isCatelogOpen, activeConnections },
		dispatch: dispatchAction,
	} = useContext(sharedContext)
	const isSmallDevice = useMediaQuery('(max-width: 600px)')

	const [updateElement] = useUpdateNodeMutation(activeConnections?.elementType ?? 'Character')
	const [query, setQuery] = useState('')

	useEffect(() => {
		if (!isCatelogOpen) {
			dispatchAction(setSearchResult(''))
			setQuery('')
		}
	}, [isCatelogOpen])

	const handleSearch = useRef(
		debounce(async searchterm => {
			dispatchAction(setSearchResult(searchterm))
		}, 400),
	).current

	return (
		<InputContainer>
			<SearchInput
				type="text"
				placeholder={'Search Catalog'}
				value={isCatelogOpen ? query : ''}
				onChange={e => {
					setQuery(e.target.value)
					handleSearch(e.target.value)
				}}
				id="searchInput"
				onClick={e => {
					e.stopPropagation()
					dispatchAction(setIsCatelogOpen('Recent'))
					if (activeConnections?.isActive) {
						const updateData = {
							lastDocView: 'reg',
							updatedAt: new Date().toISOString(),
						}
						updateCacheField(
							client,
							{
								id: activeConnections?.elementId,
								__typename: nodeTypeForId(activeConnections?.elementId),
							},
							updateData,
							true,
						)
						updateElement({
							variables: {
								id: activeConnections?.elementId,
								input: {
									lastDocView: 'reg',
									updatedAt: new Date().toISOString(),
								},
							},
						})
					}
				}}
			/>
			<IconWrapper>
				<img src={searchIcon} fill="black" />
			</IconWrapper>
			{isCatelogOpen ? (
				<ExitSearchIcon
					onClick={e => {
						e.stopPropagation()
						dispatchAction(setIsCatelogOpen(false))
					}}
				>
					{isSmallDevice ? 'X' : 'Close Catalog'}
				</ExitSearchIcon>
			) : (
				<></>
			)}
		</InputContainer>
	)
}

export default SearchComponent
