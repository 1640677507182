/* eslint-disable no-nested-ternary */
/* eslint-disable prettier/prettier */
import styled from 'styled-components'
import dropdown from '../../images/arrowFull.svg'

const StoryTextContainer = styled.div`
	flex: 1;
	overflow-y: auto;
	${({ isExpanded }) => (isExpanded ? 'width: 100%; margin: auto auto 0 auto;' : '')}

	::-webkit-scrollbar-thumb {
		background: #9d9d9d;
		border-radius: 1rem;
		width: auto;
		height: auto;
	}

	::-webkit-scrollbar-thumb:hover {
		background: #b8b8b8;
	}

	::-webkit-scrollbar-track {
		border-radius: 100px;
	}

	::-webkit-scrollbar {
		width: 0.5rem;
		height: 0.5rem;
	}

	&.Pstory {
		box-sizing: border-box;
		width: 100%;
		padding: 20px;
		margin: 0 auto;
		@media (max-width: 600px) {
			min-height: 650px;
			height: 100%;
			position: relative;
			display: ${({ isExpanded }) => (isExpanded ? '' : 'none')};
		}
	}
`

const VizContainer = styled.div`
	width: auto;
	height: 100%;
	margin-top: ${({ isDeepMode }) => (isDeepMode ? 'auto' : '')};
	grid-column: ${({ gridColumn }) => gridColumn};
	grid-row: ${({ gridRow }) => gridRow};

	@media (max-width: 600px) {
		grid-column: ${({ gridMobColumn }) => gridMobColumn};
		grid-row: ${({ gridMobRow }) => gridMobRow};
	}
`

const Overlay = styled.div`
	display: ${props => (props.overlayShow ? 'flex' : 'none')};
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.2) !important;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 999;
`

const DeepModeFooter = styled.div`
	width: 100vw;
	height: auto;
	display: ${props => (props.isDisplay ? 'flex' : 'none')};
	align-items: center;
	justify-content: flex-start;
	font-weight: bold;
	span {
		font-weight: bold;
		cursor: pointer;
		margin-left: 0.5rem;
		color: #0066ff;
	}
`
const ToggleView = styled.div`
	position: absolute;
	top: 1px;
	right: 1px;
	border-radius: 0 9px;
	display: flex;
	align-items: center;
	justify-content: space-evenly;
	padding: 10px;
	background-color: #ffffff;
	p {
		font-size: 9px;
		margin: 0 5px;
	}

	.active {
		font-weight: 600;
	}
	z-index: 1;
`

const ViewContainer = styled.div`
	position: relative;
	width: 100%;
	height: 100%;
	background-color: #f3f3f6;
	border-radius: 9px;
	.timeline-container {
		position: absolute;
		top: 0;
		width: 100%;
		height: 100%;
	}
	overflow: auto;
	visibility: ${({ isChapterViewExpanded, isExpandingView, isVisible }) =>
		(isVisible && !isChapterViewExpanded) || isExpandingView ? 'visible' : 'hidden'};
	@media (min-width: 601px) {
		visibility: ${({ isChapterViewExpanded, isExpandingView }) =>
			!isChapterViewExpanded || isExpandingView ? 'visible' : 'hidden'};
		overflow: ${({ leftToggleTabs, rightToggleTabs }) =>
			leftToggleTabs || rightToggleTabs ? 'auto' : 'hidden'};
	}

	@media (min-width: 769px) {
		overflow: ${({ leftToggleTabs, rightToggleTabs }) =>
			leftToggleTabs && rightToggleTabs ? 'auto' : 'hidden'};
	}
`
const DeepModeBarContainer = styled.div`
	width: auto;
	border-radius: 2px 2px 0 0;
	background-color: #ffffff;

	li {
		cursor: pointer;
		p.active {
			border-bottom: 2px solid #ffc300;
			padding: 0 8px;
			:hover {
				text-decoration: none;
			}
		}
		p:hover {
			text-decoration: underline;
		}
	}

	.MuiBreadcrumbs-separator {
		font-size: 24px;
		margin-right: 0;
	}

	.ql-toolbar.ql-snow {
		background: #ffffff;
		border-width: 1px 1px 0px 1px;
		border-style: solid;
		border-color: #efeef0;
		box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.08);
		position: relative;
		z-index: 1;

		select,
		span,
		button {
			margin-left: 5px;
			margin-right: 5px;
		}
	}

	.ql-snow .ql-picker.ql-font {
		width: auto;
		min-width: 108px;
	}

	.ql-picker .ql-picker-label {
		display: flex;
		justify-content: space-between;
		align-items: center;
		border: none;
		::after {
			content: url(${dropdown});
		}
		svg {
			display: none;
		}
	}
	.ql-icon-picker,
	.ql-color-picker {
		.ql-picker-label {
			::after {
				content: '';
			}
			svg {
				display: block;
			}
		}
	}
`

const MiddlePanel = styled.div`
	width: auto;
	height: auto;
	overflow-y: auto;
	overflow-x: hidden;
	grid-column: ${({ gridColumn }) => gridColumn};
	grid-row: ${({ gridRow }) => gridRow};
	@media (max-width: 600px) {
		grid-column: ${({ gridMobColumn }) => gridMobColumn};
		grid-row: ${({ gridMobRow }) => gridMobRow};
		z-index: ${({ leftToggleTabs, rightToggleTabs }) =>
			leftToggleTabs || rightToggleTabs ? '-1' : 'unset'};
	}
`

const Bar = styled.div`
	height: 30px;
	width: 100%;
	border-radius: 9px;
	display: flex;
	align-items: center;
	font-size: 12px;
	background-color: #f8f8f8;
	position: absolute;
	top: 0;
	cursor: pointer;
	box-sizing: border-box;
	.active {
		font-weight: 600;
	}
	span {
		margin: 0 11px;
	}
	.chapter-icon-container {
		background-color: #ffc300;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 100%;
		width: 30px;
		border-radius: 0 0 0 4px;
		img {
			margin-top: 1px;
		}
	}
	z-index: 2;
`

const RadarChartContainer = styled.div`
	box-sizing: border-box;
	position: absolute;
	top: 0;
	border-radius: 50%;
	border: 1px solid rgba(255, 195, 0, 0.2);
	box-shadow: 1px 1px 6px rgba(0, 0, 0, 0.1);
	background-color: #ffffff;
	width: 90px;
	height: 90px;

	canvas {
		transform: translate(-11px, -2px);
	}
`

const VizMenu = styled.div`
	position: absolute;
	top: 5px;
	left: 5px;
	z-index: 1;
	visibility: ${({ isOpen, isNarrow }) => (isOpen && isNarrow ? 'hidden' : 'visible')};
`

const NotificationIconContainer = styled.div`
	position: absolute;
	top: 20px;
	left: 108px;
	display: flex;
	align-items: center;
	width: 185px;

	.notification-icon-container {
		display: flex;
		align-items: center;
		img {
			width: 18px;
			height: 18px;
		}
		::after {
			display: inline-block;
			content: '';
			margin-top: 7px;
			width: 10px;
			height: 0;
			border: 1px solid #49c100;
			transform: translateY(-3px);
		}
	}

	p {
		border: 2px solid #49c100;
		background-color: #f1f6ee;
		word-break: break-all;
		border-radius: 2px;
		padding: 9px;
		margin: 0;
		font-family: 'Source Sans Pro';
		font-size: 9px;
		font-weight: 600;
		line-height: 10px;
	}
`

const Menu = styled.div`
	position: relative;
	right: 12px;
	bottom: 13px;
	height: 140px;
	width: 140px;

	.pie {
		background: #ffc300;
		border: 1px solid #ffc300;
		border-radius: 50%;
		cursor: pointer;
		position: absolute;
		height: 120px;
		width: 120px;
		transform: scale(1.08);
	}
	.pie1 {
		clip-path: polygon(60px 60px, 120px 0, 150px 85px);
	}
	.pie2 {
		clip-path: polygon(60px 60px, 150px 85px, 85px 150px);
		${({ visHovered }) =>
			visHovered &&
			`
			outline: 1px solid black;
			outline-offset: 3px;
		`}
	}
	.pie3 {
		clip-path: polygon(60px 60px, 85px 150px, 0 120px);
	}
	.pie-color {
		width: 100%;
		height: 100%;
		border-radius: 50%;
		background: #ffffff;
	}
	.pie-color2 {
		clip-path: polygon(60px 60px, 150px 85px, 85px 150px);
	}
	.pie-color3 {
		clip-path: polygon(60px 60px, 82px 150px, 0 120px);
	}
	.active {
		background: #fff3cc;
	}
	.disabled {
		background-color: #898989;
		border: 1px solid #898989;
		.pie-color {
			background-color: #e8e8e8;
		}
	}
	.filter-disabled {
		background-color: #e8e8e8;
	}
`

const SummaryIcon = styled.img`
	position: absolute;
	top: 40px;
	left: 103px;
`

const VisIcon = styled.img`
	position: absolute;
	left: 88px;
	top: 88px;
`

const TimelineIcon = styled.img`
	position: absolute;
	top: 103px;
	left: 40px;
`

const AllVisIcon = styled.img`
	cursor: pointer;
	position: absolute;
	top: 82px;
	left: 50px;
	display: ${({ hovered }) => (hovered ? 'block' : 'none')};
	border-radius: 50%;
	background-color: #fff4cf;
`

const ConnectionVisIcon = styled.img`
	cursor: pointer;
	position: absolute;
	top: 109px;
	left: 115px;
	display: ${({ hovered }) => (hovered ? 'block' : 'none')};
	border-radius: 50%;
	background-color: #fff4cf;
`

const BeatsVisIcon = styled.img`
	cursor: pointer;
	position: absolute;
	top: 128px;
	left: 86px;
	display: ${({ hovered }) => (hovered ? 'block' : 'none')};
	border-radius: 50%;
	background-color: #fff4cf;
`

const ArrowIconContainer = styled.div`
	position: absolute;
	top: 105px;
	left: 65px;

	width: 75px;
	height: 80px;
	p {
		position: absolute;
		top: 40px;
		font-family: Gloria Hallelujah;
		font-size: 9px;
		line-height: 11px;
		text-align: center;
		transform: rotate(-7.31deg);
		background-color: #a1e4fa;
	}
	img {
		position: absolute;
		left: 35px;
	}
`

export {
	VizContainer,
	Overlay,
	DeepModeFooter,
	ToggleView,
	ViewContainer,
	DeepModeBarContainer,
	StoryTextContainer,
	MiddlePanel,
	Bar,
	RadarChartContainer,
	VizMenu,
	SummaryIcon,
	VisIcon,
	TimelineIcon,
	AllVisIcon,
	ConnectionVisIcon,
	BeatsVisIcon,
	Menu,
	ArrowIconContainer,
	NotificationIconContainer,
}
