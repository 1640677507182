/* eslint-disable no-unused-vars */
/* eslint-disable camelcase */
/* eslint-disable arrow-parens */
/* eslint-disable array-callback-return */
/* eslint-disable no-nested-ternary */
/* eslint-disable prettier/prettier */
import React, { useState, useEffect, useContext, useRef } from 'react'

import {
	
	ScapBookLable,
	ScrapBookWrapper,

} from './styles'

import RelationshipList from '@lynit/layout/src/ui/DriverDoc/RelationshipList'

import { sharedContext } from '@lynit/shared/src/state/sharedProvider'

import { graphDataStateContext } from '@lynit/shared/src/state/graphDataProvider'
import {

	setNoElementError,
	setServerError,

} from '@lynit/shared/src/state/actions'

import { toastHandler } from '@lynit/shared/src/utils/backendHandler'


import DeepModeElement from '@lynit/deep-mode-element/src/ui/DeepModeElement'

import { useMemo } from 'react'
import { useCallback } from 'react'
import ThreePartDoc from '../ThreePartDoc'
import { useDndContext } from '@dnd-kit/core'

const DriverDoc = ({
	setActive,
	id,
	types,
	setIsSetModifiers,
	setStructureTagConnnectionModal,
	structureTagConnnectionModal,
	linkNodes,
}) => {
	const graphData = useContext(graphDataStateContext)

	const {
		state: {
			
			activeConnections,
			deleteNode,
			
			currentStory,
		},
		dispatch: sharedDispatch,
	} = useContext(sharedContext)

	
	const {active, over} = useDndContext()

	const [expandedElementId, setExpandedElementId] = useState(id)
	const [currentTab, setCurrentTab] = useState(
		currentStory?.lastDocView === 'Writing' && types ? types[0] : types[1],
	)
	const [newNode, setNewNode] = useState(null)

	const serverErrorInterval = useRef()
	const [currentElement, setCurrentElement] = useState()
	const [view, setView] = useState('reg')

	const name = graphData.nodes[id]?.name

	
	const BottomSectionInputComponent = useCallback(() => {

		return(<RelationshipList
			className="wewe"
			expandedElementId={id}
			key={id}
			id={id}
			name={graphData.nodes[id]?.name ?? ''}
			description={graphData.nodes[id]?.description ?? ''}
			type={graphData.nodes[id]?.__typename ?? currentTab}
			number={graphData.nodes[id]?.number ?? 0}
			toastHandler={toastHandler}
			newNode={newNode}
			setNewNode={setNewNode}
			deleteNode={deleteNode}
			active={currentTab}
			setServerError={setServerError}
			serverErrorInterval={serverErrorInterval}
			setNoElementError={setNoElementError}
			setActive={setActive}
			structureTagConnnectionModal={structureTagConnnectionModal}
			setStructureTagConnnectionModal={setStructureTagConnnectionModal}
			setIsSetModifiers={setIsSetModifiers}
			currentElement={currentElement}
			setCurrentElement={setCurrentElement}
			view={view}
		/>)
	},[id,activeConnections?.elementId])
	
	const middleSectionDecriptionFields = {
		Character: [
			{ label: 'Summary', slug: 'description', placeholder: `Who is ${name}? Consider describing them in their own words.`, },
			{ label: 'Goals and Needs', slug: 'goalsAndNeeds', placeholder: ` Goals are what ${name} externally pursue. Needs are what will truly make ${name} feel whole and complete.`, },
			{ label: 'Inner Conflict', slug: 'innerConflict', placeholder: `How do ${name}’s beliefs and behaviors get in the way of their needs? What life lesson does ${name} still have to learn in order to fulfill their need?`, },
			{ label: 'External Conflict', slug: 'externalConflict', placeholder: `Identify the external challenges, obstacles, and adversaries ${name} faces. These conflicts create tension and propel the plot forward as ${name} strives to overcome them.`, },
		],
		Arc: [
			{ label: 'Summary', slug: 'description', placeHolderText: `In a sentence or two, describe what this Arc is about?`, },
			{ label: 'Central Conflict', slug: 'centralConflict', placeholder: ` What is the main challenge, internal or external, that clashes with the protagonist’s goal?`, },
		],
		Event: [{ label: 'Summary', slug: 'description', placeholder: `Summarize ${name} here.` }],
		Theme: [{ label: 'Summary', slug: 'description', placeholder: `Summarize ${name} here.` }],
	}

	const MiddleSectionInputComponent = useCallback(() => {

		return(
			<ScrapBookWrapper
			>
				{id && (
					<>
						<ScapBookLable>Scrapbook</ScapBookLable>
						<DeepModeElement
							id={id}
						/>
					</>
				)}
			</ScrapBookWrapper>
		)


	},[id])

	// return useMemo(() => {
	return activeConnections?.isActive ? (
		<ThreePartDoc
		id={activeConnections?.elementId}
		docType={"Driver"}
		middleSectionDecriptionFields={middleSectionDecriptionFields[activeConnections.elementType]}
		MiddleSectionInputComponent={MiddleSectionInputComponent}
		BottomSectionInputComponent={BottomSectionInputComponent}
		setActive={setActive}
		/>
	) : (
		<></>
	)
	
}

export default React.memo(DriverDoc)
