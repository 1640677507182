export const SET_GRAPH_DATA = 'SET_GRAPH_DATA'
export const SET_SUMMARY_VIEW = 'SET_SUMMARY_VIEW'
export const SET_STORY = 'SET_STORY'
export const SET_UNSEEN_RECOMMENDATION_COUNT = 'SET_UNSEEN_RECOMMENDATION_COUNT'
export const IS_SAVING = 'IS_SAVING'
export const SET_DEEP_MODE_ELEMENT = 'SET_DEEP_MODE_ELEMENT'
export const OPEN_RECOMMENDATION = 'OPEN_RECOMMENDATION'
export const RECOMMENDATION_OPENED = 'RECOMMENDATION_OPENED'
export const SET_SERVER_ERROR = 'SET_SERVER_ERROR'
export const SET_NO_ELEMENT_ERROR = 'SET_NO_ELEMENT_ERROR'
export const SET_WAS_OFFLINE = 'SET_WAS_OFFLINE'
export const SET_IS_ONLINE = 'SET_IS_ONLINE'
export const SET_CURRENT_RECOMMENDATION = 'SET_CURRENT_RECOMMENDATION'
export const SET_RECOMMENDATION_SHOWN = 'SET_RECOMMENDATION_SHOWN'
export const SET_DIFFERENT_VERSION = 'SET_DIFFERENT_VERSION'
export const SET_CURRENT_STORY = 'SET_CURRENT_STORY'
export const DELETE_STORY = 'DELETE_STORY'
export const SET_CURRENT_STORY_ID = 'SET_CURRENT_STORY_ID'
export const SET_CURRENT_ELEMENT = 'SET_CURRENT_ELEMENT'
export const SET_IS_TOUR_OPEN = 'SET_IS_TOUR_OPEN'
export const IS_EXPORTING = 'IS_EXPORTING'
export const SET_BEATS_IN_CHAPTERS = 'SET_BEATS_IN_CHAPTERS'
export const SET_CONNECTION_DATA = 'SET_CONNECTION_DATA'
export const SET_USER = 'SET_USER'
export const LOGIN = 'LOGIN'
export const SET_CURRENT_FLOW = 'SET_CURRENT_FLOW'
export const LOGOUT = 'LOGOUT'
export const OPEN_TUTORIAL = 'OPEN_TUTORIAL'
export const EXPAND_TUTORIAL_SET = 'EXPAND_TUTORIAL_SET'
export const TUTORIAL_SET_EXPANDED = 'TUTORIAL_SET_EXPANDED'
export const TUTORIAL_OPENED = 'TUTORIAL_OPENED'
export const SET_CLICKED_VALUE_PROP = 'SET_CLICKED_VALUE_PROP'
export const SET_LEFT_TOGGLE_TABS = 'SET_LEFT_TOGGLE_TABS'
export const SET_RIGHT_TOGGLE_TABS = 'SET_RIGHT_TOGGLE_TABS'
export const SET_OPEN_TOOL_TIP = 'SET_OPEN_TOOL_TIP'
export const SET_IS_NEW_USER = 'SET_IS_NEW_USER'
export const SET_CACHE_CONN_DESC = 'SET_CACHE_CONN_DESC'
export const SET_CACHE_CONN_SELECTION = 'SET_CACHE_CONN_SELECTION'
export const SET_NEW_CONN_ID = 'SET_NEW_CONN_ID'
export const SET_DIVING_DEEP = 'SET_DIVING_DEEP'
export const EDITOR_TEXT_CHANGE = 'EDITOR_TEXT_CHANGE'
export const SET_ACTIVE_ELEMENT = 'SET_ACTIVE_ELEMENT'
export const RESET_ACTIVE_ELEMENT = 'RESET_ACTIVE_ELEMENT'
export const MOVE_BEAT = 'MOVE_BEAT'
export const REORDER_ELEMENT = 'REORDER_ELEMENT'
export const SET_USER_SUBSCRIBED = 'SET_USER_SUBSCRIBED'
export const SET_IS_MOBILE = 'SET_IS_MOBILE'
export const ON_SAVE_MODAL = 'ON_SAVE_MODAL'
export const SET_CONNECTION_COUNT = 'SET_CONNECTION_COUNT'
export const SET_ACTIVE_CONNECTIONS = 'SET_ACTIVE_CONNECTIONS'
export const SET_SCROLL_POSITION = 'SET_SCROLL_POSITION'
export const SET_CHAPTER_VIEW_EXPANDED = 'SET_CHAPTER_VIEW_EXPANDED'
export const SET_VISULIZATION_EXPANDED = 'SET_VISULIZATION_EXPANDED'
export const SET_CONTEXT_DEEP_MODE_CONNS = 'SET_CONTEXT_DEEP_MODE_CONNS'
export const SET_SUMMARY_CARD_VISIBLE = 'SET_SUMMARY_CARD_VISIBLE'
export const SET_FIELD_ERROR = 'SET_FIELD_ERROR'
export const SET_SEARCH_RESULT = 'SET_SEARCH_RESULT'
export const SET_IS_CATELOG_OPEN = 'SET_IS_CATELOG_OPEN'
export const SET_ACTIVE_FILTER = 'SET_ACTIVE_FILTER'
export const SET_DRIVER_BEAT = 'SET_DRIVER_BEAT'
