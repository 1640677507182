import React, { useRef } from 'react'
import OutsideClickHandler from '../../utils/OutsideClickHandler'
import { Overlay, ModalContainer, ButtonContainer, AcceptButton, CancelButton } from './styles'
import { throttle } from 'lodash'

const Modal = ({
	nodeName,
	acceptHandler,
	closeHandler,
	isShowed,
	elementType,
	isConnection,
	details,
}) => {
	const bodyHandler = async func => {
		document.body.classList['remove']('overflow')
		await func()
	}
	
	const closeEvent = () => bodyHandler(closeHandler)
	
	const acceptEvent = useRef(
		throttle(() => bodyHandler(acceptHandler),9999, { 'trailing': false })
	 ).current
	
	
		

	return (
		<Overlay
			isShow={isShowed}
			data-testid="modal"
		>
			<OutsideClickHandler onOutsideClick={() => isShowed && closeEvent()}>
				<ModalContainer>
					<div className="title">Hey, wait!</div>
					<div className="content">
						Are you sure you want to delete this {elementType}? <br />
						{isConnection ? (
							''
						) : (
							<>
								<b>{nodeName}</b> {details}.
							</>
						)}
					</div>
					<ButtonContainer>
						<CancelButton onClick={closeEvent}>No, Keep it</CancelButton>
						<AcceptButton onClick={acceptEvent}>Yes, remove it</AcceptButton>
					</ButtonContainer>
				</ModalContainer>
			</OutsideClickHandler>
		</Overlay>
	)
}

export default Modal
