/* eslint-disable no-nested-ternary */
import styled from 'styled-components'
import { FullScreen } from 'react-full-screen'
import { nodeColors, colorStyles } from '@lynit/shared/src/utils/commonStyles'

const StyledFullscreen = styled(FullScreen)`
	height: ${props => (props.isFullscreen ? '100vh' : '100%')};
`

const VizOuterContainer = styled.div`
	width: 100%;
	height: ${props => (props.isFullscreen ? '100vh' : '100%')};
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	border-radius: 4px;
	background-color: #f3f3f6;
	@media (max-width: 500px) {
		width: auto;
		display: flex;
	}
`
const VizUpperPart = styled.div`
	width: 100%;
	height: 2rem;
	border-radius: 0.2rem;
	background-color: ${props => (props.isDeepMode ? '#E8E8E8' : '#eeeeee')};
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
	/* z-index: 99; */
`
const VizUpperTitle = styled.div`
	font-weight: 700;
	font-size: 0.9rem;
	padding-left: 1.5rem;
	display: inline;
	width: calc(100% - 60px);
	p {
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}

	span {
		font-weight: 400;
	}
`
const VizUpperIcon = styled.img`
	padding-right: 0.75rem;
	cursor: pointer;
`

const ArcVizContainer = styled.div`
	max-width: 100%;
	height: 100%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-evenly;
`
const ArcVizInnerContainer = styled.div`
	position: absolute;
	left: 50px;
	height: ${props => (props.isFullscreen ? '100%' : 'calc(100% - 50px)')};

	width: 100%;
	padding-bottom: 1rem;
	overflow: scroll;
	background-color: #f3f3f6;
	display: flex;
	flex-direction: column;
	justify-content: ${({ isEmptyState }) => (isEmptyState ? 'space-evenly' : 'flex-start')};
	align-items: ${({ isEmptyState }) => (isEmptyState ? 'center' : 'flex-start')};

	@media (max-width: 500px) {
		padding-bottom: 0;
	}

	::-webkit-scrollbar-thumb {
		background: #c4c4c4;
		border-radius: 1rem;
		width: auto;
		height: auto;
	}

	::-webkit-scrollbar-thumb:hover {
		background: #b8b8b8;
	}

	::-webkit-scrollbar-track {
		background: #f3f3f6;
	}

	::-webkit-scrollbar {
		width: 0.5rem;
		height: 0;
		margin: 0rem 0 0rem 0;
		padding: 0rem 1rem 0rem 0;
	}
`

const ConnectionsListContainer = styled.div`
	max-width: 45%;
	min-width: 45%;
	height: ${props => (props.isFullscreen ? '107%' : '100%')};
	display: ${props => (props.isDisplay ? 'flex' : 'none')};
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	@media (max-width: 500px) {
		min-width: 100%;
	}
`

const ConnectionsListUpperPart = styled.div`
	display: ${({ areNoChaptersConnected }) => (areNoChaptersConnected ? 'none' : 'flex')};
	background: #f7f7f7;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	padding-left: 1rem;
	padding-right: 0rem;
	width: ${props => (props.isFullscreen ? '30rem' : '96.5%')};
	@media (max-width: 500px) {
		width: 96%;
	}
	@media (max-width: 1500px) {
		width: 95.5%;
	}

	@media (min-width: 1600px) {
		width: 96.5%;
	}
`
const ConnectionsListTitle = styled.h4`
	margin: 0;
	background: #f7f7f7;
	height: auto;
	font-size: 0.87rem;
	padding: 0.3rem 0 0.3rem 0rem;
	@media (max-width: 500px) {
		width: 88%;
	}
`

const CloseImg = styled.img`
	padding-right: 1rem;
	cursor: pointer;
`
const ConnectionsList = styled.div`
	width: 100%;
	height: 100%;
	margin: 0;
	padding: 0;
	overflow-y: scroll;
	overflow-x: hidden;
	background-color: #e4e4e4;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	background-color: white;

	::-webkit-scrollbar-thumb {
		background: #c4c4c4;
		border-radius: 1rem;
		width: auto;
		height: auto;
	}

	::-webkit-scrollbar-thumb:hover {
		background: #b8b8b8;
	}

	::-webkit-scrollbar-track {
		background: #f3f3f6;
	}

	::-webkit-scrollbar {
		width: 0.5rem;
		height: 0rem;
		margin: 0rem 0 0rem 0;
		padding: 0rem 1rem 0rem 0;
		@media (max-width: 500px) {
			height: ${props => (props?.chaptersLength < 5 ? 0 : '0.5rem')};
		}
	}
`

const EmptyStateContainer = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin-top: 5%;

	img {
		width: 80%;
		margin-left: ${({ isNoChapters }) => (isNoChapters ? '5%' : 0)};
	}

	p {
		color: #c4c4c4;
		font-size: 0.8rem;
		width: 90%;
	}

	h4 {
		color: #3185fc;
		font-size: 0.7rem;
		font-weight: 400;
		cursor: pointer;
	}
`
const EmptyStateText = styled.h3`
	width: 90%;
	font-size: 1rem;
	font-weight: 600;
	text-align: center;
	color: #b0b0b0;
`
const ConnectionsListItem = styled.div`
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: flex-start;
	align-items: flex-start;
	background-color: none;
	margin: 0.3rem 0 0.3rem 0;
	@media (max-width: 500px) {
		padding-left: 1rem;
	}
`
const ConnectionLIUpperPart = styled.div`
	width: ${props => (!props?.isUpperPart ? '100%' : props?.isChapterSection ? '100%' : '100%')};
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: ${props =>
		!props?.isUpperPart ? 'flex-start' : !props?.isChapterSection ? 'space-between' : 'flex-start'};
	margin-left: 1rem;

	@media (max-width: 500px) {
		margin-left: 0.3rem;
	}
`
const ConnectionsListItemTitle = styled.h4`
	display: inline-block;
	overflow: hidden;
	white-space: nowrap;
	margin: 0;
	color: ${props => props.isDriverButton && '#FFC300'};
	font-weight: ${props => (props.isDriverButton ? '400' : '700')};
	background: none;
	width: auto;
	height: auto;
	font-size: ${props => (props.isDriverButton ? '0.6rem' : '0.8rem')};
	cursor: ${props => (props.isDriverButton ? 'pointer' : 'default')};
	margin: ${props =>
		props.isDriverButton ? '0.45rem 0.5rem 0.3rem 0.35rem' : '0.3rem 0 0.3rem 0.2rem'};
`

const DriversContainer = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-template-rows: auto;
	gap: 0px 0px;
	padding-left: 1%;
	width: 95%;
	padding-left: 5%;
	height: auto;
`

const DriverContainer = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: ${({ isDeleteDriver }) => (isDeleteDriver ? 'center' : 'flex-start')};
	box-shadow: ${({ isDeleteDriver }) => (isDeleteDriver ? '0px 4px 4px rgb(0 0 0 / 10%)' : 'none')};
	align-items: center;
	height: 1.2rem;
	cursor: pointer;
	padding: 0 0.2rem 0 0.2rem;
	margin: ${({ isDeleteDriver }) => (isDeleteDriver ? '0' : '0.1rem 0 0.1rem 0')};
	border-radius: ${({ isDeleteDriver }) => (isDeleteDriver ? '0.5rem' : '0')};
	background-color: ${({ isDeleteDriver }) => (isDeleteDriver ? '#e4e4e4' : 'none')};
`
const ConnectionDescription = styled.p`
	margin: 0;
	font-size: 0.7rem;
	max-width: 90%;
	font-weight: 400;
	padding: 0.3rem 1rem 0.5rem 1rem;
	@media (max-width: 500px) {
		padding-left: 0.5rem;
	}
`

const ConnectionTextArea = styled.textarea`
	margin: 0;
	width: 90%;
	height: ${props => (props.textAreaHeight > 20 ? `${props.textAreaHeight / 40}rem` : 'auto')};
	border: 0.1rem solid #ffc300;
	font-size: 0.7rem;
	font-family: 'Source Sans Pro', sans-serif;
	font-weight: 400;
	margin: 0.3rem 0.7rem 0.5rem 1rem;

	@media (max-width: 500px) {
		margin-left: 0.3rem;
	}

	&:focus {
		outline: none;
		border: 0.1rem solid #ffc300 !important;
	}
`

const ConnectionDeleteText = styled.p`
	margin: 0;
	font-size: 0.6rem;
	color: #555555;
	padding-left: 0.3rem;
	font-weight: 300;
`
const Timeline = styled.div`
	border-left: ${props => (props?.isSelected ? '0.2rem solid #ffc300' : 'none')};
	background-color: ${props => (props?.isSelected ? '#FFFDF5' : 'none')};
	max-height: 100%;

	min-height: 4rem; //based on desktop design
	min-width: calc(
		100% - 1rem
	); //100% if chapters?.length < 18, else auto based on dynamic node and edge placement
	padding: ${props => (props?.isSelected ? '0.1rem 0 0.1rem 0.8rem;' : '0.1rem 0 0.1rem 1rem;')};
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
`
const NodeContainer = styled.div`
	display: flex;
	margin-top: ${props => {
		if (props.isWhiteDot) {
			if (props.isLastChapter) {
				return '0.525rem'
			}
			if (!props.isLastChapter) {
				return '0.55rem'
			}
		}
		if (!props.isWhiteDot) {
			return '0.05rem'
		}
	}};
	margin-bottom: ${props => {
		if (props?.isWhiteDot) {
			return '0.75rem'
		}
		if (props?.isChapterNode) {
			return '0.25rem'
		}
	}};
	flex-direction: row;
	justify-content: center;
	align-items: center;
`

const NodeAndNumber = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;

`
const ArcNode = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 2.5rem;
	border-radius: 50%;
	background-color: ${props => nodeColors[props?.nodeType]};
	width: 2.5rem;
`

const ArcName = styled.h4`
	bottom: 0;
	width: 80%;
	text-align: center;
	margin: 0;
	margin-block-start: 0;
	margin-block-end: 0;
	padding: 5% 0% 0 0%;
	font-weight: 400;
	font-size: 12px;
	white-space: pre;
`
const ChapterNode = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: ${props => (props?.isInTimeline ? '2rem' : '1rem !important')};
	width: ${props => (props?.isInTimeline ? '2rem' : '1rem !important')};
	border-radius: 50%;
	background-color: ${props => nodeColors[props?.nodeType]};
`

const WhiteDot = styled.div`
	border: ${props => (props?.isNoChapters ? 'none' : '0.5px solid black')};
	cursor: ${props => props?.isNoChapters && 'default'};
	height: 1rem;
	width: 1rem;
	margin-bottom: -1px;
	background-color: #fff;
	border-radius: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
`

const ChapterName = styled.p`
	margin: 0;
	text-align: left;
	position: relative;
	top: ${props => (props.isWhiteDot ? '1rem' : '.5rem')};
	//padding: ${props => (props.isWhiteDot ? '0% 30% 0 8px' : '0% 30% 0 14px')};
	font-weight: 400;
	font-size: 12px;
`
const DottedLine = styled.hr`
	border: none;
	margin: 0 0.125rem 0 0.125rem;
	border-top: 1px dashed black;
	color: #fff;
	background-color: black;
	height: 1px;
	width: 0.25rem;
`

const DottedLineWrapper = styled.div`
	left: 0px;
	position: relative;
	display: flex;
	bottom: 8px;

`

const VizBottomPart = styled.div`
	border-radius: 0.2rem;
	margin-top: 0rem;
	width: auto;
	height: 1.5rem;
	background-color: #f3f3f6;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-evenly;
	box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.1);
`
const UpdateButton = styled.button`
	width: 3rem;
	margin-left: 11.3rem;
	height: 1.3rem;
	font-size: 0.7rem;
	border-radius: 3px;
	border: none;
	background-color: ${colorStyles.lynitYellow};
	cursor: ${props => (props.disabled ? '' : 'pointer')};
	opacity: ${props => (props.disabled ? '0.5' : '1')};
	&:hover {
		text-decoration: none;
		transform: ${props => (props.disabled ? 'translateY(0)' : 'translateY(-2px)')};
	}
	&:active {
		transform: translateY(0);
		background-color: ${props => (props.disabled ? 'white' : `${colorStyles.lightGrey}`)};
		transition: linear 400ms;
	}
	@media (max-width: 500px) {
		width: 2.5rem;
		margin-left: 2.5rem;
		height: 1.3rem;
		font-size: 0.6rem;
	}
`

export {
	StyledFullscreen,
	VizOuterContainer,
	VizUpperPart,
	VizUpperTitle,
	VizUpperIcon,
	ArcVizContainer,
	ArcVizInnerContainer,
	ConnectionsListContainer,
	ConnectionsListUpperPart,
	ConnectionsListTitle,
	CloseImg,
	ConnectionsList,
	ConnectionsListItem,
	ConnectionLIUpperPart,
	ConnectionsListItemTitle,
	DriversContainer,
	DriverContainer,
	ConnectionDescription,
	ConnectionTextArea,
	ConnectionDeleteText,
	Timeline,
	NodeContainer,
	NodeAndNumber,
	ArcNode,
	ArcName,
	ChapterNode,
	WhiteDot,
	ChapterName,
	DottedLine,
	DottedLineWrapper,
	VizBottomPart,
	UpdateButton,
	EmptyStateContainer,
	EmptyStateText,
}
