/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
import React, { useState, useContext, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import { makeStyles, withStyles } from '@mui/styles'
import { useApolloClient } from '@apollo/client'
import { Popover, useMediaQuery } from '@mui/material'

import BreadCrumb from '../BreadCrumb'
import TaskBar from '../TaskBar'
import DeleteModal from '../../shared-ui/DeleteModal'
import UpgradeToPro from '../../shared-ui/UpgradeToPro'
import AutoSave from '../AutoSave'
import MenuIcon from '@mui/icons-material/Menu'
import IconButton from '@mui/material/IconButton'

import {
	deleteCurrentStory,
	logout,
	setCurrentStoryId,
	setUser,
	setCurrentStory,
	setDeepModeElement,
	setIsCatelogOpen,
	setActiveConnections,
} from '../../state/actions'
import { sharedContext } from '../../state/sharedProvider'
import { autoSaveStateContext } from '../../state/autoSaveProvider'
import {
	useCreateBeatMutation,
	useCreateNodeMutation,
	useDeleteStoryMutation,
	useEditStoryMutation,
	useGetCustomerPortalQuery,
	useLazyGetUserQuery,
} from '../../hooks'

import { toastHandler } from '../../utils/backendHandler'
import { createNodeId, getNodeIcon, userNameLengthFormat } from '../../utils/utils'
import OutsideClickHandler from '../../utils/OutsideClickHandler'
import { colorStyles } from '../../utils/commonStyles'
import {
	createCacheElement,
	deleteCacheElement,
	deleteStoryCache,
	updateCacheField,
} from '../../utils/apollo'

import lynit from '../../images/lynit.svg'
import LogoutIcon from '../../images/logout.svg'
import storyIcon from '../../images/storyIcon.svg'
import SupportIcon from '../../images/support.svg'
import dropdownIcon from '../../images/downArrow.svg'
import SettingsIcon from '../../images/engine_icon.svg'
import trashIcon from '../../images/IconDelete.svg'
import outliningIcon from '../../images/outlining-icon.svg'
import writingIcon from '../../images/writing-icon.svg'
import Search from '../Search'
import SearchIcon from '../../images/search-icon.svg'
import PlusSign from '../../images/PlusIcon.svg'
import AccountCircleIcon from '@mui/icons-material/AccountCircle'

import {
	Box,
	Container,
	LynitLogo,
	NameButton,
	HelperDiv,
	MenuContent,
	LogoutButton,
	MenuContainer,
	ProfileOptions,
	ProfileContainer,
	HeaderLeftContainer,
	FrameOption,
	OutlliningLogo,
	WritingLogo,
	ViewLabel,
	ViewContainer,
	ItemWrapper,
	ItemIcon,
	ItemLabel,
	CreateElementBar,
	CreateStoryElementButton,
	IconContainer,
	MenuOption,
} from './styles'
import { userDispatchContext, userStateContext } from '../../state/userProvider'
import { systemStateContext, systemDispatchContext } from '../../state/systemProvider'
import { beatsDataDispatchContext } from '../../state/beatsProvider'
import { useDeleteUserMutation } from '../../hooks/deleteUser'
import { throttle } from 'lodash'
import { graphDataStateContext } from '../../state/graphDataProvider'
import { updateNodeMutations } from '@lynit/shared/src/data'

const styles = {
	root: {
		flexGrow: 1,
	},
	barStyles: {
		boxShadow: 'none',
		backgroundColor: '#FFFFFF',
		zIndex: 200,
	},
	leftComponents: {
		flexGrow: 1,
	},
	link: {
		textDecoration: 'none',
		color: colorStyles.black,
	},
	name: {
		borderRadius: '30px',
		minWidth: '10rem',
		fontSize: 'smaller',
	},
	menu: {
		color: '#000000',
		minWidth: '7rem',
	},
	bottomBar: {
		alignSelf: 'flex-end',
		margin: '0 0 7px 0',
		minHeight: 'fit-content',
	},
	notesButton: {
		fontWeight: 'bold',
		fontSize: '.75rem',
		border: 'none',
	},
	icon: {
		margin: '0 5px',
	},
	back: {
		color: '#555555',
		fontSize: 'smaller',
		textDecoration: 'none',
		transform: 'translateX(-1rem)',
		paddingTop: '1rem',
	},
	notesDiv: {
		width: '100px',
		borderRadius: '10px 10px 0 0',
	},
}

const Navbar = React.memo(({ isDashboard, isLayout, dispatch }) => {
	const isMobile = useMediaQuery('(max-width: 600px)')
	const isSmallScreen = useMediaQuery('(max-width: 768px)')
	const client = useApolloClient()
	const history = useHistory()

	const {
		state: {
			currentStory,
			isExporting,
			deleteStory: isDeleteOpen,
			activeConnections,
			isCatelogOpen,
		},
		dispatch: dispatchAction,
	} = useContext(sharedContext)
	const { isSaving } = useContext(autoSaveStateContext)
	const user = useContext(userStateContext)
	const userDataDispatch = useContext(userDispatchContext)
	const beatsDataDispatch = useContext(beatsDataDispatchContext)
	const systemDispatch = useContext(systemDispatchContext)
	const graphData = useContext(graphDataStateContext)

	const { isLoggedIn, createLog, toastHandler } = useContext(systemStateContext)

	const [createBeat] = useCreateBeatMutation({ ignoreResults: true })

	const [isShow, setActive] = useState(false)
	const [isVisible, setVisible] = useState(false)
	const [isDeleteUser, setIsDeleteUser] = useState(false)
	const [isCreateElementBar, setIsCreateElementBar] = useState(false)

	const [getUser] = useLazyGetUserQuery({
		notifyOnNetworkStatusChange: true,
	})
	const { data: urlCustomerPortal, refetch: refetchCustomerPortal } = useGetCustomerPortalQuery()
	const [deleteStory] = useDeleteStoryMutation()
	const [deleteUser] = useDeleteUserMutation()
	const [editStory] = useEditStoryMutation()

	const createNodeMap = {}
	Object.keys(updateNodeMutations).forEach(key => {
		createNodeMap[key] = useCreateNodeMutation(key, { ignoreResults: true })[0]
	})

	const storyDelete = async () => {
		try {
			await deleteStory({
				variables: {
					id: currentStory?.id,
				},
			})
			const storyCount = user?.user.userSummary.storyCount - 1
			const userSummary = { ...user?.user.userSummary, storyCount }

			updateCacheField(client, { id: user?.user.id, __typename: 'User' }, { userSummary })

			deleteStoryCache(client, currentStory?.id)
			toastHandler('success', 'Story deleted successfully.')
			history.push('/dashboard')
			dispatchAction(deleteCurrentStory(false))
			beatsDataDispatch(setCurrentStoryId(null))
		} catch (error) {
			if (error?.message?.includes('subscription')) {
				toastHandler('error', error.message)
				createLog(
					`ToastHandler Error Message`,
					`{"errorMessage":"${error.message}"}`,
					'Navbar',
					'Story Deletion',
				)
				if (
					user?.user?.subscriptionStatus?.status === 'trialing' ||
					user?.user?.subscriptionStatus?.status === 'active'
				) {
					window.location.reload()
				}
			} else {
				toastHandler('error', error?.message)
				createLog(
					`ToastHandler Error Message`,
					`{"errorMessage":"${error.message}"}`,
					'Navbar',
					'Story Deletion',
				)
			}
		}
	}

	const activeButton = () => setActive(!isShow)

	const splitString = string => {
		let newStringArr = ''
		if (string[string?.length - 1] === ' ') {
			newStringArr = string
				.slice(0, -1)
				.split(' ')
				.map(word => word[0]?.toUpperCase())
				?.join('')
		} else {
			newStringArr = string
				.split(' ')
				.map(word => word[0]?.toUpperCase())
				?.join('')
		}
		return newStringArr
	}

	const createStoryElement = elementType => {
		// const elementName = {
		// 	Character: 'Main Character',
		// 	Arc: 'Main Plot',
		// 	Theme: 'Central Theme',
		// }
		const tempObj = {
			id: createNodeId(elementType),
			createdAt: new Date().toISOString(),
			updatedAt: new Date().toISOString(),
			order: String(graphData.nodesByType[elementType].length),
			__typename: elementType,
			name: `New ${elementType}`,
			description: '',
			contents: '',
			title: '',
			number: '0',
			beatConnections: [],
			driverConnections: [],
			childConnections: [],
			staticConnections: [],
			noteConnections: [],
			xCoordinate: null,
			yCoordinate: null,
			vizLocSaved: null,
			firstBeat: '',
			coreElement: 'false',
			storyText: null,
			wordCount: '0',
			innerConflict: '',
			externalConflict: '',
			type: '',
			goalsAndNeeds: '',
			centralConflict: '',
			lastDocView: '',
			lastOpenedDate: '',
		}
		const optimisticResponse = {}
		optimisticResponse[`create${elementType}`] = tempObj
		let node
		if (elementType === 'Note') {
			node = {
				id: tempObj.id,
				contents: tempObj.contents,
				order: tempObj.order,
			}
			createNodeMap[elementType]({
				node,
				optimisticResponse,
				tempObj,
				hasOder: elementType !== 'Note',
			})
			dispatchAction(setIsCatelogOpen('Notes'))
		} else if (elementType === 'Beat') {
			optimisticResponse[`create${elementType}`] = { beat: tempObj }
			createCacheElement(client, optimisticResponse, false, true)
			createBeat({
				variables: {
					beat: {
						id: optimisticResponse.createBeat.beat.id,
						description: optimisticResponse.createBeat.beat.description,
					},
				},
				ignoreResults: true,
			}).catch(async error => {
				await deleteCacheElement(client, optimisticResponse.createBeat.beat)

				toastHandler(
					'error',
					`There was an error creating the Beat, try again.`,
					null,
					'ListElement',
					'Beat Creation',
				)
				console.error(error)
			})
			dispatchAction(setIsCatelogOpen('Beats'))
		} else if (elementType === 'Chapter') {
			node = {
				id: tempObj.id,
				name: tempObj.name,
				number: '0',
				description: '',
				order: tempObj.order,
			}
			createNodeMap[elementType]({
				node,
				optimisticResponse,
				tempObj,
			})
			dispatchAction(
				setActiveConnections({
					isActive: true,
					elementId: tempObj.id,
					elementType: tempObj.__typename,
					docType: 'Chapter',
					appView: 'Writing',
				}),
			)
			// dispatchAction(setCurrentStory({ ...currentStory, lastOpenedChapter: tempObj.id, lastDocView: "Writing"  }))
			// editStory({
			// 	variables: {
			// 		id: currentStory?.id,
			// 		input: {
			// 			lastOpenedChapter: tempObj.id,
			// 		},
			// 	},
			// })
		} else {
			node = {
				id: tempObj.id,
				name: tempObj.name,
				description: '',
				order: tempObj.order,
				coreElement: tempObj.coreElement,
			}
			createNodeMap[elementType]({
				node,
				optimisticResponse,
				tempObj,
				hasOder: elementType !== 'Note',
			})
			dispatchAction(
				setActiveConnections({
					isActive: true,
					elementId: tempObj.id,
					elementType: tempObj.__typename,
					docType: 'Driver',
					appView: 'Outlining',
				}),
			)
			// dispatchAction(setCurrentStory({ ...currentStory, lastOpenedDriver: tempObj.id, lastDocView: "Outlining"  }))
			// editStory({
			// 	variables: {
			// 		id: currentStory?.id,
			// 		input: {
			// 			lastOpenedChapter: tempObj.id,
			// 		},
			// 	},
			// })
		}
	}

	//import styled from "styled-components";
	//import ToolbarItem from "./ToolbarItem";

	const toolbarItems = [
		{ label: 'Character' },
		{ label: 'Arc' },
		{ label: 'Theme' },
		{ label: 'Event' },
		{ label: 'Beat' },
		{ label: 'Note' },
		{ label: 'Chapter' },
	]

	const CreateElementDropdown = ({ toolbarItems }) => {
		return (
			<CreateElementBar>
				{toolbarItems.map((item, index) => (
					<CreateElementBarButtons key={index} icon={getNodeIcon(item.label)} label={item.label} />
				))}
			</CreateElementBar>
		)
	}

	const CreateElementBarButtons = ({ icon, label }) => {
		const [isHovered, setIsHovered] = useState(false)
		return (
			<ItemWrapper
				onClick={() => {
					createStoryElement(label)
					setIsCreateElementBar(null)
				}}
				onMouseEnter={() => setIsHovered(true)}
				onMouseLeave={() => setIsHovered(false)}
			>
				<IconContainer isSelected={isHovered} elementType={label}>
					<ItemIcon src={icon} alt={`${label} icon`} />
				</IconContainer>

				<ItemLabel isSelected={isHovered}>{label}</ItemLabel>
			</ItemWrapper>
		)
	}

	return (
		<>
			<Container data-public data-dd-privacy="allow" className="mystorycontainer" id="navBar">
				<div style={{ display: 'flex', flexDirection: 'row' }}>
					<HeaderLeftContainer>
						{
							<Box
								display="flex"
								flexDirection="row"
								alignItems="center"
								className="logo-container"
							>
								<LynitLogo
									src={lynit}
									alt="LynitIcon"
									width={72}
									height={30}
									onClick={() => history.push('/dashboard')}
								/>
							</Box>
						}
						{!isMobile && (
							<Box
								display="flex"
								flexDirection="row"
								alignItems="center"
								className="logo-container"
							>
								<OutsideClickHandler onOutsideClick={() => setActive(false)}>
									<ProfileContainer
										data-public
										data-dd-privacy="allow"
										onClick={() => {
											createLog(`Account Options Clicked`, `{}`, 'Navbar', null)
											activeButton()
											refetchCustomerPortal()
											if (!user?.user) {
												getUser()
											}
										}}
									>
										<p>{userNameLengthFormat(user?.user?.name)}</p>
										<img src={dropdownIcon} alt="User dropdown icon" width={12} height={8} />
									</ProfileContainer>
								</OutsideClickHandler>
							</Box>
						)}
						
						{!isMobile && <BreadCrumb />}
						{isLoggedIn && isDashboard && (
							<Box>
								{(isSaving || isExporting) && window.innerWidth < 500 && (
									<AutoSave isMobile={window.innerWidth < 500} />
								)}
							</Box>
						)}
					</HeaderLeftContainer>

					{isLoggedIn && !isDashboard && (
						<>
							<HelperDiv>
								<div style={{ display: 'flex', flexDirection: 'column' }}>
									<div style={{ display: 'flex', flexDirection: 'row' }}>
										{(isSaving || isExporting) && <AutoSave />}
									</div>
								</div>
							</HelperDiv>
						</>
					)}
				</div>
				{isLoggedIn && isLayout && (!isSmallScreen || !isCatelogOpen) && (
					<FrameOption>
						<ViewContainer
							display="flex"
							flexDirection="column"
							alignItems="center"
							className="logo-container"
							isActive={activeConnections?.appView === 'Outlining'}
							onClick={async () => {
								beatsDataDispatch(setDeepModeElement(null))
								dispatchAction(
									setActiveConnections({
										isActive: false,
										elementId: null,
										elementType: null,
										docType: null,
										appView: 'Outlining',
									}),
								)

								dispatch({ key: 'currentView', value: 'visualization' })
							}}
						>
							<OutlliningLogo
								src={outliningIcon}
								alt="OutliningViewLogo"
								width={72}
								height={30}
								style={{
									width: 'min-content',
								}}
							/>
							<ViewLabel isActive={activeConnections?.appView === 'Outlining'}>Outlining</ViewLabel>
						</ViewContainer>
						<ViewContainer
							display="flex"
							flexDirection="column"
							alignItems="center"
							className="logo-container"
							isActive={activeConnections?.appView === 'Writing'}
							onClick={async () => {
								beatsDataDispatch(setDeepModeElement(null))

								dispatchAction(
									setActiveConnections({
										isActive: activeConnections?.appView === 'Writing',
										elementId: null,
										elementType: null,
										docType: null,
										appView: 'Writing',
									}),
								)

								dispatch({ key: 'currentView', value: 'timeline' })
							}}
						>
							<WritingLogo
								src={writingIcon}
								alt="WritingViewLogo"
								width={72}
								style={{
									width: 'min-content',
								}}
								height={30}
							/>
							<ViewLabel isActive={activeConnections?.appView === 'Writing'}>Writing</ViewLabel>
						</ViewContainer>
					</FrameOption>
				)}
				{isLoggedIn && (
					<MenuContainer isSmallScreen={isSmallScreen}>
						{isSmallScreen && (
							<OutsideClickHandler onOutsideClick={() => setVisible(false)}>
								<IconButton
									edge="start"
									color="inherit"
									aria-label="open drawer"
									onClick={() => setVisible(true)}
									sx={{
										mr: isCatelogOpen ? 0 : 0,
									}}
								>
									<MenuIcon />
								</IconButton>
								{isVisible && (
									<MenuOption>
										<LogoutButton
											onClick={event => {
												setVisible(false)
											}}
										>
											<Box
												display="flex"
												flexDirection="row"
												alignItems="center"
												className="logo-container"
												style={{
													width: '100%',
												}}
											>
												<OutsideClickHandler onOutsideClick={() => setActive(false)}>
													<LogoutButton
														data-public
														data-dd-privacy="allow"
														className="userNameLabel"
														onClick={() => {
															createLog(`Account Options Clicked`, `{}`, 'Navbar', null)
															activeButton()
															refetchCustomerPortal()
															if (!user?.user) {
																getUser()
															}
														}}
													>
														<AccountCircleIcon
															sx={{
																height: '20px',
																width: '20px',
															}}
															fontSize="large"
														/>
														<p style={{ fontSize: '1rem' }}>
															{userNameLengthFormat(user?.user?.name)}
														</p>
													</LogoutButton>
												</OutsideClickHandler>
											</Box>
										</LogoutButton>
										{ isLayout && (<LogoutButton
											onClick={event => {
												setIsCreateElementBar(event.currentTarget)
												setVisible(false)
											}}
										>
											<img src={PlusSign} alt="plus-icon" />
											<p>Create a Story Element</p>
										</LogoutButton>)}
										{isLayout && (<LogoutButton
											onClick={() => {
												dispatchAction(setIsCatelogOpen('Recent'))
												setVisible(false)
												dispatchAction(
													setActiveConnections({
														isActive: null,
														elementId: null,
														elementType: null,
														docType: null,
														appView: activeConnections.appView,
													}),
												)
											}}
										>
											<img src={SearchIcon} alt="search-icon" />
											<p>Search Catalog</p>
										</LogoutButton>)}
										{!isDashboard && (
											<LogoutButton
												onClick={() => {
													history.push('/dashboard')
												}}
											>
												<div
													style={{
														width: '100%',
														display: 'flex',
														flexDirection: 'row',
														wordBreak: 'normal',
													}}
												>
													<img src={storyIcon} alt="Story icon" />
													<p>Back to My Stories</p>
												</div>
											</LogoutButton>
										)}
										{user?.user?.subscriptionStatus?.status === 'trialing' &&
											!user?.user?.subscriptionStatus?.subscriptionSchedule && (
												<UpgradeToPro
													message={
														<>
															Your trial period will expire <br />
															in <b>{user?.user?.subscriptionStatus?.daysLeft}.</b>
														</>
													}
												/>
											)}

										<LogoutButton
											onClick={() => {
												if (
													(user?.user?.subscriptionStatus?.status === 'trialing' &&
														!user?.user?.subscriptionStatus?.subscriptionSchedule) ||
													user?.user?.subscriptionStatus?.status === 'canceled' ||
													user?.user?.subscriptionStatus?.status === 'past_due'
												) {
													createLog('Go to plan picker', `{}`, 'Navbar', null)
													history.push('/plan-picker')
												} else {
													createLog('Open Customer Portal', `{}`, 'Navbar', null)
													window.location = urlCustomerPortal?.createCustomerPortalSession
												}
											}}
										>
											<div
												style={{
													width: '100%',
													display: 'flex',
													flexDirection: 'row',
													wordBreak: 'normal',
												}}
											>
												<img src={SettingsIcon} alt="Support button icon" />
												<p>Manage subscription</p>
											</div>
										</LogoutButton>
										<LogoutButton
											onClick={() => {
												createLog('Contact Support Clicked', `{}`, 'Navbar', null)
												window.Beacon('toggle')
												setVisible(false)
											}}
										>
											<img src={SupportIcon} alt="Support button icon" />
											<p>Contact support</p>
										</LogoutButton>
										<LogoutButton
											onClick={async () => {
												createLog('User Logout', `{}`, 'Navbar', null)
												systemDispatch(logout())
												localStorage.clear()
												client.cache.reset()
												history.push('/login')
												userDataDispatch(setUser())
												document.body.setAttribute('style', 'background: white;')
											}}
										>
											<img src={LogoutIcon} alt="Logout icon" />
											<p>Logout</p>
										</LogoutButton>
										<LogoutButton
											onClick={() => {
												createLog(
													'User Deletion Attempted',
													`{"workflowStep":${1}}`,
													'Navbar',
													'User Deletion',
												)
												setIsDeleteUser(true)
												setVisible(false)
											}}
											style={{ color: '#ff8080' }}
										>
											<img src={trashIcon} alt="Trash button icon" />
											<p>Delete Account</p>
										</LogoutButton>
									</MenuOption>
								)}
							</OutsideClickHandler>
						)}
						{!isSmallScreen && isLayout && (
							<CreateStoryElementButton
								onClick={event => {
									setIsCreateElementBar(event.currentTarget)
								}}
							>
								Create a Story Element
							</CreateStoryElementButton>
						)}

						{isCreateElementBar && (
							<Popover
								anchorEl={isCreateElementBar}
								open={!!isCreateElementBar}
								onClose={() => {
									setIsCreateElementBar(null)
								}}
								PaperProps={{
									sx: {
										right: isSmallScreen ? '10px' : 'unset',
										left: isSmallScreen ? 'auto !important' : 'unset',
									},
								}}
								anchorOrigin={{
									vertical: isMobile || isSmallScreen ? 50 : 40,
									horizontal: isSmallScreen ? 'top' : 'center',
								}}
								transformOrigin={{
									vertical: 'top',
									horizontal: isSmallScreen ? 'left' : 'center',
								}}
							>
								<CreateElementDropdown toolbarItems={toolbarItems}></CreateElementDropdown>
							</Popover>
						)}

						{(!isSmallScreen || isCatelogOpen) && isLayout && <Search />}
					</MenuContainer>
				)}
				{isLoggedIn && isDeleteOpen && (
					<DeleteModal
						elementType="story"
						nodeName={`${currentStory?.name} and its elements`}
						details="will be removed too"
						acceptHandler={async () => {
							createLog(
								'Story Deletion Confirmed',
								`{"workflowStep":${2}}`,
								'Navbar',
								'Story Deletion',
							)
							await storyDelete()
						}}
						closeHandler={() => {
							createLog(
								'Story Deletion Cancelled',
								`{"workflowStep":${2}}`,
								'Navbar',
								'Story Deletion',
							)
							dispatchAction(deleteCurrentStory(false))
						}}
						isShowed={isDeleteOpen}
					/>
				)}
				{isLoggedIn && isDeleteUser && (
					<DeleteModal
						elementType="user"
						nodeName={`All of your Account Data will be removed. `}
						details="This action is irreversible"
						acceptHandler={async () => {
							createLog(
								'User Deletion Confirmed',
								`{"workflowStep":${2}}`,
								'Navbar',
								'User Deletion',
							)
							setIsDeleteUser(false)
							await deleteUser()
							systemDispatch(logout())
							localStorage.clear()
							client.cache.reset()
							userDataDispatch(setUser())
							history.push('/signup')
							document.body.setAttribute('style', 'background: white;')
						}}
						closeHandler={() => {
							createLog(
								'User Deletion Cancelled',
								`{"workflowStep":${2}}`,
								'Navbar',
								'User Deletion',
							)
							setIsDeleteUser(false)
						}}
						isShowed={isDeleteUser}
					/>
				)}
			</Container>
			{/* {isLoggedIn && isLayout && isMobile && <TaskBar />} */}
		</>
	)
})

export default withStyles(styles)(Navbar)
