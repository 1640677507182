import styled from 'styled-components'
import { colorStyles, nodeColors } from '@lynit/shared/src/utils/commonStyles'
import diveDeepHover from '@lynit/shared/src/images/diveDeepHover.svg'

const getColor = type => {
	switch (type) {
		case 'Chapter':
			return nodeColors.Chapter
		case 'Character':
			return nodeColors.Character
		case 'Arc':
			return nodeColors.Arc
		case 'Event':
			return nodeColors.Event
		case 'Theme':
			return nodeColors.Theme
		case 'Note':
			return nodeColors.Note
		default:
			return 'white'
	}
}

const ElementContainer = styled.div`
	box-sizing: border-box;
	overflow-x: hidden;
	width: auto;
	height: ${({ isCollapsed }) => (isCollapsed ? 'auto' : '100%')};
	display: flex;
	flex-direction: column;
	margin-bottom: 0.8rem;
	justify-content: flex-start;
	align-items: flex-start;
	background-color: ${props => (props.isSelected ? 'white' : 'white')};
	border-radius: 4px;
	padding: 0.7rem;
	z-index: 0;
	user-select: none;

	:hover {
		background-color: ${props => props.isCollapsed && '#dbdbdb'};
	}
	opacity: ${props => (props.isDragging ? '0.5' : 'unset')};
	@media (max-width: 400px) {
		padding: 0.7rem 0.5rem;
	}
`

const CollapsedElement = styled.div`
	max-width: 100%;
	width: 100%;
	height: auto;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	white-space: nowrap;
	p {
		font-weight: bold;
	}
`

const ArrowIcon = styled.img`
	width: 0.9rem;
	height: 0.9rem;
	transform: ${props => (props.isCollapsed ? 0 : 'rotate(90deg)')};
	cursor: pointer;
	padding: 5px;

	:hover {
		background: ${props => props.isCollapsed && '#c2c2c2'};
		border-radius: 3px;
	}
`

const ArrowIconConnectionCard = styled.img`
	width: 0.5rem;
	height: 0.5rem;
	left: 50%;
	top: ${props => (props.isCollapsed ? '5%' : '14%')};
	transform: ${props => (props.isCollapsed ? 'rotate(90deg)' : 'rotate(270deg)')};
	cursor: pointer;
	position: absolute;
	//padding: 5px;
	//height: 60%;
	display: ${props => (props.collaspedCardHovered ? 'block' : 'none')};
	//display: block;
`

const UncollapsedElement = styled.div`
	padding: 8px;
	width: 100%;
	height: calc(100% - 20px);
	overflow-x: hidden;
	display: ${props => (props.isCollapsed ? 'none' : 'flex')};
	flex-direction: column;
	background-color: white;
	justify-content: flex-start;
	transition: 3ms;
	.beats-heading {
		margin: 0;
		font-weight: 600;
	}
	@media (max-width: 500px) {
		font-size: 0.8rem;
		padding: 4px;
	}

	::-webkit-scrollbar-thumb {
		background: #9d9d9d;
		border-radius: 1rem;
		width: auto;
		height: auto;
	}

	::-webkit-scrollbar-thumb:hover {
		background: #b8b8b8;
	}

	::-webkit-scrollbar {
		width: 4px;
	}
`

const BottomSection = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	color: #888585;
	font-family: Source Sans Pro;
	font-style: normal;
	line-height: normal;
	margin-right: 5px;
	@media (max-width: 500px) {
		font-size: 0.6rem;
	}
	div {
		width: auto;
		height: auto;
		display: flex;
		flex-direction: row;
		align-items: center;
		margin-bottom: 0;
		font-size: 13px;
		cursor: pointer;
		@media (max-width: 500px) {
			font-size: 0.6rem;
		}
		p {
			color: ${({ isHover, isDeepMode }) => (isHover || isDeepMode ? '#FFC300' : '#888585')};
			font-weight: 600;
			margin: 0;
			margin-left: 0.4rem;
		}
		@media (max-width: 400px) {
			img {
				height: 13px;
				width: 13px;
			}
			p {
				display: none;
			}
		}
	}
`

const UpdateButton = styled.button`
	width: 4rem;
	height: 1.7rem;
	border-radius: 3px;
	border: none;
	background-color: ${colorStyles.lynitYellow};
	cursor: ${props => (props.disabled ? '' : 'pointer')};
	opacity: ${props => (props.disabled ? '0.5' : '1')};
	&:hover {
		text-decoration: none;
		transform: ${props => (props.disabled ? 'translateY(0)' : 'translateY(-2px)')};
	}
	&:active {
		transform: translateY(0);
		background-color: ${props => (props.disabled ? 'white' : `${colorStyles.lightGrey}`)};
		transition: linear 400ms;
	}
	@media (max-width: 500px) {
		width: 2.5rem;
		height: 1.3rem;
		font-size: 0.6rem;
	}
`

const CollapsedContainer = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	align-items: center;

	p {
		margin: 0;
	}
	:hover {
		color: ${props => props.hoverStyle && !props.isCollapsed && '#224c96'};
		cursor: ${props =>
			props.hoverStyle && !props.isCollapsed ? `url(${diveDeepHover}), auto` : 'pointer'};
		text-decoration: ${props => props.hoverStyle && !props.isCollapsed && 'underline'};
	}
`

const HelperButtons = styled.div`
	width: auto;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	z-index: 0;
	cursor: pointer;
`

const DriverIconContainer = styled.div`
	display: flex;
	width: 18px;
	height: 18px;
	align-items: center;
	flex-direction: row;
	justify-content: center;
	border: 1px solid rgba(49, 133, 252, 1);
	border-radius: 50%;
	object-fit: contain;
	margin-right: 0.4rem;
	background-color: rgba(49, 133, 252, 1);
`

const IconContainer = styled.div`
	position: relative;
	width: 20px;
	height: 20px;
	border-radius: 100px;
	text-align: center;
	vertical-align: middle;
	display: flex;
	justify-content: center;
	align-items: center;
	background-color: ${props => (props.isSelected ? getColor(props.color) : 'transparent')};

	img {
		width: ${props =>
			props.color === 'Arc'
				? '20px'
				: props.color === 'Event'
				? '8px'
				: props.color === 'Character'
				? 'unset'
				: '0.8rem'};
	}
`

const DeleteIcon = styled.img`
	display: block;
	cursor: pointer;
	opacity: 0.7;
	@media (max-width: 500px) {
		font-size: 0.6rem;
	}
`

const DiveDeepButton = styled.button`
	width: auto;
	height: auto;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-evenly;
	white-space: nowrap;
	text-align: center;
	border-radius: 20px;
	border: ${({ isDeepMode }) => (isDeepMode ? '1px solid #B2CEE9' : `1px solid #2D81FF`)};
	background-color: ${props => (props.isDeepMode ? '#B2CEE9' : '#FEFEFE')};
	color: black;
	color: ${({ isDeepMode }) => (isDeepMode ? '#0d1b4e' : '#2D81FF')};
	cursor: pointer;
	padding: 0.4rem 0.7rem 0.4rem 0.7rem;

	@media (max-width: 768px) {
		font-size: 11px;
		img {
			width: 11px;
		}
	}
	img {
		margin-right: 0.3rem;
	}
	padding-right: 0.9rem;
`

const TextFieldsContainer = styled.div`
	display: flex;
	font-weight: bold;
`

const DraggerContainer = styled.div`
	height: 15px;
	display: flex;
	gap: 10px;
	@media (min-width: 601px) and (max-width: 768px) {
		height: 13px;
		gap: 5px;
	}
`

const IconsContainer = styled.div`
	display: flex;
	gap: 10px;
	justify-content: flex-end;
	align-items: center;
	@media (max-width: 768px) {
		gap: 5px;
	}
	@media (max-width: 360px) {
		width: 100%;
	}
`

const Element = styled.div`
	display: flex;
	flex-direction: row;
	justify-content: flex-start;
	align-items: center;
	overflow: hidden;
`

const BeatsContainer = styled.div`
	width: auto;
	display: ${({ isVisible }) => (isVisible ? 'flex' : 'none')};
	flex-direction: column;
	background: #d7edde;
	border: ${({ isEmpty }) => (isEmpty ? '2px dashed #78C091' : 'unset')};
	border-radius: 10px;
	box-shadow: ${({ isEmpty }) =>
		isEmpty ? 'unset' : '0px 0.888587px 0.888587px rgba(9, 30, 66, 0.1)'};
	user-select: none;

	::-webkit-scrollbar {
		display: none;
	}
	-ms-overflow-style: none;
	scrollbar-width: none;
`

const CreateBeatQuill = styled.div`
	min-height: 25px;
	height: auto;
	border: 0.5px solid #f2f2f2;
	border-radius: 10px;
	background-color: #fbfbfb;
	box-shadow: 0px 0.888587px 0.888587px rgba(9, 30, 66, 0.1);
	.ql-editor {
		padding: 8px 10px;
		::before,
		p {
			margin: 0;
			font-size: 12px;
			font-family: 'Source Sans Pro';
			line-height: 15px;
			vertical-align: middle;
		}
		::before {
			left: 10px;
			right: 10px;
		}
	}
`

const AddBeat = styled.button`
	//width: 60px;
	padding: 2px 5px;
	margin-top: 5px;
	background-color: #78c091;
	color: #ffffff;
	border-radius: 2px;
	border: none;
	font-size: 9px;
	font-weight: 600;
	white-space: nowrap;
	cursor: pointer;
`

const ElementWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	align-items: center;
	gap: 4px;
	width: 100%;
	max-width: calc(100% - 25px);
	@media (max-width: 320px) {
		flex-wrap: wrap;
	}
	cursor: pointer;
`

const BeatsTitle = styled.div`
	display: flex;
	align-items: center;
	gap: 5px;
	.title {
		font-size: 12px;
		font-weight: bold;
		margin: 10px 0;
	}
`

const CreateBeat = styled.div`
	margin: 0;
	padding: 0;
	background: transparent;
	border: none;
	cursor: pointer;
	font-family: 'Source Sans Pro';
	font-weight: bold;
	font-size: 12px;
	line-height: 15px;
	color: #78c091;
`

const TabsContainer = styled.div`
	background-color: #f5f5f5;
	border-radius: 4px;
	margin-top: 10px;
	margin-bottom: 10px;
	display: flex;
`
const CountIcon = styled.div`
	border-radius: 50%;
	background-color: #888585;
	color: #fff;
	font-family: Nunito Sans;
	font-size: 11px;
	font-style: normal;
	font-weight: 700;
	line-height: normal;
	height: 15px;
	width: 15px;
	//left: -15px
	display: flex;
	align-items: center;
	justify-content: center;
	p {
		margin: 0 !important;
	}
`

const TabContainer = styled.div`
	display: flex;
	align-items: center;
	font-size: 12px;
	padding: 0px 5.5px;
	color: ${({ isActive }) => (isActive ? '#000000' : '#888585')};
	img {
		height: 24px;
		opacity: ${({ isActive }) => (isActive ? 1 : 0.5)};
	}
	p,
	div {
		margin-left: 5px;
	}
	div {
		background-color: ${({ isActive }) => (isActive ? '#000000' : '#888585')};
	}
	@media (max-width: 992px) {
		flex-wrap: wrap;
		justify-content: center;
		font-size: 11px;
		img {
			order: 0;
			height: 22px;
		}
		div {
			order: 1;
			height: 17px;
			width: 17px;
			font-size: 9.5px;
		}
		p {
			order: 2;
			margin: 10px 0px 0px;
			flex: 100%;
		}
	}
	@media (max-width: 400px) {
		padding: 0px;
		img {
			height: 18px;
		}
		div {
			height: 15px;
			width: 15px;
			font-size: 9.5px;
		}
	}
`

const BeatsExpanded = styled.div`
	display: flex;
	align-items: center;
	div {
		height: 18px;
		width: 18px;
		p {
			font-size: 11px;
		}
	}
	p {
		margin: 0 0 0 10px;
		font-size: 12px;
	}
	background-color: #fff3ce;
	padding: 6px 10px;
	border-radius: 4px;
	margin-bottom: 10px;
`
const IndividualConnectionWrapper = styled.div`
	position: relative;
	margin: 20px 0;
	height: 100%;
	

	::before {
		position: absolute;
		content: '';
		width: 40px;
		top: 40px;
		border-top: 1px solid #3c40439e;
		left: 4px;
	}
	@media (min-width: 401px) and (max-width: 1024px) {
		width: 100%;
		::before {
			width: 30px;
		}
	}
	@media (max-width: 400px) {
		::before {
			width: 25px;
	}
`

// const driverCardStyle = {
// 	firstcard: {height : }
// }

// top: ${({ firstCard, prevConnCard }) => {
// 	if(firstCard & prevConnCard){
// 		return '-120%;'
// 	} else if(firstCard){
// 		return '-10%;'
// 	} else {
// 		return '-35%;'
// 	}
// 	}}

const NewIndividualConnectionWrapper = styled.div`
	position: relative;
	height: 100%;
	.icon-container {
		display: flex;
		width: 1rem;
		height: 1rem;
		justify-content: center;
		align-items: center;
		border: 1px solid ${props => getColor(props.type)};
		border-radius: 50%;
		object-fit: contain;
		background: ${props => getColor(props.type)};
		//margin-bottom: 10px;
		img {
			width: 10px;
			height: auto;
		}
	}

	@media (min-width: 401px) and (max-width: 1024px) {
		width: 100%;
		::before {
			//width: 30px;
		}
	}
	@media (max-width: 400px) {
		::before {
			//width: 25px;
	}
`

const ConnectionIconContainer = styled.div`
	position: absolute;
	top: 3px;
	left: -26px;
	img {
		height: 18px;
		width: 18px;
		cursor: pointer;
	}
	@media (min-width: 401px) and (max-width: 1024px) {
		// left: -27px;
		//top: 18px;
		img {
			height: 16px;
			width: 16px;
		}
	}
	@media (max-width: 400px) {
		// left: 8px;
		// top: 32px;
		img {
			height: 14px;
			width: 14px;
		}
	}
`
const ConnectionInnerWrapper = styled.div`
	position: relative;
	left: 75px;
	height: auto;
	width: 75%;
	display: inline-block;
`

const Story = styled.div`
	background: #ffffff;
	border: 2px solid #b5b5b5;
	border-radius: 4px;
	padding: 5px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	max-width: 36px;
	width: 100%;
	height: 36px;
	position: absolute;
	left: -36px;
	top: 16px;
	z-index: 1;
	.icon-container {
		display: flex;
		width: 1.5rem;
		height: 1.5rem;
		justify-content: center;
		align-items: center;
		border: 1px solid ${props => getColor(props.type)};
		border-radius: 50%;
		object-fit: contain;
		background: ${props => getColor(props.type)};
		margin-bottom: 10px;
		img {
			width: 12px;
			height: auto;
		}
	}

	.element-type {
		font-size: 9px;
		color: #222222;
		text-overflow: ellipsis;
		overflow: hidden;
		max-width: 42px;
		position: absolute;
		bottom: 2px;
		left: 50%;
		transform: translateX(-50%);
		width: 100%;
		text-align: center;
		white-space: nowrap;
	}

	@media screen and (min-width: 600px) and (max-width: 914px) {
		::after {
			height: 95px;
		}
	}
	@media (min-width: 401px) and (max-width: 1024px) {
		left: -42px;
		height: 30px;
		max-width: 30px;
		.icon-container {
			width: 1.2rem;
			height: 1.2rem;
			img {
				width: 10px;
			}
		}
		.element-type {
			font-size: 8.8px;
		}
	}
	@media (max-width: 400px) {
		left: -49px;
		height: 30px;
		max-width: 27px;
		.icon-container {
			width: 1rem;
			height: 1rem;
		}
	}
`

const ConnectionDescription = styled.div`
	border-radius: 0 0 4px 4px;
	position: relative;
	/* -webkit-transform: translateY(0%);
	-ms-transform: translateY(0%);
	transform: translateY(0%); */
	margin: 0 auto;
	/* top: 0px; */
	/* left: -1px; */
	height: auto;
	width: calc(100% - 2px);
	background-color: white;
	border: 1px solid rgba(232, 232, 232, 1);
	border-top: 0;
	top: -3px;
	z-index: 1;
	display: ${props => (props.isVisible ? `inline-block` : `none`)};
	svg {
		position: absolute;
		z-index: -1;
		border-radius: 4px;
		top: -1%;
	}
	.ql-container {
		background-color: transparent;
		position: relative;
		word-break: break-word;
		box-sizing: border-box;
		-moz-text-overflow: ellipsis;
		width: 95%;
		font-size: 11px;
		display: inline-block;
		margin: 0 5px;
	}
	.ql-editor {
		padding: 6px 5px;
	}
	@media (min-width: 950px) and (max-width: 1024px) {
		//left: -25px;
		.ql-container {
			//width: 70%;
			font-size: 10px;
			//left: 10px;
		}
	}
	@media (min-width: 401px) and (max-width: 949px) {
		//left: -25px;
		.ql-editor {
			//padding: 6px 5px;
			line-height: 1;
		}
		.ql-container {
			//left: 10px;
			font-size: 10px;
			//width: 63%;
		}
	}
	@media (max-width: 400px) {
		//left: -32px;
		.ql-editor {
			//padding: 6px 15px;
			line-height: 1;
		}
		.ql-container {
			//left: 10px;
			font-size: 10px;
			//width: 58%;
		}
	}
`

const RightDescription = styled.div`
	display: inline-flex;
	flex-direction: column;
	align-items: end;
	height: 100%;
	justify-content: space-between;
	position: absolute;
	right: 0px;
	top: 0px;
	img {
		height: 12px;
		margin: 5px;
	}
`
const BeatDrivenCount = styled.div`
	display: inline-flex;
	flex-direction: column;
	align-items: end;
	div {
		height: 17px;
		width: 17px;
		font-size: 10px;
		margin-right: 5px;
		background-color: #f5f5f5;
		color: #676767;
	}
	img {
		height: 16px;
		width: 22x !important;
		opacity: 0.7;
	}
	@media (max-width: 400px) {
		div {
			height: 15px;
			width: 15px;
		}
		img {
			height: 14px;
		}
	}
`
const NewConnectionWrapper = styled.div`
	position: relative;
	flex: 1;
	justify-content: center;
	align-items: center;
	display: flex;
	margin-top: 5px;
	position: relative;
	background: ${props =>
		props.isOpened
			? '#fff'
			: props.supporting
			? `rgba(240, 247, 241, 1)`
			: `rgba(253, 238, 238, 1)`};
	margin-right: -2px;
	margin-left: -2px;
	min-width: 80px;
	// ::before {
	// 	content: '';
	// 	position: absolute;
	// 	border-top: 1.7px solid
	// 		${props => (props.supporting ? `rgba(77, 187, 94, 1)` : `rgba(221, 0, 0, 1)`)};
	// 	top: 21px;
	// 	width: 42%;
	// 	left: 0;
	// 	top: 0;
	// }
	// ::after {
	// 	content: '';
	// 	position: absolute;
	// 	border-top: 1.7px solid
	// 		${props => (props.supporting ? `rgba(77, 187, 94, 1)` : `rgba(221, 0, 0, 1)`)};
	// 	top: 21px;
	// 	width: 42%;
	// 	right: 0;
	// 	top: 0;
	// }
`
const ConnectionWrapper = styled.div`
	position: relative;
	flex: 1;
	display: flex;
	flex-direction: column;
	gap: 20px;
`

const Line = styled.div`
	height: 12px;
	width: 12px;
	border-radius: 50%;
	background-color: #b5b5b5;
	position: absolute;
	top: 40px;
	z-index: 1;
`
const CreateConnection = styled.div`
	border-radius: 4px;
	background-color: #f8f8f8;
	color: #222222;
	font-size: 11px;
	display: flex;
	cursor: pointer;
	padding: 8px;
	position: relative;
	user-select: none;
	flex-direction: column;
	width: 65%;
	left: 45px;
	top: 30px;

	p {
		margin: 0;
	}

	.title {
		font-weight: bold;
		font-size: 12px;
		text-decoration: underline;
		margin-bottom: 5px;
	}

	.description {
		font-size: 10px;
		margin-bottom: 20px;
	}

	${({ noCards, isLength }) => {
		return noCards
			? `::after {
			position: absolute;
			content: '';
			left: -30px;
			border-left: 1px ${isLength ? 'dashed' : 'dashed'} #3c40439e;
			width: 0;
			height: calc(50% + ${isLength ? '8px' : '-5px'});
			top: ${isLength ? '-8px' : '1px'};
		}
	
		::before {
			position: absolute;
			content: '';
			width: 30px;
			top: 40px;
			border-top: 1px dashed #3c40439e;
			left: -30px;
		}
		// @media (min-width: 401px) and (max-width: 1024px) {
		// 	width: 100%;
		// 	::before {
		// 		width: 30px;
		// 	}
		// }
		@media (max-width: 400px) {
			::before {
				width: 25px;
		}`
			: ''
	}}
`

const PlusIcon = styled.div`
	position: absolute;
	width: 15px;
	height: 15px;
	border-radius: 50%;
	background-color: black;
	left: -25px;
	top: 47%;
	transform: translateY(-50%);
	color: #fff;
	font-size: 24px;
	font-weight: 500;
	display: inline-flex;
	justify-content: center;
	align-items: center;
	padding: 1px;
`

const LeftDashLine = styled.div`
	position: absolute;
	left: -56px;
	width: 35px;
	top: 47%;
	border-bottom: 1px ${({ isLength }) => (isLength ? 'solid' : 'dashed')} #3c40439e;
`

const CreateConnectionBtn = styled.div`
	box-sizing: border-box;
	cursor: pointer;
	width: 100%;
	height: 100%;
	padding: 5px 10px;
	border-radius: 20px;
	border: 1px solid ${({ isSupporting }) => (isSupporting ? '#4dbb5e' : '#D00')};
	display: flex;
	align-items: center;
	justify-content: space-between;
	margin-bottom: 10px;

	:hover {
		background-color: ${({ isSupporting }) => (isSupporting ? '#E7F0E9' : '#F6E7E7')};
		border-color: transparent;
	}
	@media (min-width: 320px) and (max-width: 1024px) {
		.icon {
			padding-right: 10px;
		}
		.tool-tip {
			padding-left: 10px;
		}
	}
`
const DescriptionSeparator = styled.div`
	background-color: #e9e9e9;
	height: 100%;
	width: ${({ width }) => `${width}px`};
	position: absolute;
	right: 50px;
	top: 0;
`

const RelationshipHeaderContainer = styled.div`
	display: flex;
	margin-bottom: 35px;
`
const ElementCardContainer = styled.div`
	position: relative;
`
const ElementLable = styled.div`
	display: flex;
	gap: 5px;
	padding: 3px 10px;
	border-radius: 4px 4px 0 0;
	width: 86px;
	background-color: rgba(241, 241, 241, 1);
	position: absolute;
	right: 0;
	top: -18px;
	align-items: center;
	justify-content: center;
	height: 14px;
`
const ElementLableText = styled.div`
	font-family: Source Sans Pro;
	font-size: 12px;
	font-weight: 600;
	line-height: 15.08px;
	text-align: left;
`
const ElementCard = styled.div`
	background: rgba(241, 241, 241, 1);
	padding: 8px 5px 12px 5px;
	border-radius: 4px;
	margin-bottom: 36px;
`
const CreateRelation = styled.div`
	border-radius: 4px 0px 0px 0px;
	display: flex;
	background: rgba(250, 250, 250, 1);
	padding: 5px 8px;
	border-radius: 4px;
	gap: 5px;
	margin-bottom: 10px;
	cursor: pointer;
	:hover {
		background-color: rgba(255, 251, 237, 1);
	}
`
const EmptyDriverWrapper = styled.div`
	border: 1px dashed rgba(199, 199, 199, 1);
	padding: ${props => (props.extraPadding ? `20px 5px` : '5px')};
	border-radius: 4px;
	margin-bottom: 6px;
	background: rgba(255, 251, 237, 1);
`
const EmptyDriverLabel = styled.p`
	font-family: Source Sans Pro;
	font-size: 12px;
	font-weight: 600;
	line-height: 15.08px;
	text-align: center;
	color: rgba(255, 195, 0, 1);
	margin: 0;
`
const EmptyDriverDescription = styled.p`
	font-family: Source Sans Pro;
	font-size: 10px;
	font-weight: 600;
	line-height: 12.57px;
	text-align: center;
	margin: 0;
	color: rgba(189, 189, 189, 1);
`
const Card = styled.div`
	gap: 13px;
	display: flex;
	flex-direction: column;
`
const ConnectionCardContainer = styled.div`
	display: flex;
	.artist-icon {
		width: unset;
	}
`
const ElementName = styled.div`
	display: flex;
	border: 1px solid rgba(232, 232, 232, 1);
	padding: 7px 12px;
	gap: 5px;
	background: rgba(255, 255, 255, 1);
	background: rgba(255, 255, 255, 1);
	position: relative;
	border-radius: 4px;
	align-items: center;
	z-index: 99;
	flex: 1;
	max-width: 200px;
	width: 100%;
`
const ElementNameLable = styled.div`
	font-family: Source Sans Pro;
	font-size: 12px;
	font-weight: 600;
	line-height: 15.08px;
	text-align: left;
	color: rgba(76, 76, 76, 1);

	:hover {
		text-decoration: underline;
	}
`
const ConnectionNameContainer = styled.div`
	display: flex;
`
const ConnectionImage = styled.div`
	position: absolute;
	left: 50%;
	transform: translate(-50%, -65%);
	flex: 1;
	width: 100%;
	height: 5px;
	top: 1%;

	display: flex;
	align-items: center;
	text-align: center;
	flex: 1;

	&::before,
	&::after {
		content: '';
		flex: 1;
		border-bottom: 1.7px ${props => (props.dashed ? `dashed` : `solid`)}
			${props => (props.supporting ? `rgba(77, 187, 94, 1)` : `rgba(221, 0, 0, 1)`)};
	}

	&:not(:empty)::before {
		margin-right: ${props => (props.isCollapsed ? '0.25em' : '0')};
	}

	&:not(:empty)::after {
		margin-left: ${props => (props.isCollapsed ? '0.25em' : '0')};
	}
`
const StructureTagLable = styled.div`
	font-family: Source Sans Pro;
	font-size: 10px;
	font-weight: 400;
	line-height: 23.57px;
	text-align: center;
	color: ${props => (props.supporting ? `rgba(77, 187, 94, 1)` : `rgba(221, 0, 0, 1)`)};
`
const CreateRelationLable = styled.div`
	font-family: Source Sans Pro;
	font-size: 12px;
	font-weight: 600;
	line-height: 15.08px;
	text-align: left;
`
const Connection = styled.div`
	flex-grow: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 15px;
	position: relative;
	img {
		cursor: pointer;
	}
`
const ConnectionLine = styled.div`
	position: absolute;
	left: 50%;
	transform: translate(-50%, -65%);
	flex: 1;
	width: 100%;
	height: 5px;
	top: 50%;

	display: flex;
	align-items: center;
	text-align: center;
	flex: 1;

	&::before,
	&::after {
		content: '';
		flex: 1;
		border-bottom: 1.7px dashed rgba(255, 195, 0, 1);
	}
`
const PremiseLine = styled.div`
	position: absolute;
	left: 50%;
	transform: translate(-50%, -65%);
	flex: 1;
	width: 100%;
	height: 5px;
	top: 50%;

	display: flex;
	align-items: center;
	text-align: center;
	flex: 1;

	&::before,
	&::after {
		content: '';
		flex: 1;
		border-bottom: 1.7px dashed rgba(76, 76, 76, 1);
	}
`
const BeatsTextWrapper = styled.div`
	flex-grow: 1;
	display: flex;
	justify-content: center;
	align-items: center;
	border-right: ${props => (props.bordered ? `1px solid gray` : `none`)};
	height: 100%;
`
const BeatsCountLabel = styled.div`
	font-family: Source Sans Pro;
	font-size: 13px;
	font-weight: 600;
	line-height: 15.08px;
	text-align: right;
	color: ${props => (props.isActive ? `rgba(76, 76, 76, 1)` : `rgba(199, 199, 199, 1)`)};
`

const RelationshipLabelContainer = styled.div`
	display: flex;
	gap: 5px;
	padding: 5px 10px 5px 10px;
	border-radius: 4px;
	border: 1px solid rgba(232, 232, 232, 1);
	align-items: center;
	flex: 1;
	margin-right: -4px;
	z-index: 99;
	background: white;
`
const BeatsCountContainer = styled.div`
	display: flex;
	padding: 1px 1px 2px 10px;
	gap: 5px;
	background: rgba(241, 241, 241, 1);
	justify-content: center;
	align-items: center;
	border: 1px solid rgba(241, 241, 241, 1);
	border-radius: 0 4px 4px 0;
`
const ExtraBeatsContainer = styled.div`
	display: flex;
	padding: 5px 10px 5px 10px;
	gap: 5px;
	background: rgba(241, 241, 241, 1);
	justify-content: center;
	align-items: center;
	width: 48px;
	background: white;
	border-radius: 0 4px 4px 0;
	padding: 4px;
	p {
		font-family: Source Sans Pro;
		font-size: 14px;
		font-weight: 600;
		line-height: 15.08px;
		text-align: left;
		color: rgba(255, 195, 0, 1);
		margin: 0;
	}
`

const RelationshipLabel = styled.div`
	font-family: Source Sans Pro;
	font-size: 14px;
	font-weight: 700;
	line-height: 17.6px;
	text-align: left;
`

const TotalBeatCount = styled.div`
	display: flex;
	gap: 5px;
	width: 48px;
	justify-content: center;
	align-items: center;
	p {
		font-family: Source Sans Pro;
		font-size: 14px;
		font-weight: 600;
		line-height: 15.08px;
		text-align: left;
		color: rgba(76, 76, 76, 1);
		margin: 0;
	}
`
const DriverSection = styled.div`
	color: #000;
	leading-trim: both;
	text-edge: cap;
	margin-top: 12px;
	width: 75%;

	font: 700 12px Source Sans Pro, sans-serif;
`

const TooltipWrapper = styled.div`
	background: rgba(255, 251, 237, 1);
	padding: 5px 10px 5px 10px;
	border-radius: 4px;
	border: ${props => (props.dashed ? '1px dashed rgba(199, 199, 199, 1)' : 'none')};
	// width: 360px;
	// width: 100%;
`
const TooltipLabel = styled.p`
	color: rgba(255, 195, 0, 1);
	text-align: center;
	font-family: Source Sans Pro;
	font-size: 12px;
	font-weight: 600;
	line-height: 15.08px;
	text-align: center;
	margin: 0;
`
const TooltipDescription = styled.div`
	font-family: Source Sans Pro;
	font-size: 10px;
	font-weight: 600;
	line-height: 12.57px;
	text-align: center;
	color: rgba(189, 189, 189, 1);
	display: flex;
	justify-content: center;
	align-items: center;
	img {
		margin: 0 3px;
	}
`
const WrapBeatSections = styled.div`
	margin-left: 16px;
	margin-top: 5px;
`

const BeatContainer = styled.div`
	display: flex;
	align-items: center;
	gap: 6px;
`
const ExistingBeatLabel = styled.div`
	color: rgba(120, 192, 145, 1);
	font-family: Source Sans Pro;
	font-size: 12px;
	font-weight: 600;
	line-height: 15.08px;
	text-align: left;
`
const ExistingBeat = styled.div`
    color: rgba(120, 192, 145, 1);
    background: #f2fff6;
    outline: none;
    padding: 5px 10px;
    border-radius: 4px;
    text-align: start;
    border: 1.8px solid rgba(120, 192, 145, 1);
	cursor: pointer;
	&:hover {
        background: rgba(120, 192, 145, 0.1);
        border-color: rgba(120, 192, 145, 0.8); 
    }
`

const CreateBeatWrapper = styled.div`
	border-radius: 4px;
    border: 1px dashed rgba(120, 192, 145, 1);
    background: rgba(242, 255, 246, 1);
    padding: 10px;
    width: calc(100% - 33px);
	cursor: pointer;
	&:hover {
        background: rgba(120, 192, 145, 0.1);
        border-color: rgba(120, 192, 145, 0.8); 
    }
`
export {
	ElementContainer,
	CollapsedElement,
	UncollapsedElement,
	ArrowIcon,
	ArrowIconConnectionCard,
	BottomSection,
	UpdateButton,
	CollapsedContainer,
	HelperButtons,
	DeleteIcon,
	DiveDeepButton,
	IconContainer,
	TextFieldsContainer,
	DraggerContainer,
	IconsContainer,
	Element,
	BeatsContainer,
	CreateBeatQuill,
	AddBeat,
	ElementWrapper,
	BeatsTitle,
	CreateBeat,
	TabsContainer,
	CountIcon,
	TabContainer,
	BeatsExpanded,
	ConnectionDescription,
	DescriptionSeparator,
	RightDescription,
	BeatDrivenCount,
	ConnectionWrapper,
	Story,
	IndividualConnectionWrapper,
	NewIndividualConnectionWrapper,
	Line,
	ConnectionIconContainer,
	CreateConnectionBtn,
	PlusIcon,
	CreateConnection,
	LeftDashLine,
	ConnectionInnerWrapper,
	DriverSection,
	RelationshipHeaderContainer,
	RelationshipLabel,
	RelationshipLabelContainer,
	BeatsCountContainer,
	ExtraBeatsContainer,
	TotalBeatCount,
	ElementCardContainer,
	ElementLable,
	ElementCard,
	CreateRelation,
	ConnectionCardContainer,
	ElementName,
	ConnectionNameContainer,
	CreateRelationLable,
	ElementLableText,
	Connection,
	ElementNameLable,
	BeatsTextWrapper,
	ConnectionImage,
	Card,
	NewConnectionWrapper,
	StructureTagLable,
	BeatsCountLabel,
	EmptyDriverWrapper,
	EmptyDriverLabel,
	EmptyDriverDescription,
	TooltipWrapper,
	TooltipDescription,
	TooltipLabel,
	DriverIconContainer,
	ConnectionLine,
	ExistingBeatLabel,
	ExistingBeat,
	CreateBeatWrapper,
	BeatContainer,
	WrapBeatSections,
	PremiseLine,
}
