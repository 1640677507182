/* eslint-disable camelcase */
/* eslint-disable no-use-before-define */
/* eslint-disable no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable prettier/prettier */
/* eslint-disable array-callback-return */
import React, { useEffect, useContext, useState, memo } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import isEqual from 'lodash/isEqual'
import { useDroppable } from '@dnd-kit/core'

import {
	setCurrentElement,
	setDeepModeElement,
	setRightToggleTabs,
} from '@lynit/shared/src/state/actions'
import { sharedContext } from '@lynit/shared/src/state/sharedProvider'
import DragHelperModal from '@lynit/shared/src/ui/DragHelperModal'

import StoryEditor from '../StoryEditor'
import { Fonts, Sizes } from '@lynit/shared/src/utils/utils'
import { DeepModeBarContainer, StoryTextContainer, MiddlePanel } from './styles'
//import 'quill/dist/quill.snow.css'
import { systemStateContext } from '@lynit/shared/src/state/systemProvider'
import { graphDataStateContext } from '@lynit/shared/src/state/graphDataProvider'
import {
	beatsDataDispatchContext,
	beatsDataStateContext,
} from '@lynit/shared/src/state/beatsProvider'

const equivalentElements = (prevProps, nextProps) => {
	const isEquivalent =
		isEqual(prevProps.state, nextProps.state) &&
		isEqual(prevProps.deletedNode, nextProps.deletedNode) &&
		prevProps.linkNodes === nextProps.linkNodes
	return isEquivalent
}

const DeepModeElement = memo(({ id }) => {
	const history = useHistory()
	const { story: selectedStory } = useParams()

	const graphData = useContext(graphDataStateContext)

	const {
		state: { leftToggleTabs, rightToggleTabs, story },
		dispatch: sharedDispatch,
	} = useContext(sharedContext)

	const {
		currentStoryId: storyId,
	} = useContext(beatsDataStateContext)
	const beatsDataDispatch = useContext(beatsDataDispatchContext)

	const { isMobile } = useContext(systemStateContext)

	const selectedElement = graphData?.nodes?.[id] ?? null

	

	

	return (
		<div
			style={{
				background: '#0000000',
				height: '100%',
				display: 'flex',
				flexDirection: 'column',
				//padding: '10px',
				gap: '10px',
			}}
		>
			<DeepModeBarContainer className="deepmodebarcontainer">
				<div id={`toolbar-${id}`} key={`toolbar-${id}`}>
					<select className="ql-font">
						{Fonts.map(font => (
							<option aria-label="font-type" key={font} value={font}>
								{font}
							</option>
						))}
					</select>
					<select className="ql-header" defaultValue={''}>
						<option value={1}>Heading 1</option>
						<option value={2}>Heading 2</option>
						<option value={3}>Heading 3</option>
						<option value={4}>Heading 4</option>
						<option value={5}>Heading 5</option>
						<option value={6}>Heading 6</option>
						<option></option>
					</select>
					<select className="ql-size" defaultValue={`${Math.round(11 / 0.75)}px`}>
						{Sizes.map(size => (
							<option aria-label="font-size" key={size} value={`${Math.round(size / 0.75)}px`}>
								{size}
							</option>
						))}
					</select>
					<button className="ql-bold" />
					<button className="ql-italic" />
					<button className="ql-underline" />
					{/* <button className="ql-strike" /> */}

					<select className="ql-align ql-icon-picker">
						<option className="ql-picker-item"></option>
						<option className="ql-picker-item" value="center"></option>
						<option className="ql-picker-item" value="right"></option>
						<option className="ql-picker-item" value="justify"></option>
					</select>
					<button className="ql-indent" value="+1" />
					<button className="ql-indent" value="-1" />

					<button className="ql-list" value="ordered" />
					<button className="ql-list" value="bullet" />

					{/* <button className="ql-link" /> */}

					<select className="ql-color"></select>
					<select className="ql-background"></select>
					<button className="ql-clean" />
				</div>
			</DeepModeBarContainer>

			{selectedElement?.id  && (
				<StoryTextContainer isExpanded={true} className="Pstory">
					<StoryEditor
						id={id}
						key={id}
						story={selectedElement?.storyText}
						placeholder={`Add any additional details and description about ${selectedElement?.name}`}
						isRightPanel={true}
						isDeepMode={true}
						type={selectedElement?.__typename}
					/>
				</StoryTextContainer>
			)}
		</div>
	)
})

const DeepModeElementWrapper = props => {
	const { setNodeRef, isOver, active } = useDroppable({
		id: 'document-container',
		data: {
			id: 'document-container',
		},
	})

	const {
		state: { leftToggleTabs, rightToggleTabs },
	} = useContext(sharedContext)

	return (
		<MiddlePanel
			gridColumn="2/3"
			gridRow="1/3"
			gridMobColumn="2/3"
			gridMobRow="1/3"
			isDeepMode={true}
			leftToggleTabs={false}
			rightToggleTabs={false}
			ref={setNodeRef}
			id="chapter-document-area"
		>
			<DragHelperModal
				description="Drag Beats to Chapters"
				blurOnly={true}
				blur={true}
				target="chapter-document-area"
				isOpen={isOver && active?.data?.current?.type === 'beat'}
			/>
			<DeepModeElement {...props} />
		</MiddlePanel>
	)
}

export default memo(DeepModeElementWrapper, equivalentElements)
