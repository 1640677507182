/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
/* eslint-disable no-nested-ternary */
import React, { useState, useContext, useEffect, useRef } from 'react'
import { useApolloClient } from '@apollo/client'
import {
	SortableContext,
	useSortable,
	verticalListSortingStrategy,
	arrayMove,
} from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import Quill from 'quill'
import orderBy from 'lodash/orderBy'
import Tooltip from '@mui/material/Tooltip'
import { makeStyles } from '@mui/styles'
import Timeline from '@mui/lab/Timeline'
import TimelineItem from '@mui/lab/TimelineItem'
import TimelineSeparator from '@mui/lab/TimelineSeparator'
import TimelineConnector from '@mui/lab/TimelineConnector'
import TimelineContent from '@mui/lab/TimelineContent'
import TimelineDot from '@mui/lab/TimelineDot'
import { useHistory } from 'react-router-dom'
import { useDndContext, useDroppable } from '@dnd-kit/core'
import { sharedContext } from '@lynit/shared/src/state/sharedProvider'
import { graphDataStateContext } from '@lynit/shared/src/state/graphDataProvider'
import { systemStateContext } from '@lynit/shared/src/state/systemProvider'
import {
	beatsDataDispatchContext,
	beatsDataStateContext,
} from '@lynit/shared/src/state/beatsProvider'
import {
	elementReordered,
	setActiveConnections,
	setConnectionCount,
	setCurrentStory,
	setDeepModeElement,
	setIsCatelogOpen,
	setOpenToolTip,
	setScrollPosition,
} from '@lynit/shared/src/state/actions'
import DeleteModal from '@lynit/shared/src/shared-ui/DeleteModal'
import useQuillAutoSave from '@lynit/shared/src/ui/AutoSave/useQuillAutoSave'

import { userStateContext } from '@lynit/shared/src/state/userProvider'


import addBeatIcon from '@lynit/shared/src/images/addBeatIcon.svg'

import {
	createNodeId,
	getNodeIcon,
	nodeTypeForId,
	sortNodesByCreatedAt,
} from '@lynit/shared/src/utils/utils'


import {
	createCacheConnection,
	createCacheElement,
	deleteCacheConnections,
	deleteCacheElement,
	updateCacheBeatsDriven,
	updateCacheDescriptionElement,
	updateCacheField,
	updateCacheOrderBeat,
} from '@lynit/shared/src/utils/apollo'

import {
	
	BeatsContainer,
	CreateBeatQuill,
	AddBeat,
	
	CreateBeat,
	EmptyDriverWrapper,
	EmptyDriverLabel,
	EmptyDriverDescription,
	
	ExistingBeatLabel,
	ExistingBeat,
	CreateBeatWrapper,
} from './styles'

import {
	useGetRelationshipsQuery,
	useReorderBeatsMutation,
	useReorderNodesMutation,
	useUpdateNodeMutation,
	useDeleteConnectionMutation,
	useCreateBeatMutation,
	useDraggable,
    useCreateSharedBeatMutation,
} from '@lynit/shared/src/hooks'

import { nodeColors } from '@lynit/shared/src/utils/commonStyles'
import Arrow from '../../../../shared-package/src/images/arrow.svg'
import DraggableBeats from '@lynit/shared/src/ui/BeatsContainer/DraggableBeats'


const Delta = Quill.import('delta')

const useStyles = makeStyles(() => ({
	tooltip: {
		margin: 0,
		background: '#55534F',
		display: props => props.isTooltip && 'none',
	},
	tooltip1: {
		display: props => !props.isCollapsed && 'none',
		background: '#55534F',
	},
	initialsTooltip: {
		background: '#55534F',
	},
	arrow: {
		color: '#55534F',
	},
	timeline: {
		margin: '0',
		padding: '7px 5px',
	},
	timelineItem: {
		'&:before': {
			display: 'none',
		},
		minHeight: 'unset',
		boxSizing: 'border-box',
		justifyContent: 'center',
		alignItems: 'center',
	},
	timelineContent: {
		padding: '0px 3px',
		width: 'calc(100% - 20px)',
	},
	timelineDot: {
		margin: 0,
		backgroundColor: '#78C091',
	},
	timelineChapter: {
		margin: 0,
		backgroundColor: nodeColors.Chapter,
		width: '8px',
		height: '8px',
		padding: '0',
		display: 'flex',
		justifyContent: 'center',
		alignItems: 'center',
		fontSize: '10px',
		color: '#000000',
	},
	hiddenTimelineConnector: {
		visibility: 'hidden',
	},
	timelineConnector: {
		background: '#78C091',
	},
	dashedTimelineConnector: {
		background: 'transparent',
		border: '1px dashed #78C091',
		width: 0,
	},
	tab: {
		color: '#888585',
		fontFamily: 'Source Sans Pro',
		fontSize: '14px',
		fontStyle: 'normal',
		fontWeight: 700,
		lineHeight: 'normal',
		textTransform: 'none',
		minWidth: '70px',
		padding: '2px 5px',
	},
	tabSelected: {
		color: '#000000 !important',
	},
	indicator: {
		height: '6px',
		borderRadius: '10px',
		backgroundColor: '#FFC300',
	},
	tabs: {
		borderBottom: '6px solid #fff5d4',
		borderRadius: '4px',
	},
}))


const BeatsContainerComponent = React.memo(
	(
        {
        
            referenceElement,
            location,
            locationId,
            beatsList,
            droppable
        } 
		
	) => {
		const client = useApolloClient()
		const {
			state: { deleteNode },
			dispatch: sharedDispatch,
		} = useContext(sharedContext)

		const {
			currentElement: currentDeepModeElement,
			currentStoryId: storyId,
			deepModeElement,
			serverError,
			noElementError,
		} = useContext(beatsDataStateContext)

		const beatsDataDispatch = useContext(beatsDataDispatchContext)
		const { createLog, toastHandler } = useContext(systemStateContext)
		const graphData = useContext(graphDataStateContext)
		const { user, refetchUser } = useContext(userStateContext)

		const [beatContentChanged, setBeatContentChanged] = useState(false)
        const [currentElement, setCurrentElement] = useState()

		const [createBeat] = useCreateBeatMutation({ ignoreResults: true })
		const [reorderNodes] = useReorderNodesMutation()
		const [updateBeat] = useUpdateNodeMutation('Beat')
		const [performDeleteConnectionMutation] = useDeleteConnectionMutation()
		const { data: relationships } = useGetRelationshipsQuery()
        const [performCreateSharedBeat] = useCreateSharedBeatMutation({ ignoreResults: true })
		const [newBeat, setNewBeat] = useState(null)

        const type = referenceElement?.__typename

		const classes = useStyles({
			isTooltip: true,
			isCollapsed: false,
		})

		const checkBeatContentChanged = (updateId, content, text) => {
			if (text.length > 0) {
				setBeatContentChanged(true)
			} else {
				setBeatContentChanged(false)
			}
		}

		const { quill, quillRef } = useQuillAutoSave(
			{
				modules: {
					toolbar: false,
				},
				formats: [],
				placeholder: 'Type your first beat here',
			},
			checkBeatContentChanged,
			referenceElement?.id,
			'firstBeat',
			'',
		)

		
		// useEffect(async () => {
		// 	if (newNode) {
		// 		if (type === 'Chapter') {
		// 			sharedDispatch(setCurrentStory({ ...currentStory, lastOpenedChapter: newNode.id }))
		// 			editStory({
		// 				variables: {
		// 					id: currentStory?.id,
		// 					input: {
		// 						lastOpenedChapter: newNode.id,
		// 					},
		// 				},
		// 			})
		// 		} else {
		// 			sharedDispatch(setCurrentStory({ ...currentStory, lastOpenedDriver: newNode.id }))
		// 			editStory({
		// 				variables: {
		// 					id: currentStory?.id,
		// 					input: {
		// 						lastOpenedDriver: newNode.id,
		// 					},
		// 				},
		// 			})
		// 		}
		// 		setNewNode(null)
		// 	}
		// }, [newNode])

		const createBeatHandler = async (beatContent = JSON.stringify({ ops: [{ insert: '\n' }] })) => {
            const beatId = createNodeId('Beat')
			const relId = createNodeId('Relationship')
            let relationship

            let tempData 
            const beat = {
                id: beatId,
                beatConnections: [],
                driverConnections: [],
                description: beatContent,
                driverConnections: [],
                order: null,
                staticConnections: [],
                noteConnections: [],
                title: '',
                createdAt: new Date().toISOString(),
                updatedAt: new Date().toISOString(),
                xCoordinate: null,
                yCoordinate: null,
                vizLocSaved: false,
                firstBeat: '',
                __typename: 'Beat',
            }
            
            !beatContentChanged && setNewBeat(beat)
            if(referenceElement?.__typename === "Relationship"){

                relationship = {
                    arc: null,
                    connectionType: 'Driver',
                    sourceNode: {
                        id: beatId,
                        __typename: 'Beat',
                    },
                    id: relId,
                    order: String( graphData?.nodes[referenceElement?.sourceNode?.id]?.driverConnections?.length || 0),
                    destNode: {
                        id: referenceElement?.sourceNode?.id,
                        __typename: referenceElement?.sourceNode?.__typename,
                    },
                    name: null,
                    description: null,
                    arcStage: null,
                    relName: null,
                    __typename: 'Relationship',
                    structureTag: null,
                    beatsDriven: null,
                    sharedBeats: [],
                    firstBeat: '',
                    createdAt: new Date().toISOString(),
                    updatedAt: new Date().toISOString(),
                }

                const destNodeDriverConnection = {
                    arc: null,
                    connectionType: 'Driver',
                    destNode: {
                        id: beatId,
                        __typename: 'Beat',
                    },
                    id: createNodeId('Relationship'),
                    order: String(graphData?.nodes[referenceElement?.destNode?.id]?.driverConnections?.length || 0),
                    sourceNode: {
                        id: referenceElement?.destNode?.id,
                        __typename: referenceElement?.destNode?.__typename,
                    },
                    name: null,
                    description: null,
                    arcStage: null,
                    relName: null,
                    __typename: 'Relationship',
                    structureTag: null,
                    beatsDriven: null,
                    sharedBeats: [],
                    firstBeat: '',
                    createdAt: new Date().toISOString(),
                    updatedAt: new Date().toISOString(),
                }

                tempData = {
                    createBeat: {
                        beat,
                        relationship,
                    },
                }

                const staticConnectionUpdateFields = {
                    beatsDriven: String(+beatsList.length + 1),
                    sharedBeats: (beatsList || []).concat([tempData.createBeat.beat]),
                    updatedAt: new Date().toISOString(),
                }
    
                await performCreateSharedBeat({
                    tempData,
                    destNodeDriverConnection,
                    staticConnectionUpdateFields,
                    id: referenceElement?.id,
                    sharedBeats: beatsList,
                    beatsDriven: beatsList?.length,
                    client,
                })
               
            } else {
                relationship = {
                    arc: null,
                    connectionType: referenceElement?.__typename === "Chapter" ? "Beat": 'Driver',
                    sourceNode: {
                        id: beatId,
                        __typename: 'Beat',
                    },
                    id: relId,
                    order: String(beatsList.length || 0),
                    destNode: {
                        id: referenceElement?.id,
                        __typename: referenceElement?.__typename,
                    },
                    name: null,
                    description: null,
                    arcStage: null,
                    relName: null,
                    __typename: 'Relationship',
                    structureTag: null,
                    beatsDriven: null,
                    sharedBeats: [],
                    firstBeat: '',
                    createdAt: new Date().toISOString(),
                    updatedAt: new Date().toISOString(),
                }

                tempData = {
                    createBeat: {
                        beat,
                        relationship,
                    },
                }
               
                
                await createCacheElement(client, tempData, false, false)
			    await createCacheConnection(client, tempData.createBeat.relationship)
                createBeat({
                    variables: {
                        beat: {
                            id: tempData.createBeat.beat.id,
                            description: tempData.createBeat.beat.description,
                        },
                        relationship: {
                            id: tempData.createBeat.relationship.id,
                            destNodeId: tempData.createBeat.relationship.destNode.id,
                            sourceNodeId: tempData.createBeat.relationship.sourceNode.id,
                            description: tempData.createBeat.relationship.description,
                            connectionType: tempData.createBeat.relationship.connectionType,
                            structureTag: tempData.createBeat.relationship.structureTag,
                            relName: 'RELATED',
                            beatsDriven: tempData.createBeat.relationship.beatsDriven,
                            sharedBeats:
                                tempData.createBeat.relationship.sharedBeats?.map(beat => {
                                    return { id: beat.id }
                                }) || [],
                            firstBeat: '',
                            order:tempData.createBeat.relationship.order,
                        },
                        parentId: referenceElement?.id,
                    },
                    ignoreResults: true,
                }).catch(async error => {
                    await deleteCacheElement(client, tempData.createBeat.beat)
                    await deleteCacheConnections(client, tempData.createBeat.relationship)
                    toastHandler(
                        'error',
                        `There was an error creating the Beat, try again.`,
                        null,
                        'RelationshipList',
                        'Beat Creation',
                    )
                    console.error(error)
                })

                
            }
 
			
			const elem = document.getElementById(`${referenceElement?.id}-beatContainer`)
            elem.scrollTo({
                top: elem.scrollHeight,
                behavior: 'smooth',
            })
			//})

			createLog(
				`Beat Creation Attempted`,
				`{"workflowStep":${1},"parentNodeType":"${type}"}`,
				'Beats Tab',
				'Beat Creation',
			)

			
        
			
			quill?.setText('')
			setBeatContentChanged(false)
		}

	

		const reorderNodesAfterDelete = async deletedNodeId => {
			const mutationData = []

			const deletedNode = graphData.nodes[deletedNodeId]

			graphData.nodesByType[deletedNode.__typename].forEach(element => {
				if (element?.order > deletedNode?.order) {
					mutationData.push({
						id: element?.id,
						order: String(element?.order - 1),
					})
				}
			})

			await reorderNodes({
				variables: {
					nodeType: referenceElement.__typename,
					newNodeOrder: mutationData,
				},
			})
				.then(res => {
					if (res && serverError) {
						beatsDataDispatch(setServerError(false))
						clearInterval(serverErrorInterval.current)
					}
				})
				.catch(async err => {
					if (err.message.includes('Cannot update')) {
						beatsDataDispatch(setNoElementError(true))
					}
					if (err.message.includes('Failed to fetch') || err.message.includes('NetworkError')) {
						beatsDataDispatch(setServerError(true))
					}
				})
				.finally(() => {
					setTimeout(() => refetchUser(), 0)
				})
		}


		const removeElement = async (id, type) => {
			const deletedConnections = []
			const deletedElements = [graphData.nodes[id]]
			const updatedData = []

			const staticConnections = graphData?.nodes?.[id]?.staticConnections
			const driverConnections = graphData?.nodes?.[id]?.driverConnections

			await deleteNode({
				variables: {
					id,
				},
			})
				.then(async () => {
					refetchUser()
					if (staticConnections?.length) {
						staticConnections.forEach(async connection => {
							deletedConnections.push(connection)
							deleteCacheConnections(client, connection, false)
						})
					}
					if (driverConnections?.length) {
						driverConnections.forEach(async connection => {

							deletedConnections.push(connection)
							deleteCacheConnections(client, connection, false)

						})
					}
					reorderNodesAfterDelete(id)
					if (type === 'Chapter') {
						const deletedNode = graphData.nodes[id]
						deletedNode?.beatConnections.forEach(beatConnection => {
							deletedConnections.push(beatConnection)
							deleteCacheConnections(client, beatConnection)
						})
					}

                    if (referenceElement?.__typename === 'Relationship' && type ==="Beat") {
                     
                        const beatDrivers = driverConnections.map(conn => {
                            const id = conn.sourceNode.__typename === 'Beat' ? conn.destNode.id : conn.sourceNode.id
    
                            return { id }
                        })
    
                        const setUniqueStaticConnections = new Set()
                        const descreaseBeatsDriven = []
                        beatDrivers.map(driver_A => {
                            beatDrivers.map(driver_B => {
                                let driverPair = [driver_A.id, driver_B.id]
                                driverPair.sort()
                                driverPair = driverPair.toString()
                                if (setUniqueStaticConnections.has(driverPair) || driver_A.id === driver_B.id) {
                                    return
                                }
                                setUniqueStaticConnections.add(driverPair)
                                const existStaticConnection = graphData.nodes[driver_A.id]?.staticConnections.find(
                                    conn => {
                                        return [conn.sourceNode.id, conn.destNode.id].includes(driver_B.id)
                                    },
                                )
                                if (existStaticConnection) {
                                    descreaseBeatsDriven.push(existStaticConnection)
                                }
                            })
                        })
    
                        if (descreaseBeatsDriven?.length) {
                            descreaseBeatsDriven.map(conn => {
                                updatedData.push(conn)
                                updateCacheField(client, conn, {
                                    beatsDriven: String(Math.max(+conn.beatsDriven - 1, 0)),
                                    sharedBeats: conn.sharedBeats?.filter(sharedBeat => sharedBeat.id !== id) || [],
                                    updatedAt: new Date().toISOString(),
                                })
                            })
                        }
                    }
					await deleteCacheElement(client, currentElement, true)

					if (currentElement?.id === currentDeepModeElement) {
						beatsDataDispatch(setDeepModeElement(null))
					}
					setCurrentElement()
				})
				.catch(e => {
					console.error(e)
					setCurrentElement()

					if (e.message.includes('subscription')) {
						toastHandler('error', e.message, null, 'RelationshipList', 'Element Deletion')

						if (
							user?.subscriptionStatus?.status === 'trialing' ||
							user?.subscriptionStatus?.status === 'active'
						) {
							window.location.reload()
						}
					} else {
						toastHandler(
							'error',
							`${currentElement?.name} could not be deleted.`,
							null,
							'RelationshipList',
							'Element Deletion',
						)
					}
				})
		}

		const removeConnection = async connToDelete => {
			// Because the element that comes from deleting a connection with a Note is mutated in
			// order to handle the case to edit the Note instead of the conenction, it is necessary
			let elementToDelete
			// let driverConn

			elementToDelete = relationships?.relations?.find(e => e?.id === connToDelete?.id)
			// if (connToDelete?.__typename !== 'Note') {
			// elementToDelete = relationships?.relations?.find(e => e?.id === connToDelete?.id)
			// const drivers = graphData.nodes[id].driverConnections?.filter(e =>
			// 	e.sourceNode.__typename === 'Beat'
			// 		? e.destNode.id === elementToDelete.destNode.id ||
			// 		  e.destNode.id === elementToDelete.sourceNode.id
			// 		: e.sourceNode.id === elementToDelete.sourceNode.id ||
			// 		  e.sourceNode.id === elementToDelete.destNode.id,
			// )

			//driverConn = drivers?.filter(driver => relationships.relations.find(e => e.id === driver.id))
			// } else {

			// }
			// if (driverConn) {
			// 	driverConn.forEach(conn => {
			// 		deleteCacheConnections(client, conn)
			// 			.then(() => {
			// 				setTimeout(() => refetchUser(), 1000)
			// 			})
			// 			.catch(e => {
			// 				console.log(e)
			// 			})
			// 	})
			// }
			performDeleteConnectionMutation({
				id: connToDelete?.__typename !== 'Note' ? connToDelete?.id : connToDelete?.id,
				client,
				elementToDelete,
				isCreateCacheConnection: true,
			})
			if (
				graphData?.nodes?.[elementToDelete.sourceNode.id].coreElement === 'true' ||
				graphData?.nodes?.[elementToDelete.destNode.id].coreElement === 'true'
			) {
				//refetchPremiseConnections()
			}
		}

	

		return (
			<>  
            <BeatsContainer
                id={`${referenceElement?.id}-beatContainer`}
                isVisible={true}
                isEmpty={!beatsList?.length}
                className="beatsContainer"
                ref={droppable}
              
            >
                <Timeline className={classes.timeline}>
                    {/* Create first beat */}
                    <TimelineItem
                        className={classes.timelineItem}
                        style={{ display: beatsList?.length ? 'none' : 'flex' }}
                    >
                        <TimelineSeparator>
                            <TimelineConnector className={classes.hiddenTimelineConnector} />
                            <TimelineDot className={classes.timelineDot} />
                            <TimelineConnector className={classes.hiddenTimelineConnector} />
                            {beatContentChanged ? <div style={{ height: '20px' }} /> : <></>}
                        </TimelineSeparator>
                        <TimelineContent className={classes.timelineContent}>
                            <CreateBeatQuill
                                ref={quillRef}
                                key={referenceElement?.id}
                                id={referenceElement?.id}
                                data-fieldname={'firstBeat'}
                                translate="no"
                            />
                            {beatContentChanged && (
                                <AddBeat
                                    onClick={() => createBeatHandler(JSON.stringify(quill.getContents()))}
                                >
                                    + Create First Beat
                                </AddBeat>
                            )}
                        </TimelineContent>
                    </TimelineItem>

                    {/* Beats */}

                    {
                        <SortableContext
                            items={beatsList.map(beat => {
                                

                                return beat.id + "-" +location+ "-" + referenceElement?.id
                                    
                            })}
                            //items={driverConnections.map(connection => connection?.id )}
                            strategy={verticalListSortingStrategy}
                        >
                            {beatsList?.map((beatItem, index) => {
                                const beat = graphData.nodes[beatItem.id]
                                const nodeId = beat?.beatConnections?.[0]?.destNode?.id?.startsWith(
                                    'bea',
                                )
                                    ? beat?.beatConnections?.[0]?.sourceNode?.id
                                    : beat?.beatConnections?.[0]?.destNode?.id
                                const node = graphData.nodes[nodeId]
                                return (
                                    <TimelineItem
                                        key={`${index}-${beat?.id}`}
                                        className={classes.timelineItem}
                                    >
                                        <DraggableBeats
                                            index={index}
                                            key={index}
                                            node={node}
                                            beatsList={beatsList}
                                            beatId={beat?.id}
                                            beat={beat}
                                            setCurrentElement={setCurrentElement}
                                            referenceElementType={referenceElement?.__typename}
                                            referenceElement={referenceElement}
                                            connectionId={beatItem?.connectionIc}
                                            beatConnection={beat?.beatConnections?.[0]}
                                            setIsSetModifiers={() => {}}
                                            locationId={locationId}
                                            location={location}
                                            isNewBeat={newBeat?.id===beat?.id}
                                            setNewBeat={setNewBeat}
                                        />
                                    </TimelineItem>
                                )
                            })}
                        </SortableContext>
                    }
                    <div
                        style={{
                            marginLeft: '16px',
                            marginTop: '5px',
                        }}
                    >
                        <div
                            style={{
                                display: 'flex',
                                alignItems: 'center',
                                gap: '6px',
                            }}
                        >
                            <div>
                                <img src={Arrow} />
                            </div>
                            <div
                                style={{
                                    width: `calc(100% - 30px)`,
                                }}
                            >
                                <ExistingBeatLabel>What will happen next?</ExistingBeatLabel>
                                {!["Search Connection Card","Viz Connection Card","Driver Card"].includes(location) && 
                                (<ExistingBeat
                                    onClick={() => {
                                        //sharedDispatch(setActiveFilter('Beats'))
                                        sharedDispatch(setIsCatelogOpen('Beats'))
                                    }}
                                >
                                    Insert existing beat
                                </ExistingBeat>)}
                            </div>
                        </div>
                        {beatsList?.length ? (
                            <CreateBeatWrapper
                                onClick={() => {
                                    createBeatHandler()
                                }}
                            >
                                <TimelineItem className={classes.timelineItem}>
                                    <TimelineSeparator>
                                        <TimelineDot className={classes.timelineDot} style={{ padding: 0 }}>
                                            <img src={addBeatIcon} alt="Add Icon" style={{ width: '8px' }} />
                                        </TimelineDot>
                                        <TimelineConnector className={classes.hiddenTimelineConnector} />
                                    </TimelineSeparator>
                                    <TimelineContent className={classes.timelineContent}>
                                        <CreateBeat>Create a beat</CreateBeat>
                                    </TimelineContent>
                                </TimelineItem>
                            </CreateBeatWrapper>
                        ) : (
                            <></>
                        )}
                    </div>
                </Timeline>
				</BeatsContainer>
				
				{currentElement?.id && (
					<DeleteModal
						elementType={currentElement?.type?.toLowerCase()}
						nodeName={`${currentElement?.name}`}
						isShowed={currentElement}
						details=" and its beats that don't have another driver will be removed too"
						isConnection={currentElement?.type === 'Beat' || currentElement?.type === 'Connection'}
						acceptHandler={async () => {
							if (currentElement.type === 'Connection') {
								removeConnection(currentElement)
								createLog(
									'Connection Deletion Confirmed',
									`{"workflowStep":${2},"connectionType":"Static"}`,
									'ElementList',
									'Connection Deletion',
								)
							} else {
								if (currentElement?.type !== 'Beat') {
									// setExpandedElementId('')
								}

								await removeElement(currentElement?.id, currentElement?.type)
								if (currentElement?.type !== 'Beat') {
									sharedDispatch(
										setActiveConnections({
											isActive: false,
											elementId: null,
											elementType: null,
											appView: 'Outlining',
										}),
									)

									// setExpandedElementId("")
								}
								createLog(
									`Element Deletion Confirmed`,
									`{"workflowStep":${2},"elementType":"${currentElement?.type}"}`,
									'ElementList',
									'Element Deletion',
								)
							}
							// const connectionCount = getConnectionCount(client)
							// sharedDispatch(
							// 	setConnectionCount({
							// 		supportingCount: connectionCount.Supporting,
							// 		opposingCount: connectionCount.Opposing,
							// 		undefinedCount: connectionCount.Undefined,
							// 		total: connectionCount.Total,
							// 	}),
							// )
							setCurrentElement()
						}}
						closeHandler={() => {
							if (currentElement.type === 'Connection') {
								createLog(
									'Connection Deletion Cancelled',
									`{"workflowStep":${2},"connectionType":"Static"}`,
									'ElementList',
									'Connection Deletion',
								)
							} else {
								createLog(
									'Element Deletion Cancelled',
									`{"workflowStep":${2},"elementType":"${currentElement?.type}"}`,
									'ElementList',
									'Element Deletion',
								)
							}
							setCurrentElement()
						}}
					/>
				)}
			</>
		)
	},
)

const Draggable = props => {
	
	
	
	

	const {
		attributes,
		listeners,
		active,
		over,
	} = useDndContext()

	


	// const newBeatList = graphData.nodes[props?.id]?.driverConnections.map(connection => {
	// 	const beatId = connection?.sourceNode?.id?.startsWith('bea')
	// 						? connection?.sourceNode?.id
	// 						: connection?.destNode?.id
	// 	return { id:beatId,connectionId:connection.id, order: +connection.order }
	// })

	// const orderedBeatList= orderBy(newBeatList, ['order'], ['asc'])
	const beatsList = useDraggable(props?.referenceElement?.id, active, over, props?.beatList,props?.connectionTypeField)
	
	


	const emptyDroppable = useDroppable({
		id: `rel-empty-beat-connections-${props?.referenceElement?.id}-${props.location}`,
		disabled: beatsList?.length,
		data: {
			type: 'beat',
			referenceElement: { id: props.id },
			id: `rel-empty-beat-connections-${props?.referenceElement?.id}-${props.location}`,
		},
	})

	const beatsContainer = useDroppable({
		id: `container-${props?.referenceElement?.id}-${props?.location}`,
		//disabled: !!referenceElement?.beatConnections?.length,
		data: {
			type: 'beat',
			referenceElement: {id: props?.referenceElement?.id },
			id: `container-${props?.referenceElement?.id}-${props.location}`,
		},
	})



	return (
        <>
           {props?.referenceElement?.id && (
             <BeatsContainerComponent
                {...props}
                attributes={attributes}
                listeners={listeners}
                beatsList={beatsList}
                droppable={beatsList?.length ? beatsContainer.setNodeRef : emptyDroppable.setNodeRef}
            />)}
		</>
	)
}

export default React.memo(Draggable)


